import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import { backendURL } from '../Utils/utils';
import 'react-toastify/dist/ReactToastify.css';
import './ForgotPassword.css';

// handling the Forgot Password feature.
const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        setEmail(e.target.value);
        setError('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${backendURL}/forgot_password/`, {
                email,
            });
            toast.success(response.data.message);
        } catch (err) {
            // Display any error messages returned from the server
            if (err.response && err.response.data && err.response.data.message) {
                setError(err.response.data.message);
            } else {
                setError('Something went wrong. Please try again later.');
            }
        }
    };

    return (
        <div className="forgot-password-container-page">
            <div className="forgot-password-container edit-form-group">
                <h2>Forgot Your Password?</h2>
                <p>Enter your email address below to receive a password reset link.</p>
                <p>It could take up to a few minutes for the email to reach your inbox.</p>
                <form onSubmit={handleSubmit}>
                    <div className={`${error ? 'error' : ''}`}>
                        <label>Email:</label>
                        <input
                            type="email"
                            name="email"
                            value={email}
                            onChange={handleChange}
                            placeholder="Enter your email"
                            required
                            className="forgot-password-email-input"
                            autoComplete='email'
                        />
                        {error && <span className="error-message">{error}</span>}
                    </div>
                    <button type="submit" className="theme-button">
                        Reset Password
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
