import './LeaderScore.css';
import axios from 'axios';
import { useEffect, useState } from 'react';
import linkedin_share from '../../assets/linkedin-share.png';
import { backendURL } from '../Utils/utils';
import { toast } from 'react-toastify';
import { Modal, Form, Button } from 'react-bootstrap';
import '../Utils/common.css';

// handles sharing content to LinkedIn.
const LinkedinShare = () => {
    const [showShareForm, setShowShareForm] = useState(false);
    const [commentary, setCommentary] = useState('Check out my PplLdr leadership ratings!');
    const [showAuth, setShowAuth] = useState(false);
    let allowFire = true;

    const handleMessageListener = async (event) => {
        if (!allowFire) {
            return;
        }
        if (event.data.error) {
            allowFire = true;
            return;
        }
        // This block of code serves the following purpose:
        // Due to the nature of how LinkedinLoginModule implements window.addEventListener,
        // multiple instances in muyltiple routes of the component will fire when call back is called after loggin into Linkedin
        // Therefore, this block checks which instances is to be fired, given the destination in event.data.path
        // Instances not mentioned in even.data.path will try to invoke non existing prop to handle the login
        // So we check if the props exists before calling
        if (event.data.from === 'linkedin') {
            allowFire = false;
            const data = {
                linkedin_token: event.data.code,
                commentary: commentary,
                url: window.location.href,
            };

            try {
                await axios.post(`${backendURL}/post_to_linkedin/`, data);

                toast.success('Shared to LinkedIn successfully');
                synthethicClose();
            }
            catch (err) {
                if (err.response.status === 422) {
                    toast.info('Seems like you already shared on LinkedIn before.\nPlease delete your first share and try again.',
                        { autoClose: false },
                    );
                    synthethicClose();
                }
                else if (err.response.status === 401) {
                    setTimeout(() => {
                        initLinkedInAuth();
                    }, 3000);
                }
                console.error(err);
            }
            allowFire = true;
        }
    };

    useEffect(() => {
        window.addEventListener('message', event => handleMessageListener(event), false);

        return () => {
            // Clean up the event listener when the component unmounts
            window.removeEventListener('message', handleMessageListener);
        };
    }, []);

    const initLinkedInAuth = () => {
        if (!allowFire) {
            return;
        }

        setShowAuth(true);
        const param = {
            scope: 'profile email openid w_member_social',
            redirect_uri: `${window.location.origin}/linkedinCallback`,
            client_id: process.env.REACT_APP_LINKEDIN_CLIENT_ID ?? window._env_.REACT_APP_LINKEDIN_CLIENT_ID,
        };
        const query_param = new URLSearchParams(param);
        const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&${query_param}`;

        setTimeout(() => {
            window.open(
                url,
                '_blank',
            );
        }, 3000);
    };

    const synthethicClose = () => {
        document.getElementById('linkedin-share-close-btn').click();
    };

    const closeModal = (e) => {
        e.stopPropagation();
        setCommentary('Check out my PplLdr leadership ratings!');
        setShowAuth(false);
        setShowShareForm(false);
    };

    return (
        <button
            className='linkedin-share-btn'
            onClick={() => {
                setShowShareForm(true);
            }}
        >
            <img src={linkedin_share} alt='linkedin share'/>
            <Modal
                show={showShareForm}
                backdrop="static"
                centered
                onHide={closeModal}
            >
                <Modal.Header>
                    <h3>Share rating score to LinkedIn</h3>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        className='edit-form-group'
                    >
                        <Form.Group>
                            <Form.Label>Commentary</Form.Label>
                            <Form.Control
                                as='textarea'
                                value={commentary}
                                onChange={e => {
                                    setCommentary(e.target.value);
                                }}
                            />
                            <Form.Text>
                                This is where you put what you&apos;d like to announce to your connections
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Your rating URL</Form.Label>
                            <Form.Control
                                type='text'
                                value={window.location.href}
                                disabled
                            />
                            <Form.Text>
                                This is where your connections will see your ratings
                            </Form.Text>
                        </Form.Group>
                    </Form>
                    {showAuth &&
                        <>
                            <h4>Authenticating with LinkedIn...</h4>
                            <p>We need your permission to post on your LinkedIn account</p>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant='secondary'
                        onClick={closeModal}
                        id='linkedin-share-close-btn'
                    >
                        Close
                    </Button>
                    <Button
                        className='theme-button'
                        onClick={initLinkedInAuth}>
                        Share to LinkedIn
                    </Button>
                </Modal.Footer>
            </Modal>
        </button>
    );
};

export default LinkedinShare;