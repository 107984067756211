import Teams from './MyTeams';
import TeamMembers from './MyTeamMembers';
import RatedLeader from './MyRatedLeaders';
import { useEffect, useState } from 'react';
import './MyTeamDashboard.css';
import PropTypes from 'prop-types';

import { getAuthenticationHeader, backendURL } from '../Utils/utils';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Loading from '../Utils/Loading';

// Component for my team dashboard
const TeamDashboard = (props) => {
    const [selectedFunc, setSelectedFunc] = useState('myTeams');
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [teamList, setTeamList] = useState([]);
    const [notRated, setNotRated] = useState(false);
    const navigate = useNavigate();

    const getSelfRatingStatus = async () => {
        if (!props.userProfile) {
            return;
        }
        try {
            const config = {
                ...getAuthenticationHeader(),
                params: {
                    first_name: props.userProfile.first_name,
                    last_name: props.userProfile.last_name,
                    id: props.userProfile.id,
                },
            };

            const response = await axios.get(`${backendURL}/get_rating_list`, config);
            if (response.data.length == 0) {
                setNotRated(true);
            }

        } catch (error) {
            console.error(error);
        }

    };

    const onSelectTeam = (team, teamNames) => {
        setSelectedTeam(team);
        setTeamList(teamNames);
    };

    const navigateToMyTeams = () => {
        setSelectedFunc('myTeams');
        setSelectedTeam(null);
    };

    const navigateToMyLeaders = () => {
        setSelectedFunc('myLeaders');
    };

    const toSelfRate = () => {
        navigate(`/questionnaire/selfrate/${props.userProfile.id}`,
            {
                state: {
                    userProfile: props.userProfile,
                    self_rate: true,
                },
            },
        );
    };

    useEffect(() => {
        getSelfRatingStatus();
    }, [props]);

    if (!props.userProfile) {
        return (<Loading />);
    }

    return (
        <div className="teamdashboard-page">
            {notRated &&
                <Modal
                    show={notRated}
                    centered
                >
                    <Modal.Header>
                        <h3>Notification</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <p>To access this page, you need to finish self-rating first.</p>
                        <p>After that, you can invite your direct reports to rate you.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className='theme-button'
                            onClick={toSelfRate}
                        >Continue to self-rating</button>
                    </Modal.Footer>
                </Modal>
            }
            <div className="teamdashboard-sidebar">
                <ul>
                    <li className={`teamdashboard-item ${selectedFunc === 'myTeams' ? 'selected' : ''}`} onClick={navigateToMyTeams}>
                        <i className="fa-solid fa-people-group" /> My Teams</li>
                    <li className={`teamdashboard-item ${selectedFunc === 'myLeaders' ? 'selected' : ''}`} onClick={navigateToMyLeaders}>
                        <i className="fa-solid fa-user-tie" /> My Leaders</li>
                </ul>
            </div>
            <div className="teamdashboard-content">
                {selectedFunc === 'myTeams' && (selectedTeam ? (
                    <TeamMembers selectedTeam={selectedTeam} onSelectTeam={onSelectTeam} userProfile={props.userProfile} teamList={teamList} />
                ) : (
                    <Teams onSelectTeam={onSelectTeam} />
                ))}
                {selectedFunc === 'myLeaders' && <RatedLeader />}
            </div>
        </div>
    );
};

TeamDashboard.propTypes = {
    userProfile:  PropTypes.object,
};

export default TeamDashboard;
