const NotFound = () => {
    const styles = {
        height: 'calc(100vh - 45px - 55px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '40px',
    };

    return (
        <div style={styles}>
            <h1 style={{ color: 'var(--main-accent-blue)' }}>404</h1>
            <h4>Awwww man, this page does not exist!</h4>
        </div>
    );
};

export default NotFound;