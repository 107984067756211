import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';

import './Profile.css';
import Contact from './Contact';
import Education from './Education';
import EducationEdit from './EducationEdit';
import Experience from './Experience';
import ExperienceEdit from './ExperienceEdit';
import Loading from '../Utils/Loading';
import ProfileEdit from './ProfileEdit';
import SkillsEdit from './SkillsEdit';
import Awards from './Awards';
import AwardsEdit from './AwardsEdit';
import { backendURL, getAuthenticationHeader } from '../Utils/utils';

const Profile = (props) => {
    // User data
    const navigate = useNavigate();
    const header = getAuthenticationHeader();

    const [loading, setLoading] = useState(true);

    const [userProfile, setUserProfile] = useState(null);
    const [educationList, setEducationList] = useState(null);
    const [workExperienceData, setWorkExperienceData] = useState([]);
    const [skillList, setSkillList] = useState(null);
    const [achievementList, setAchievementList] = useState([]);

    const { userViewOnly } = useParams();
    let viewUserParams = null;
    if (userViewOnly) {
        const splitdata = userViewOnly.split('-');
        viewUserParams = {
            params: {
                first_name: splitdata[0],
                last_name: splitdata[1],
                id: splitdata[2],
            },
        };
    }

    // Child props used for editing
    const [workExperienceFormData, setWorkExperienceFormData] = useState(null);
    const [workExperienceSkillRefresh, setWorkExperienceSkillRefresh] = useState(false);
    const [editExperienceID, setEditExperienceID] = useState();
    const [educationFormData, setEducationFormData] = useState(null);
    const [educationFormID, setEducationFormID] = useState(null);
    const [achievementForm, setAchievementForm] = useState(null);

    // Edit button
    const [editExperience, setEditExperience] = useState(false);
    const [editEducation, setEditEducation] = useState(false);
    const [editAchievements, setEditAchievements] = useState(false);

    // Modal display flags
    const [showEditExperience, setshowEditExperience] = useState(false);
    const [showEditProfile, setShowEditProfile] = useState(false);
    const [showEditSkills, setShowEditSkills] = useState(false);
    const [showContact, setShowContact] = useState(false);
    const [showEditEducation, setShowEditEducation] = useState(false);
    const [showAchievement, setShowAchievement] = useState(false);

    useEffect(() => {
        if (!header.headers.Authorization) {
            // Handle case where the token is not available or expired
            navigate('/');
            return;
        }

        fetchProfileData();
        fetchWorkExperienceData();
        fetchSkills();
        fetchEducation();
        fetchAchievements();

        window.addEventListener('resize', dynamicProfileLayout);

        return (
            window.removeEventListener('resize', dynamicProfileLayout)
        );
    }, [userViewOnly]);

    const dynamicProfileLayout = () => {
        const bioField = document.getElementById('profile-bio');
        const introContainer = document.getElementById('user-profile-image-small-container');
        if (window.innerWidth > 855) {
            if (bioField && introContainer) {
                if (bioField.offsetHeight > 100) {
                    introContainer.style.height = 'auto';
                }
                else {
                    introContainer.style.height = '125px';
                }
            }
        }
        else {
            introContainer.style.height = 'auto';
        }
    };

    const fetchProfileData = async () => {
        try {
            setLoading(true);
            let config = header;

            let apiEndpoint = `${backendURL}/user/profile/`;
            if (userViewOnly) {
                apiEndpoint = `${backendURL}/user/view_profile/`;
                config = { ...config, ...viewUserParams };
            }
            const response = await axios.get(apiEndpoint, config);
            setUserProfile(response.data);
            if (!userViewOnly) props.setUserAppData(response.data);
        } catch (error) {
            if (userViewOnly) {
                if (error.response.status === 404) {
                    navigate('/404');
                }
            }
            else {
                console.error(error);
                setUserProfile(null);
                localStorage.clear();
                window.dispatchEvent(new Event('storage'));
                navigate('/');
            }
        }
        setLoading(false);
    };

    const fetchWorkExperienceData = async () => {
        try {
            let config = header;

            let apiEndpoint = `${backendURL}/work_experience/`;
            if (userViewOnly) {
                apiEndpoint = `${backendURL}/view_work_experience/`;
                config = { ...config, ...viewUserParams };
            }
            const response = await axios.get(apiEndpoint, config);
            setWorkExperienceData(response.data);

        } catch (error) {
            console.error(error);
            setWorkExperienceData([]);
        }
    };

    const fetchSkills = async () => {
        try {
            let config = header;

            let apiEndpoint = `${backendURL}/skills/`;
            if (userViewOnly) {
                apiEndpoint = `${backendURL}/view_skills/`;
                config = { ...config, ...viewUserParams };
            }

            const request = await axios.get(apiEndpoint, config);

            setSkillList(request.data.skills);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchEducation = async () => {
        try {
            let config = header;

            let apiEndpoint = `${backendURL}/education/`;
            if (userViewOnly) {
                apiEndpoint = `${backendURL}/view_education/`;
                config = { ...config, ...viewUserParams };
            }

            const request = await axios.get(apiEndpoint, config);
            setEducationList(request.data);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchAchievements = async () => {
        try {
            let config = header;

            let apiEndpoint = `${backendURL}/achievements/`;
            if (userViewOnly) {
                apiEndpoint = `${backendURL}/view_achievements/`;
                config = { ...config, ...viewUserParams };
            }

            const request = await axios.get(apiEndpoint, config);
            setAchievementList(request.data);
        } catch (error) {
            console.error(error);
        }
    };

    const exprienceFormToggle = (refresh = false) => {
        setshowEditExperience(!showEditExperience);
        if (showEditExperience) {
            if (refresh) {
                fetchWorkExperienceData();
                fetchSkills();
                setWorkExperienceSkillRefresh(true);
            }
            setWorkExperienceFormData(null);
        }
    };
    const handleEditToggle = () => {
        setEditExperience(!editExperience);
    };
    const toggleEducationEditIndicator = () => {
        setEditEducation(!editEducation);
    };
    const editProfileToggle = (refresh = false) => {
        setShowEditProfile(!showEditProfile);
        if (refresh) fetchProfileData();
    };
    const editEditSkillsToggle = async (refresh = false) => {
        setShowEditSkills(!showEditSkills);
        if (refresh) fetchSkills();
    };
    const showContactToggle = () => {
        setShowContact(!showContact);
    };
    const addEducationToggle = (refresh = false) => {
        setShowEditEducation(!showEditEducation);
        if (refresh) fetchEducation();
        if (showEditEducation) setEducationFormData(null);
    };
    const showAchievementToggle = (refresh = false) => {
        setShowAchievement(!showAchievement);
        if (refresh) fetchAchievements();
        if (showAchievement) setAchievementForm(null);
    };
    const toggleAchievementEditIndicator = () => {
        setEditAchievements(!editAchievements);
    };

    // Callback function for getting child exp attributes
    const updateModalData = (childData, item_id) => {
        setWorkExperienceFormData(childData);
        setEditExperienceID(item_id);
        exprienceFormToggle();
    };
    const updateEducationModalData = (childData, item_id) => {
        if (childData.end_month && childData.end_year) {
            setEducationFormData({ ...childData, currently_working: false });
        }
        setEducationFormData(childData);
        setEducationFormID(item_id);
        addEducationToggle();
    };
    // Callback to delete experience
    const handleSubmitDelete = async (item_id) => {
        try {
            const config = getAuthenticationHeader();
            await axios.delete(`${backendURL}/work_experience?id=${item_id}`, config);

            fetchProfileData();
            fetchWorkExperienceData();
        } catch (error) {
            console.error(error);
        }
    };
    // Callback to edit achievment
    const updateAchievementModalData = (childData) => {
        setAchievementForm(childData);
        showAchievementToggle();
    };

    // See Scores
    const viewScores = () => {
        let nameId;
        if (viewUserParams) {
            nameId = viewUserParams.params;
            nameId = `${viewUserParams.params.first_name}-${viewUserParams.params.last_name}-${viewUserParams.params.id}`;
        }
        else {
            nameId = `${userProfile.first_name}-${userProfile.last_name}-${userProfile.id}`;
        }
        navigate(`/user/ratingscore/${nameId}`);
    };

    // View User posts
    const viewActivities = () => {
        if (userViewOnly) {
            navigate(`/posts/${viewUserParams.params.first_name}-${viewUserParams.params.last_name}-${viewUserParams.params.id}`);
        }
        else {
            navigate('/user/myposts');
        }
    };

    if (!userProfile || loading) {
        return <Loading message={`Loading ${userViewOnly ? '' : 'Your '}Profile`}/>;
    }

    return (
        <div style={{ perspective: '1px', minHeight: '100vh', overflowY: 'auto', overflowX: 'hidden' }} id="profile-main">
            {!userViewOnly &&
            // If viewing other users, disable modals
                <>
                    {showEditSkills &&
                        <SkillsEdit
                            skillsArr={skillList}
                            closePanel={editEditSkillsToggle}
                            show={showEditSkills}
                        />
                    }
                    {userProfile &&
                        <ProfileEdit
                            profileData={userProfile}
                            closePanel={editProfileToggle}
                            show={showEditProfile}
                        />
                    }
                    {showEditExperience &&
                        <ExperienceEdit
                            formData={workExperienceFormData ? workExperienceFormData : null}
                            editExperienceID={editExperienceID}
                            editExperience={editExperience}
                            closePanel={exprienceFormToggle}
                            show={showEditExperience}
                        />
                    }
                    {showEditEducation &&
                        <EducationEdit
                            closePanel={addEducationToggle}
                            formData={educationFormData}
                            formDataID={educationFormID}
                            editEducation={editEducation}
                            show={showEditEducation}
                        />
                    }
                    {showAchievement &&
                        <AwardsEdit
                            closePanel={showAchievementToggle}
                            formData={achievementForm}
                            edit={editAchievements}
                            show={showAchievement}
                        />
                    }
                </>
            }
            {showContact &&
                <Contact
                    email={userProfile.email}
                    phone={userProfile.phone_number}
                    closePanel={showContactToggle}
                    show={showContact}
                />
            }
            <div className="user-profile-section"></div>
            <div style={{ position: 'absolute', top: '300px', width: '100vw', transform: 'translateZ(0px)' }}>
                {userProfile && (
                    <div className="user-profile-container">
                        <div className="user-profile-image-small-container" id="user-profile-image-small-container">
                            <img src={userProfile.profile_image} alt="Profile"
                                className={`user-profile-image-small ${userProfile.is_premium ? 'user-profile-image-small-premium' : ''}`}
                            />
                            <div className="user-personal-info-container">
                                <div className="profile-heading">
                                    <h2>{userProfile.first_name} {userProfile.last_name}</h2>
                                    {!userViewOnly && <button className="profile-edit-experience-btn" title="Edit Profile" onClick={editProfileToggle}>
                                        <i className="fas fa-edit"></i>
                                    </button>}
                                </div>
                                <p id="profile-bio">{userProfile.bio || ''}</p>
                                <div id="profile-location">
                                    <p>{userProfile.address || 'Not provided'}</p>
                                    <p>•</p>
                                    <button className="profile-contact-btn" onClick={showContactToggle}><p>Contact Info</p></button>
                                    <button className="theme-button" type="button" onClick={viewScores}>See Leader Scores</button>
                                    <button className="theme-button" type="button" onClick={viewActivities}>See Activities</button>
                                </div>
                            </div>
                        </div>

                        {/* Experience Section */}
                        <div className="experience-section">
                            <h3>
                                Experience
                                {!userViewOnly && <>
                                    {!editExperience && <button className="add-experience-btn" title="Add Work Experience" onClick={exprienceFormToggle}>
                                        <i className="fas fa-plus"></i>
                                    </button>}
                                    <button className="profile-edit-experience-btn" title="Edit Work Experience" onClick={handleEditToggle}>
                                        <i className="fas fa-edit"></i>
                                    </button>
                                </>}
                            </h3>
                            {workExperienceData && workExperienceData.length > 0 ? (
                                <div className="experience-cards">
                                    {workExperienceData.map((experience) => (
                                        <Experience
                                            key={experience.id}
                                            item_id={experience.id}
                                            title={experience.title}
                                            company_name={experience.company_name}
                                            employment_type={experience.employment_type}
                                            location={experience.location}
                                            location_type={experience.location_type}
                                            start_month={experience.start_month}
                                            start_year={experience.start_year}
                                            end_month={experience.end_month}
                                            end_year={experience.end_year}
                                            description={experience.description}
                                            edit_exp={editExperience}
                                            delete_click={handleSubmitDelete}
                                            edit_click={updateModalData}
                                            refresh_skill={workExperienceSkillRefresh}
                                            refresh_skill_call_back={setWorkExperienceSkillRefresh}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <p>No work experience information</p>
                            )}
                        </div>

                        {/* Skills Section */}
                        <div className="experience-section">
                            <h3>
                                Skills
                                {!userViewOnly &&
                                    <button className="profile-edit-experience-btn" onClick={editEditSkillsToggle}>
                                        <i className="fas fa-edit"></i>
                                    </button>
                                }
                            </h3>
                            {skillList && skillList.length > 0 ? (
                                <div className="skill-item-container">
                                    {skillList.map(skill => {
                                        return (
                                            <div key={skill} className="skill-item"><p>{skill}</p></div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <p>No skills information</p>
                            )}
                        </div>

                        {/* Education Section */}
                        <div className="experience-section">
                            <h3>
                                Education
                                {!userViewOnly && <>
                                    {!editEducation && <button className="add-experience-btn" onClick={addEducationToggle}>
                                        <i className="fas fa-plus"></i>
                                    </button>}
                                    <button className="profile-edit-experience-btn" onClick={toggleEducationEditIndicator}>
                                        <i className="fas fa-edit"></i>
                                    </button>
                                </>}
                            </h3>
                            <div className="experience-cards">
                                {educationList && educationList.length > 0 ? (
                                    educationList.map(item => {
                                        return (
                                            <Education
                                                key={`education-item-${item.id}`}
                                                educationInfo={item}
                                                handleDelete={fetchEducation}
                                                edit_edu={editEducation}
                                                edit_click={updateEducationModalData}
                                                id={item.id}
                                            />

                                        );
                                    })
                                ) : (
                                    <p>No education infomation</p>
                                )}
                            </div>
                        </div>

                        {/* Awards Section */}
                        <div className="experience-section">
                            <h3>
                                Awards & Achievements
                                {!userViewOnly && <>
                                    {!editAchievements && <button className="add-experience-btn" onClick={showAchievementToggle}>
                                        <i className="fas fa-plus"></i>
                                    </button>}
                                    <button className="profile-edit-experience-btn" onClick={toggleAchievementEditIndicator}>
                                        <i className="fas fa-edit"></i>
                                    </button>
                                </>}
                            </h3>
                            {achievementList.length > 0 &&
                                <Awards
                                    data={achievementList}
                                    edit_achievement={editAchievements}
                                    callback={updateAchievementModalData}
                                    handleDelete={fetchAchievements}
                                />
                            }
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

Profile.propTypes = {
    userViewOnly: PropTypes.bool,
    setUserAppData: PropTypes.func,
};

export default Profile;
