import { useState } from 'react';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import UserThumbnail from '../Utils/UserThumbnail';
import propTypes from 'prop-types';
import '../Posts/Post.css';
import './Post.css';
import '../Utils/common.css';
// eslint-disable-next-line
import { getAuthenticationHeader, backendURL, logOut } from '../Utils/utils';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocate from 'dayjs/plugin/updateLocale';
dayjs.extend(relativeTime);
dayjs.extend(updateLocate);

dayjs.updateLocale('en', {
    relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: 'a few seconds',
        m: '1m',
        mm: '%dm',
        h: '1h',
        hh: '%dh',
        d: '1d',
        dd: '%dd',
        M: '1mo',
        MM: '%dmo',
        y: '1y',
        yy: '%dy',
    },
});

// Component for displaying and interacting with a post.
const Post = (props) => {
    const [activeLike, setActiveLike] = useState(props.activeLike);
    const [post, setPost] = useState(props.post);
    const [viewComments, setViewComments] = useState(false);
    const [comments, setComments] = useState(null);
    const [userComment, setUserComment] = useState(undefined);
    const [editContent, setEditContent] = useState(props.post.content);
    const [showEditContentArea, setShowEditContentArea] = useState(false);
    const [likeSpam, setLikeSpam] = useState(false);
    const [commentSpam, setCommentSpam] = useState(false);
    const date = new Date(props.createdAt);

    const handleLikeClick = async () => {
        if (likeSpam) {
            return;
        }

        setLikeSpam(true);
        const config = {
            ...getAuthenticationHeader(),
        };

        const data = { data: { 'post_id': post.id } };

        if (activeLike) {
            const response = await axios.delete(`${backendURL}/like_posts_of_ratings/`, { ...config, ...data });
            if (response.status === 200) {
                setActiveLike(false);
                setPost(prevPost => ({ ...prevPost, likes: prevPost.likes - 1 }));
            }
            else if (response.status === 401) {
                // logOut();
            }
        }
        else {
            const response = await axios.put(`${backendURL}/like_posts_of_ratings/`, data.data, config);
            if (response.status === 200) {
                setActiveLike(true);
                setPost(prevPost => ({ ...prevPost, likes: prevPost.likes + 1 }));
            }
            else if (response.status === 401) {
                // logOut();
            }
        }
        setLikeSpam(false);
    };

    const textareaHeightAdjust = (e) => {
        e.style.height = 'auto';
        e.style.height = `calc( ${(e.scrollHeight)}px + 5px)`;
    };

    const fetchComments = async () => {
        const config = {
            ...getAuthenticationHeader(),
            params: {
                post_id: post.id,
            },
        };
        try {
            const response = await axios.get(`${backendURL}/post_to_rating_comment/`, config);

            setComments(response.data);
        }
        catch (e) {
            console.error(e);
        }
    };

    const toggleCommentPanel = () => {
        setViewComments(!viewComments);

        if (!comments) {
            fetchComments();
        }
    };

    const makeComment = async (e) => {
        e.preventDefault();
        if (commentSpam || userComment.split(' ').join('') === '') {
            return;
        }

        setCommentSpam(true);
        const config = getAuthenticationHeader();

        const data = {
            post_id: post.id,
            content: userComment,
        };

        try {
            const response = await axios.post(`${backendURL}/post_to_rating_comment/`, data, config);

            if (response.status === 200) {
                const commentList = [response.data, ...comments];
                setComments(commentList);
                setUserComment('');
                document.getElementById('post-comment-text-area').style.height = 'auto';
            }
            else if (response.status === 401) {
                // logOut();
            }
        }
        catch (error) {
            console.error(error);
        }
        setCommentSpam(false);
    };

    const deleteComment = async (comment_id, idx) => {
        const config = getAuthenticationHeader();

        const param = `?post_id=${post.id}&comment_id=${comment_id}`;

        try {
            const response = await axios.delete(
                `${backendURL}/post_to_rating_comment${param}`,
                config,
            );

            if (response.status === 204) {
                setPost(prevData => ({ ...prevData, comments: prevData.comments - 1 }));
                const commentList = [...comments.slice(0, idx), ...comments.slice(idx + 1)];
                setComments(commentList);
            }
            else if (response.status === 401) {
                // logOut();
            }
        }
        catch (e) {
            console.error(e);
        }
    };

    const editPost = async (e) => {
        e.preventDefault();

        if (editContent.split(' ').join('') === '') {
            return;
        }

        try {
            const config = getAuthenticationHeader();
            const data = {
                content: editContent,
                post_id: post.id,
            };

            const request = await axios.patch(
                `${backendURL}/post_to_rating/`,
                data,
                config,
            );

            setPost({ ...post, content: request.data });
            setEditContent(request.data);
            setShowEditContentArea(false);

            toast.success('Successfully edited your post');
        }
        catch (err) {
            console.error(err);
        }
    };

    const deletePost = async () => {
        try {
            const config = getAuthenticationHeader();
            const params = `?post_id=${post.id}`;

            axios.delete(
                `${backendURL}/post_to_rating/${params}`,
                config,
            );
            props.dynamicPostRemoveCallBack(props.idx);
            toast.success('Successfully removed your post');
        }
        catch (e) {
            console.error(e);
        }
    };

    return (
        <div className={`post-card ${props.highlighted ? 'post-highlighted' : ''}`}>
            <div>
                <UserThumbnail userProfile={post.user} self={false} />
                <div className='right-info-container'>
                    <p className='post-date'>{dayjs(date).fromNow()}</p>
                    {props.userId === post.user.id &&
                        <div className='post-to-rating-option'>
                            <i className="fa-solid fa-trash del-btn" onClick={deletePost}></i>
                            <i className="fa-solid fa-pen edit-btn" onClick={() => {
                                setShowEditContentArea(!showEditContentArea);
                                setEditContent(post.content);
                            }}></i>
                        </div>
                    }
                </div>
            </div>
            {showEditContentArea ?
                <Form className='edit-form-group post-to-rating-edit-post-form' onSubmit={editPost}>
                    <textarea
                        value={editContent}
                        onChange={e => {
                            setEditContent(e.target.value);
                        }}
                        onKeyUp={e => {
                            textareaHeightAdjust(e.target);
                        }}
                    />
                    <button className='theme-button' type="submit">Edit post</button>
                </Form>
                :
                <pre className="post-content-with-buttons">{post.content}</pre>
            }
            <div className="like-comment-buttons">
                <div>
                    <span>{post.likes} like{post.likes > 1 ? 's' : ''}</span>
                    {props.userId &&
                        <button
                            className={`like-button ${activeLike && 'like-button-active'}`}
                            onClick={handleLikeClick}
                        >
                            <i className="fas fa-thumbs-up"></i>
                            Like{activeLike ? 'd' : ''}
                        </button>
                    }
                </div>
                {props.userId &&
                    <div>
                        <button
                            className="comment-button"
                            onClick={toggleCommentPanel}
                        >
                            <i className="fas fa-comment"></i>
                            {viewComments ? 'Close comments' : 'View comment'}
                        </button>
                    </div>
                }
            </div>

            {viewComments &&
                <div className="comment-panel">
                    <div className="post-comment-container">
                        <h5>Comments</h5>
                        {comments && comments.map((comment, idx) => {
                            return (
                                <div key={`post-comment-${post.id}-${comment.id}`}>
                                    <UserThumbnail userProfile={comment.user} self={false} style="simplified" />
                                    <div className='post-comment-content-delete-field'>
                                        <pre>{comment.content}</pre>
                                        {comment.user.id === props.userId &&
                                            <button onClick={() => { deleteComment(comment.id, idx); }}>
                                                <span>&times;</span>
                                            </button>
                                        }
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <form onSubmit={makeComment}>
                        <textarea
                            id="post-comment-text-area"
                            className="comment-text-field"
                            placeholder="Make a comment..."
                            value={userComment}
                            onChange={(e) => { setUserComment(e.target.value); }}
                            onKeyUp={e => { textareaHeightAdjust(e.target); }}
                        />
                        <button type="submit" className="theme-button">Comment</button>
                    </form>
                </div>
            }
        </div>
    );
};

Post.propTypes = {
    post: propTypes.object,
    activeLike: propTypes.bool,
    highlighted: propTypes.bool,
    userId: propTypes.number,
    idx: propTypes.number,
    dynamicPostRemoveCallBack: propTypes.func,
    createdAt: propTypes.instanceOf(Date),
};

export default Post;
