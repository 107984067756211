import { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Modal, Form, Button } from 'react-bootstrap';
import defaultPfp from '../../assets/default_pfp.png';

import './ProfileEdit.css';
import './profile-modal.css';
import '../Utils/common.css';
import { getAuthenticationHeader, backendURL } from '../Utils/utils';

// Components for editing personal information
const ProfileEdit = (props) => {
    const [formData, setFormData] = useState({
        email: props.profileData.email,
        first_name: props.profileData.first_name,
        last_name: props.profileData.last_name,
        profile_image: props.profileData.profile_image,
        bio: props.profileData.bio,
        phone_number: props.profileData.phone_number,
        address: props.profileData.address,
    });

    const [previewImage, setPreviewImage] = useState(null);

    const {
        email,
        first_name,
        last_name,
        profile_image,
        bio,
        phone_number,
        address,
    } = formData;

    const handleChange = (e) => {
        if (bio.length > 600) {
            return;
        }
        if (e.target.name === 'profile_image') {
            try {
                setFormData({ ...formData, profile_image: e.target.files[0] });
                setPreviewImage(URL.createObjectURL(e.target.files[0]));
            } catch (err) {
                console.error(err);
            }
        }
        else setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (bio.length > 600) {
            return;
        }

        try {
            const requestFormData = new FormData();

            requestFormData.append('email', email);
            requestFormData.append('first_name', first_name);
            requestFormData.append('last_name', last_name);
            // If profile_image is a File object
            if (profile_image) requestFormData.append('profile_image', profile_image);
            else requestFormData.append('profile_image', formData.profile_image);
            requestFormData.append('bio', bio);
            requestFormData.append('phone_number', phone_number);
            requestFormData.append('address', address);

            const config = getAuthenticationHeader('multipart/form-data');

            await axios.patch(`${backendURL}/edit_user/`, requestFormData, config);
            toast.success('Updated personal information successfully');

            props.closePanel(true);
        } catch (err) {
            toast.error('Something unexpected happened');
            console.error(err);
        }
    };

    const remove_pfp = async () => {
        try {
            const config = {
                data : {
                    type: 'pfp',
                },
                ...getAuthenticationHeader('multipart/form-data'),
            };

            const response = await axios.delete(
                `${backendURL}/user/remove_resource/`,
                config,
            );

            if (response.status !== 200) {
                toast.error('Something wrong happened, please try again later.');
            }

            toast.success('Removed current pfp successfully reset successfully.');

            formData.profile_image = null;
            setPreviewImage(defaultPfp);

        } catch (err) {
            toast.error('Something wrong happened, please try again later.');
            console.error(err);
        }
    };

    return (
        <Modal
            className="edit-form-group profile-edit"
            backdrop="static"
            centered
            show={props.show}
            onHide={props.closePanel}
        >
            <Modal.Header closeButton>
                <h3>Edit personal information</h3>
            </Modal.Header>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>Email:</Form.Label>
                    <Form.Control
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        required
                        autoComplete='email'
                    />
                </Form.Group>
                <Form.Group className="two-column-profile-edit name-row">
                    <div>
                        <Form.Label>First name:</Form.Label>
                        <Form.Control
                            type="text"
                            name="first_name"
                            value={first_name}
                            onChange={handleChange}
                            required
                            autoComplete='given-name'
                        />
                    </div>
                    <div>
                        <Form.Label>Last name:</Form.Label>
                        <Form.Control
                            type="text"
                            name="last_name"
                            value={last_name}
                            onChange={handleChange}
                            required
                            autoComplete='family-name'
                        />
                    </div>
                </Form.Group>
                <Form.Group className='two-column-profile-edit pfp-row'>
                    <div>
                        <Form.Label>Profile image:</Form.Label>
                        <Form.Control
                            type="file"
                            name="profile_image"
                            onChange={handleChange}
                            accept="image/*"
                            id="image-upload-profile-edit"
                        />
                    </div>

                    <img
                        src = {previewImage ?? formData.profile_image}
                        alt="current profile image" id="profile-edit-current-pfp"
                    />

                    {(
                        !previewImage &&
                        (typeof formData.profile_image === 'string' &&
                        !formData.profile_image.includes('user-pfp/default_pfp.png'))
                    )

                        && <i className="fa-solid fa-trash pfp-del-btn" onClick={remove_pfp}></i>
                    }
                </Form.Group>
                <Form.Group>
                    <Form.Label>Bio:</Form.Label>
                    <Form.Control
                        type="text"
                        name="bio"
                        value={bio}
                        onChange={handleChange}
                        maxLength={600}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label htmlFor="phone_number">Phone number:</Form.Label>
                    <Form.Control
                        type="tel"
                        name="phone_number"
                        value={phone_number}
                        onChange={handleChange}
                        autoComplete='tel'
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label htmlFor="address">Address:</Form.Label>
                    <Form.Control
                        type="text"
                        name="address"
                        value={address}
                        onChange={handleChange}
                        autoComplete='street-addres'
                    />
                </Form.Group>
                <div className='form-footer-btn-group'>
                    <Button variant='secondary' onClick={props.closePanel}>
                        Cancel
                    </Button>
                    <Button className='confirm' type='submit'>
                        Update Profile
                    </Button>
                </div>
            </Form>
        </Modal>

    );
};

ProfileEdit.propTypes = {
    profileData: PropTypes.shape({
        email: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        profile_image: PropTypes.string,
        bio: PropTypes.string,
        phone_number: PropTypes.string,
        address: PropTypes.string,
    }),
    closePanel: PropTypes.func,
    show: PropTypes.bool,
};

export default ProfileEdit;
