import { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { getAuthenticationHeader, backendURL, logOut } from '../Utils/utils';
import Loading from '../Utils/Loading';
import axios from 'axios';
import Post from './Post';
import './UserPosts.css';

// Fetches the user posts and liked posts, and displays them
const userPosts = (props) => {
    const [posts, setPosts] = useState(null);
    const [likedPostsMap, setLikedPostsMap] = useState(null);

    const { userViewOnly, postId } = useParams();
    const hilightPostId = parseInt(postId);

    let viewUserParams = null;
    if (userViewOnly) {
        const splitdata = userViewOnly.split('-');
        if (splitdata.length === 3) {
            viewUserParams = {
                params: {
                    first_name: splitdata[0],
                    last_name: splitdata[1],
                    id: splitdata[2],
                },
            };
        }
    }

    const fetchLikedPosts = async () => {
        const config = getAuthenticationHeader();

        try {
            const response = await axios.get(`${backendURL}/get_liked_posts/`, config);
            setLikedPostsMap(response.data);
        }
        catch (e) {
            console.error(e);
            logOut();
        }
    };

    const fetchUserPosts = async () => {
        let config = getAuthenticationHeader();
        let endPoint = 'get_my_posts';

        try {
            if (userViewOnly) {
                endPoint = 'get_user_posts';
                config = {
                    ...config,
                    ...viewUserParams,
                };
            }

            const response = await axios.get(`${backendURL}/${endPoint}/`, config);
            setPosts(response.data);
        }
        catch (e) {
            console.error(e);
            logOut();
        }
    };

    const dynamicPostRemove = (idx) => {
        const postList = [...posts.slice(0, idx), ...posts.slice(idx + 1)];
        setPosts(postList);
    };

    useEffect(() => {
        fetchLikedPosts()
            .then(() => {
                fetchUserPosts();
            });
    }, []);

    if (!posts) {
        return <Loading message={`Loading ${userViewOnly ? viewUserParams.params.first_name : 'Your'}${userViewOnly ? '\'s' : ''} Posts`} />;
    }

    return (
        <div className='user-posts-container'>
            <div>
                <h3>{userViewOnly ? `${viewUserParams.params.first_name}'s Posts` : 'My Posts'}</h3>
                <p>
                    Viewing {userViewOnly ? viewUserParams.params.first_name : 'your'}{userViewOnly ? '\'s' : ''} past posts
                </p>
            </div>

            <div>
                {posts.map((post, idx) => (
                    <Post
                        key={`user-post-${post.id}`}
                        highlighted={post.id === hilightPostId}
                        post={post}
                        idx={idx}
                        activeLike={likedPostsMap[post.id] ? true : false}
                        dynamicPostRemoveCallBack={dynamicPostRemove}
                        userId={props.userProfile.id}
                    />
                ))}
            </div>
        </div>
    );
};

userPosts.propType = {
    userProfile : propTypes.object,
};

export default userPosts;