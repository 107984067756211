import { Worker, Viewer } from '@react-pdf-viewer/core';

import termspdf from '../../assets/PplLdr Terms&Conditions.pdf';
import './AboutUs.css';

import packageJson from '../../../package.json';
const pdfjsVersion = packageJson.dependencies['pdfjs-dist'].replace('^', '');

// Renders the Terms and Conditions component.
const TermsandCondition = () => {
    return (

        <div className="terms">
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}>
                <Viewer fileUrl={termspdf}></Viewer>
            </Worker>
        </div>

    );
};

export default TermsandCondition;

