import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { ReactComponent as Loader } from '../../assets/Loader.svg';
import './Questionnaire.css';
import { backendURL, getAuthenticationHeader } from '../Utils/utils';
import Loading from '../Utils/Loading';

const ButtonSpin = ({ onSubmit, text, loading, disabled }) => {
    return (
        <button className="question-submitbtn" onClick={onSubmit} disabled={disabled} >
            {!loading ? text : <Loader className="spinner" />}
        </button>
    );
};

ButtonSpin.propTypes = {
    onSubmit: PropTypes.func,
    text: PropTypes.string,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
};

// Component for the Questionnaire
const Questionnaire = () => {
    const [questionlist, setQuetionlist] = useState([]);
    const [ratings, setRatings] = useState({});
    const location = useLocation();
    const [isLoading, setLoading] = useState(true);

    const token = localStorage.getItem('token');
    const [leader_prf, setLeader_prf] = useState({});
    const [leader, setLeader] = useState();

    const [decodedTeammemberId, setDecodedTeammemberId] = useState();
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false);
    const [missingAttrIds, setMissingAttrIds] = useState([]);
    const [infoMessage, setInfoMessage] = useState();
    const [showMsg, setShowMsg] = useState();

    const { teammemberUidb64, ratedUser } = useParams();
    let ratedUserParams = null;
    if (ratedUser) {
        const splitdata = ratedUser.split('-');
        if (splitdata.length === 3) {
            ratedUserParams = {
                params: {
                    first_name: splitdata[0],
                    last_name: splitdata[1],
                    id: splitdata[2],
                },
            };
        }
    }

    useEffect(() => {
        if (!token) {
            // Handle case where the token is not available or expired
            navigate('/login',
                {
                    state: {
                        next_url: `
                            /questionnaire/${teammemberUidb64}/
                            ${ratedUserParams.first_name}-${ratedUserParams.last_name}-${ratedUserParams.id}
                        `,
                    },
                },
            );
            return;
        }
        if (ratedUser) {
            fetchProfileData();
        }
        if (!location.state?.self_rate) {
            get_teammate();
        }
        get_questionlist();
    }, []);

    const fetchProfileData = async () => {
        try {
            const config = {
                ...getAuthenticationHeader(),
                ...ratedUserParams,
            };

            const response = await axios.get(`${backendURL}/user/view_profile/`, config);
            setLeader_prf(response.data);
            setLeader(response.data.first_name + ' ' + response.data.last_name);
        } catch (error) {
            console.error(error);
            setLeader_prf(null);
        }
    };

    const get_teammate = async () => {
        try {

            const config = {
                ...getAuthenticationHeader(),
                params: {
                    teammemberUidb64: teammemberUidb64,
                },
            };

            const response = await axios.get(`${backendURL}/get_teammate_by_user/`, config);
            if (response.status === 200) {
                setDecodedTeammemberId(response.data.teammate_id);
                if (response.data.has_evaluated) {
                    setShowMsg(true);
                    setInfoMessage('You have already rated this leader.');
                }

                if (response.data.has_evaluated === null) {
                    setShowMsg(true);
                    setInfoMessage('The questionnaire invitation link is no longer available. Please contact the leader and request to resend the invitation.');
                }
            }

        } catch (error) {
            console.error(error);
        }
    };

    const confirmMsg = () => {
        navigate('/createpost');
    };

    // Updates the ratings state based on the attribute and value provided, and handles any missing attribute IDs.
    const handleSliderChange = (attribute, value) => {
        setRatings(prev => ({ ...prev, [attribute]: value }));
        if (missingAttrIds.length > 0) {
            const missingUpdate = missingAttrIds.filter(id => id !== attribute.toString());
            setMissingAttrIds(missingUpdate);
        }
    };

    // Handles the input change event for a specific attribute.
    const handleInputChange = (attribute, event) => {
        const value = parseFloat(event.target.value);
        if (value >= 1 && value <= 10) {
            setRatings(prev => ({ ...prev, [attribute]: value }));
            if (missingAttrIds.length > 0) {
                const missingUpdate = missingAttrIds.filter(id => id !== attribute.toString());
                setMissingAttrIds(missingUpdate);
            }
        }
    };

    const handleSubmit = async () => {
        // Define the range of IDs for all questions
        const expectedIds = Array.from({ length: questionlist.length }, (_, index) => (index + 1).toString());

        // Find the missing IDs in submission
        const missingRates = expectedIds.filter(id => !ratings[id]);
        if (missingRates.length > 0) {
            setMissingAttrIds(missingRates);
            return;
        }

        const data = {
            leader_id: ratedUser.id,
            ratings: ratings,
            teammate_id: decodedTeammemberId,
        };

        setShowLoader(true);

        try {
            const config = getAuthenticationHeader();

            const response = await axios.post(`${backendURL}/submit_score/`, data, config);
            if (response.status === 201) {
                if (location.state?.self_rate === true) {
                    window.dispatchEvent(new Event('storage'));
                }
                navigate(`/user/ratingscore/${leader_prf.first_name}-${leader_prf.last_name}-${leader_prf.id}`,
                    {
                        state: {
                            leader: leader_prf,
                            questions: questionlist,
                            self_rate: location.state?.self_rate,
                        },
                    },
                );
            }
            else console.error('Error sending data:', response);
        } catch (error) {
            console.error('Error sending data:', error);
        }
    };
    const get_questionlist = async () => {
        try {
            const config = getAuthenticationHeader();

            const response = await axios.get(`${backendURL}/questionlist/`, config);

            if (response.status === 200) {
                setQuetionlist(response.data);
                setLoading(false);
            } else {
                console.error('Error sending data:', response);
            }
        } catch (error) {
            console.error('Error sending data:', error);
        }


    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className='questionnaire-container'>
            <h2>You are rating <span className="leader-name">{leader}</span></h2>
            {questionlist.map((attribute) => (
                <div key={attribute.id}>
                    <div className='question-title'>
                        <h6>{attribute.questioncontent}</h6>
                        <div className="attr-tooltip">&nbsp;<i className="fa-solid fa-circle-question" />
                            <span className="attr-tooltiptext">{attribute.tooltip}</span>
                        </div>
                    </div>
                    <input
                        type="range"
                        min="1"
                        max="10"
                        step="0.1"
                        value={ratings[attribute.id] || 5}
                        onChange={e => handleSliderChange(attribute.id, parseFloat(e.target.value))}
                    />
                    <input
                        type="number"
                        min="1"
                        max="10"
                        step="0.1"
                        value={ratings[attribute.id] || ''}
                        onChange={e => handleInputChange(attribute.id, e)}
                    />

                    {missingAttrIds.includes(attribute.id.toString()) && (<div className='questionnaire-error-msg'>Please rate this attribute.</div>)}
                </div>
            ))}
            {missingAttrIds.length > 0 && (<div className='questionnaire-error-msg'>Please complete all the rating attributes.</div>)}
            <ButtonSpin text='Submit' onSubmit={handleSubmit} loading={showLoader} disabled={showLoader} />

            {/* pop up message */}
            <Modal show={showMsg} backdrop="static" keyboard={false} centered>
                <Modal.Header>
                    <Modal.Title>Information:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{infoMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={confirmMsg}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
};

ButtonSpin.propTypes = {
    onSubmit: PropTypes.func,
    text: PropTypes.string,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default Questionnaire;