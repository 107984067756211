import { useState, useEffect } from 'react';
import axios from 'axios';
import { getAuthenticationHeader, backendURL } from '../Utils/utils';
import RatedLeader from './RatedLeader';
import './MyRatedLeaders.css';
import '../Utils/common.css';

// Component for displaying rated leaders
const RatedLeaders = () => {
    const [ratedLeaderList, setRatedLeaderList] = useState([]);
    const [loading, setLoading] = useState(true);

    const getRatedLeaders = async () => {
        try {
            const config = getAuthenticationHeader();

            const response = await axios.get(`${backendURL}/get_rated_leader/`, config);

            if (response.status === 200) {
                setRatedLeaderList(response.data || []);
                setLoading(false);
            }

        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getRatedLeaders();
    }, []);

    if (loading) {
        return <div className="notif-homebody">Loading...</div>;
    }

    return (
        <div className="rated-leader-homebody">
            <div className='rated-leader-head'>
                <h3>My Rated Leaders</h3>
            </div>
            <div className="rated-leader-card-container ">
                {ratedLeaderList.map((ratedLeader, index) => (
                    <RatedLeader
                        key={index}
                        averageScore={ratedLeader.average_score}
                        leader={ratedLeader.leader}
                    />
                ))}
            </div>
            {ratedLeaderList.length === 0 && <p>You have not rated any leaders.</p>}
        </div>
    );
};

export default RatedLeaders;
