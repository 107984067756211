import axios from 'axios';
import PropTypes from 'prop-types';

import './Education.css';
import { convertDate, getAuthenticationHeader, backendURL } from '../Utils/utils';

// Display and manage education-related information.
const Education = (props) => {
    const handleUpdate = () => {
        // Only proceed if edit mode is enabled.
        if (!props.edit_edu) return;

        const data = {
            institution: props.educationInfo.institution,
            degree: props.educationInfo.degree,
            gpa: props.educationInfo.gpa,
            field_of_study: props.educationInfo.field_of_study,
            start_month: props.educationInfo.start_month,
            start_year: props.educationInfo.start_year,
            end_month: props.educationInfo.end_month,
            end_year: props.educationInfo.end_year,
            achievements: props.educationInfo.achievements,
        };

        // Triggers the edit event with the updated data and identifier.
        props.edit_click(data, props.id);
    };

    const handleDelete = async () => {
        try {
            const config = getAuthenticationHeader();
            await axios.delete(`${backendURL}/education?id=${props.id}`, config);
            props.handleDelete();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="education-item-container">
            <div className="top-bar">
                <h3>{props.educationInfo.institution}</h3>
                {props.edit_edu && <i className="fa-solid fa-pen edit-btn" onClick={handleUpdate}></i>}
                {props.edit_edu && <i className="fa-solid fa-trash del-btn" onClick={handleDelete}></i>}
            </div>
            <div className="degree-info-field">
                <p>{props.educationInfo.field_of_study}</p>
                <p>|</p>
                <p>{props.educationInfo.degree}</p>
                {props.educationInfo.gpa ? <div className="gpa-field">
                    <p>GPA:</p>
                    <p>{props.educationInfo.gpa}{props.educationInfo.gpa_scale ? ` of ${props.educationInfo.gpa_scale}` : ''}</p>
                </div> : null}

            </div>
            <div className="education-duration">
                <p>{convertDate(`${props.educationInfo.start_month} ${props.educationInfo.start_year}`)}</p>
                <p>-</p>
                <p>{props.educationInfo.end_month && props.educationInfo.end_year ? convertDate(`${props.educationInfo.end_month} ${props.educationInfo.end_year}`) : 'current'}</p>
            </div>
            {props.educationInfo.achievements && props.educationInfo.achievements.items.length > 0 &&
                <>
                    <p className="education-achievements">Achievements</p>
                    <div className="education-achievements-list-container">
                        {props.educationInfo.achievements.items.map(achievement => {
                            return (
                                <div className="education-achievement-item" key={`education-achievement-${Math.random()}`}>
                                    <div>
                                        <h4>{achievement.name}</h4>
                                        <p>{achievement.year}</p>
                                    </div>
                                    <p className="education-achievement-desc">{achievement.desc}</p>
                                </div>
                            );
                        })}
                    </div>
                </>
            }
        </div>
    );
};

Education.propTypes = {
    edit_edu: PropTypes.bool,
    edit_click: PropTypes.func,
    handleDelete: PropTypes.func,
    educationInfo: PropTypes.shape({
        institution: PropTypes.string,
        degree: PropTypes.string,
        gpa: PropTypes.number,
        gpa_scale: PropTypes.number,
        field_of_study: PropTypes.string,
        start_month: PropTypes.string,
        start_year: PropTypes.number,
        end_month: PropTypes.string,
        end_year: PropTypes.number,
        achievements: PropTypes.shape({
            items: PropTypes.array,
        }),
    }),
    id: PropTypes.number,
};

export default Education;