import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import './Subscription.css';
import { getAuthenticationHeader, backendURL } from '../Utils/utils';
import Loading from '../Utils/Loading';

// Component for cancel subscription
const ConfirmCancel = () => {
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        remove_membership();
    }, []);

    const remove_membership = async () => {
        try {
            const config = getAuthenticationHeader();
            const response = await axios.delete(`${backendURL}/usermembership/`, config);

            if (response.status === 204) {
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    if (isLoading) {
        return <Loading message={'Loading cancellation page'}/>;
    }

    return (

        <div className="cancel-confirm-body">
            <h2>
                Membership Cancellation
            </h2>
            <h4>Your plan has been canceled.</h4>
            <Link to="/createpost" >
                <h4>Go back to homepage</h4>
            </Link>
        </div>

    );
};

export default ConfirmCancel;
