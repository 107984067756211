import './AboutUs.css';

// Renders the privacy policy content.
const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy-container">
            <p>
                You can also view and download the full PDF version of this document
                <a href='https://cdn.pplldr.com/assets/PplLdr+Privacy+Policy.pdf' target='_blank' rel='noreferrer'> here</a>.
            </p>
            <h1>Privacy Policy Summary</h1>
            <h2>Who Are We?</h2>
            <p>
                PplLdr (People Leader) is a direct-to-consumer professional services and
                networking company that focuses on the portability of leadership assessments for professionals.
                We are headquartered in Little Rock, Arkansas.
            </p>

            <h2>Our Key Privacy Principles</h2>
            <p>
                PplLdr was founded in 2023 and has enjoyed the trust of its users ever since.
                PplLdr cares deeply about the privacy of its users and the privacy of their data, and its top priority is to honor their trust.
            </p>
            <p>
                PplLdr has never sold or licensed personal data (like customer names, email addresses,
                or other identifiable information) and will never do so in the future.
            </p>
            <p>
                Users can delete their data from PplLdr at any time. Deletion is permanent and irreversible.
            </p>
            <p>
                If anything material ever changes in this privacy policy, we will notify you by email.
            </p>

            <h2>What Information Do We Collect About You?</h2>
            <p>We collect the information that we need to provide our Service (as defined in our Terms & Conditions), as described below:</p>
            <h3>Information you share directly with us.</h3>
            <p>
                For example, information that you enter when you sign up for the Service, research on leaders within the service,
                communicate with our customer support staff and our automated virtual assistant, fill in a questionnaire or survey on our website, and so on.
            </p>
            <h3>Information from Public Records.</h3>
            <p>
                We digitize such records ourselves. These records may include public information about you.
            </p>
            <h3>Information from our video, picture, and content features.</h3>
            <p>
                If you choose to use these features, it identifies associates your information with the content and digitized items you
                upload to posts and to describe your profile. Other users will be able to view your uploads and associate your profile with
                your specific uploads.
            </p>
            <h3>Information we receive when you use our Service.</h3>
            <p>
                We collect web-behavior information using automated means of data collection technologies (like cookies)
                to enhance your experience of visiting the Website and understand how our Services are being used.
                See our <b>Cookies Policy</b> for more information.
            </p>

            <h2>How Do We Use Your Personal Information?</h2>
            <h3>To provide our Service to you.</h3>
            <p>
                This includes displaying your professional work experiences, educational experiences,
                and comparing your self-ratings to those that are using the service to rate you in overlapping
                career experiences such as the direct report / team member ratings.
            </p>
            <h3>To communicate with you about the Service.</h3>
            <h3>To market the Service to you.</h3>
            <p>from which you can opt-out at any time.</p>
            <h3>For internal business purposes.</h3>
            <p>
                To improve and develop new products and services, perform internal data analysis, analyze the use
                of the Website to improve the customer experience or assess our promotional campaigns.
            </p>
            <h3>For internal research, with your consent.</h3>
            <p>
                If you voluntarily choose to participate in our research and agree to any Informed Consent in
                the future that focuses on pattern recognition efforts within leadership or management sciences in general.
            </p>
            <h2>Will PplLdr Disclose any of Your Personal Information to Third Parties?</h2>
            <p>
                PERSONAL INFORMATION PROVIDED BY YOU WILL NEVER BE SOLD OR LICENSED BY US TO THIRD PARTIES,
                INCLUDING INSURANCE COMPANIES, RETAILERS, GOVERNMENT AGENCIES, OTHER CORPORATIONS OR EMPLOYERS.
            </p>
            <p>
                We will not provide information to law enforcement unless we are required by a valid court order
                or subpoena for specific information.
            </p>
            <p>
                We will not disclose any of your personal information to any third party except in the following very limited circumstances:
                (a) with our service providers (e.g., payment platforms) under the protection of appropriate agreements -
                to provide you with our Service; (b) with your specified direct reports / team members or employers to verify your
                educational credentials, career positions, employers, and timelines; (c) when required of us by law or during legal proceedings,
                or to prevent fraud and cybercrime; (d) for research that you consent to; and (e) in connection with the acquisition of our business.
            </p>

            <h2>Security of Your Personal Information</h2>
            <p>
                We have implemented technical, physical, and administrative security measures to protect against the loss,
                misuse, unauthorized access, alteration or disclosure of users&apos; personal information under our control.
                For example: we periodically review and enhance our security and privacy practices as necessary, we commission periodic
                penetration tests to test the robustness of the security of our Service, only authorized personnel have access
                to personal information, and we only work with labs and third parties who meet and commit to our security standards.
            </p>

            <h2>Managing Your Privacy</h2>
            <p>
                We give you the ability to share information in a variety of ways. You can choose when and with whom you share your
                information outside of the Service. You can give or decline consent for research, manage cookies using your browser
                controls, and request to delete your account and data at any time.
            </p>

            <h2>Full Privacy Policy</h2>
            <p>
                In this document (the <b>&quot;Privacy Policy&quot;</b>) we provide information on what personal information is collected and why,
                as well as how we use the personal information to provide you with a personalized, user-friendly experience on the Website.
                We also explain how you can view this personal information, control the way it is shared, or delete it.
            </p>

            <h1>Our Privacy Policy</h1>

            <h2>1. Changes to This Privacy Policy</h2>
            <p>
                If we decide to modify our Privacy Policy, we will issue an updated version of this Privacy Policy with an
                updated date legend and if the changes are material, we will also notify you via email. If you would like us
                to cease using or storing your information in accordance with the revised policy, then delete your account or
                contact us at <a href="mailto:info@pplldr.com">info@pplldr.com</a> and ask us to do it for you.
            </p>
            <p>
                Whenever this Privacy Policy is modified in substance, the label &quot;updated&quot; will be displayed
                prominently next to the &quot;Privacy Policy&quot; link in the footer of the Website pages. The &quot;updated&quot;
                label will be removed after 30 days. Please make sure you read our updated Privacy Policy before using our
                Website or Services. If you have questions about our changes, contact us at <a href="mailto:info@pplldr.com">info@pplldr.com</a>.
            </p>

            <h2>2. What Personal Information Is Collected from You or About You?</h2>
            <p>
                We collect information we believe is necessary to provide you with the Service. The amount of other personal
                information you decide to submit to PplLdr is up to you.
                Here are the types of personal information we request, collect or you provide:
            </p>
            <ol>
                <li>
                    <h3>Name, Contact Information and Payment Details:</h3>
                    <p>
                        When you sign up for the Service,
                        we ask for your name, gender, and email address, as well as birth year and country.
                        The birth year is collected to ensure that you comply with the Terms & Conditions with
                        regards to underage and minor users. If you purchase a subscription or another product,
                        we will need to know your postal address, phone number and payment details to facilitate payment and fulfillment.
                    </p>
                </li>
                <li>
                    <h3>Information on You and Others:</h3>
                    <p>
                        You may also enter additional personal information about yourself
                        and others in the course of building your profile or otherwise using our Services, e.g., names, relationships,
                        dates and places of education or employment, contact information such as email address, and photos.
                    </p>
                    <p>
                        If you choose to add an audio recording to a photo or a profile to your associated account(s), such recordings
                        will be stored and made available for listening by registered users to our Service. You can delete them at any time.
                    </p>
                </li>
                <li>
                    <h3>Information from Public Records:</h3>
                    <p>
                        We digitize, license, and procure records from various sources, including birth,
                        marriage and death certificates, census records, immigration lists, newspapers, and other records. Such records may
                        contain personal information relating to you.
                    </p>
                </li>
                <li>
                    <h3>Your Comments and Opinions:</h3>
                    <p>
                        If you post messages or comments on our platform, blogs, social media, or professional
                        networking sites / accounts, or our message boards, we may capture that information.
                    </p>
                </li>
                <li>
                    <h3>Your Use of the Service:</h3>
                    <p>
                        While you use our Service, we may collect information based on your interaction with
                        our website or from the devices or computers you use to access the Website, to enhance your experience of visiting
                        the Website and understand how our Services are being used. This may include web log information, “clickstream” data
                        (for example, the type of computer and browser you use, the address of the website from which you linked to the Website),
                        page views and IP addresses.
                    </p>
                </li>
                <li>
                    <h3>Your Communications:</h3>
                    <p>
                        Your communications with other users through the Service&apos;s features (e.g., PplLdr Inbox),
                        as well as information you provide us in communications with our customer support team (e.g., support tickets), or
                        other representatives and/or information you provide while interacting and communicating with our automated virtual
                        assistant, will be collected and recorded by us and, with respect to our automated virtual assistant, recorded by our
                        service provider.
                    </p>
                </li>
                <li>
                    <h3>Your Survey Answers:</h3>
                    <p>
                        If you voluntarily participate in any Surveys or questionnaires on the Service, we will collect
                        the information you provide.
                    </p>
                </li>
                <li>
                    <h3>Third Party Account Authentication Services:</h3>
                    <p>
                        You may link an account from Google or Facebook to your account on
                        our Service for authentication. This will allow you to use your credentials from the other service to create a
                        PplLdr account or to sign in to PplLdr without having to enter details manually such as your email address. If
                        you choose to do so, we will collect and use the information you authorized to share with us via that service,
                        (for example, the email address for a connected Google account), in accordance with this Privacy Policy.
                    </p>
                </li>
                <li>
                    <h3>Your Image Data:</h3>
                    <p>
                        If you use our photo features or video features, we will process information obtained from
                        photos you upload (&quot;Image Data&quot;), in order to provide the applicable service to you. These photo features
                        do not collect or retain any biometric information or biometric identifiers from your photos. Uploading photos,
                        or any other personal information, of living individuals to our Services, requires you to obtain their prior consent.
                    </p>
                </li>
            </ol>
            <h2>3. How Do We Use Your Personal Information?</h2>
            <ol>
                <li>
                    <h3>To provide the Service to You:</h3>
                    <p>
                        We primarily use your personal information to fulfill the purpose(s) for which you have provided the personal
                        information to us. These purpose(s) include displaying your educational credentials, work histories, job titles,
                        dates of attendance / employment, and all other relevant information to identify you through your building of your
                        profile on our Service.
                    </p>
                    <p>
                        When you engage with our automated virtual assistant, we and our service provider will process personal information,
                        record your chat communications, and use AI technology to analyze your request, all in order to provide you with a suitable
                        response and to improve your experience. Information that the automated virtual assistant recognizes as being a credit card
                        number (based on the unique pattern of credit card numbers), will not be captured.
                    </p>
                </li>
                <li>
                    <h3>To communicate with you:</h3>
                    <p>
                        We may communicate with you for the purpose of informing you of updates or additions to the Service, or to seek feedback
                        from you about the Service. Our communications with you will be conducted primarily via email, but may also be made via
                        telephone, direct mail, or another method of communication in some circumstances. If you do not want to continue to receive
                        emails from us, you may opt out at any time by using the unsubscribe link listed in the email or by setting your Email Preferences.
                        If you wish to opt out of other methods of communication, please contact us at <a href="mailto:info@pplldr.com">info@pplldr.com</a>.
                    </p>
                </li>
                <li>
                    <h3>To market our services:</h3>
                    <p>
                        By signing up to the Service, you agree that we may use your contact information as well as information about
                        your use of the Service, to offer you complementary PplLdr products or services. Such promotional offers may be
                        made via email, telephone, or direct mail. We will never send you promotional offers by Text Message (SMS).
                    </p>
                    <p>
                        If you do not want to receive marketing offers via email, you may opt out at any time by using the unsubscribe
                        link listed in the email or by setting your Email and Communication Preferences. See the section on Email
                        Preferences below. If you do not want to receive marketing offers via telephone, you can also do that via the
                        Email and Communication Preferences. You can also instruct us to stop communicating with you by contacting us
                        at <a href="mailto:info@pplldr.com">info@pplldr.com</a>. The aggregated information gathered from you and other users through the Surveys may be used
                        in our marketing, and such emails or promotional offers may be presented to you.
                    </p>
                </li>
                <li>
                    <h3>For internal business purposes:</h3>
                    <p>
                        In order to improve the Service and to develop new products and services, we may use your personal information for
                        internal data analysis, for studying how the Website is used, to help us diagnose problems and secure the Service,
                        identifying usage trends and determining the effectiveness of promotional campaigns. For example, we may examine
                        how much time visitors spend on each page of the Website and how they navigate through the Website and use this
                        information to improve the Website.
                    </p>
                    <p>
                        We use your IP address to deliver the Website and our Service to you and to help diagnose problems with our servers.
                        Your IP address is also used to gather broad demographic information such as geographic distribution of our members.
                        When you visit the Service for the first time, we use your IP address to suggest the Service to you in the language
                        deemed most appropriate for the geographical region from which it originates.
                    </p>
                </li>
            </ol>

            <h2>4. Legal Grounds for the Processing of Personal Information</h2>
            <p>
                Under EU data protection law, (&quot;GDPR&quot;), and Brazil&apos;s data protection law, the Lei Geral de Proteção de
                Dados (&quot;LGPD&quot;), all processing of personal information is justified by a &quot;condition&quot; for processing.
                In the majority of cases, any processing will be justified on the basis that:
            </p>
            <ul>
                <li><p>you have consented to the processing</p></li>
                <li><p>the processing is necessary to perform a contract with you (e.g., processing your payments or creating your user account)</p></li>
                <li><p>the processing is necessary to comply with a relevant legal obligation; or</p></li>
                <li><p>the processing is in our legitimate interests, subject to your interests and fundamental rights</p></li>
            </ul>

            <h2>5. Will PplLdr Disclose Any of Your Personal Information to Third Parties?</h2>
            <p>
                PERSONAL INFORMATION PROVIDED BY YOU WILL NEVER BE SOLD OR LICENSED BY US TO THIRD PARTIES, INCLUDING INSURANCE
                COMPANIES, RETAILERS, GOVERNMENT AGENCIES, OTHER CORPORATIONS OR EMPLOYERS.
            </p>
            <p>
                We will not provide information to law enforcement unless required by a valid court order or subpoena for genetic information.
            </p>
            <p>
                PplLdr will not disclose any of your personal information to any third party except in the limited circumstances listed below:
            </p>
            <ol>
                <li>
                    <h3>With our service providers:</h3>
                    <p>
                        We use several reputable third parties to perform various tasks for us, under the protection of appropriate
                        agreements. For example, we use leading third party platforms to process payments from you (mainly Adyen,
                        Stripe, BlueSnap and PayPal), to provide us with cloud storage services (mainly Amazon and Google Cloud),
                        to assist us in marketing and advertising, consumer research analytics, development of new algorithms and
                        services, fraud prevention, security and the processing of customer support requests. We use an automatic
                        tool called Google Dialogflow to semantically analyze incoming customer support tickets in order to classify
                        them according to their intent, for purposes of expediting and optimizing the processing of those tickets.
                        Before any tickets are passed to Dialogflow, we remove identifiable information (such as names and email
                        addresses), and all tickets are deleted from Dialogflow immediately after their intent is classified. For
                        purposes of expediting and optimizing the processing of incoming customer support calls, we use a text-to-speech
                        conversion service by Microsoft Azure, which helps us transcribe the content of incoming support calls. The
                        calls are automatically deleted after conversion to text and are not saved by Microsoft.
                        Our automated virtual assistant operates as our service provider and will not use your personal information
                        for any purpose but to provide us with the service and improve it.
                    </p>
                    <p>
                        We use Google Customer Reviews to collect feedback about our services. If you approve Google sending you a survey,
                        please note that your email, order delivery date, your country and the content of your review will be processed by
                        Google as a Controller and therefore will be subject to Google&apos;s privacy policy.
                    </p>
                    <p>
                        With respect to third parties outside the European Economic Area, we have taken steps to ensure your personal
                        information is adequately protected, as required by applicable law.
                    </p>
                </li>
                <li>
                    <h3>In legal or privacy circumstances:</h3>
                    <p>
                        If required of us by law or during legal proceedings, or to prevent fraud
                        and cybercrime.
                    </p>
                </li>
                <li>
                    <h3>In an acquisition of PplLdr:</h3>
                    <p>
                        In the event that PplLdr or substantially all of its assets or stock are acquired the processing and transfer
                        of personal information may be needed. Note that this situation is not unique to PplLdr and applies to most companies.
                    </p>
                </li>
            </ol>

            <h2>6. How Do You Delete Information About Yourself or Report It to Us?</h2>
            <h3>Deleting your account:</h3>
            <p>This will irreversibly delete your account and the personal data you&apos;ve entered.</p>
            <p>
                Deleting or amending information that others have posted about you or your family: You are not able to delete or amend
                such information posted by another member. Contact us at <a href="mailto:info@pplldr.com">info@pplldr.com</a> and we will then correspond with you to understand
                the specific information that you want to have deleted and will then proceed to disposition and seek to resolve the matter promptly.
            </p>
            <p>
                For disputes or issues with other personal information on the Website about you:  If you are a registered member of the Website,
                and you contact us with a request pertaining to information that you entered into the Website, we ask that you communicate to us
                from the same email address that you used to register to the Website. Otherwise, we may need to verify your identity before
                considering your request.
            </p>

            <h2>7. Cookies and Non-Personal Information</h2>
            <h3>Cookies</h3>
            <p>
                We make use of browser cookies and similar automated means of data collection technologies to enhance your experience of
                visiting the Website, for example, to avoid displaying certain messages to you more than once, to save your login details
                so you won&apos;t need to re-enter them each time you wish to log in, or to remember the display language you previously
                selected so you won&apos;t need to select it each time you visit the Website. You can disable or delete cookies from your
                computer if you wish, but certain parts of our service may not work correctly or at all if you do so. For more information
                about cookies including how to set your internet browser to reject cookies please go to www.allaboutcookies.org
            </p>
            <h3>Other non-personal information</h3>
            <p>
                When you visit our Website, we may automatically collect non-personal information about you, such as the website from which
                you have come to our Website, your computer type, screen resolution, Operating System version, mobile device details (if
                applicable) and Internet browser. We may also collect non-personal information such as demographic data, for example your
                geographic area. Non-personal information also includes personal information that has been aggregated in a manner such that
                the end-product does not personally identify you or any other user of the Website, for example, by using personal information
                to calculate the percentage of our users from a particular country or who are female. Because non-personal information does
                not personally identify you, we may use such non-personal information for any purpose. In addition, we reserve the right to
                share such non-personal information.
            </p>
            <p>
                Some web browsers and devices permit you to broadcast a preference that you are not “tracked” online. We do not modify your
                online experience based upon whether such a signal is broadcast.
            </p>

            <h2>8. Security and Transfer of Information</h2>
            <p>
                When you provide us with any personal information, that personal information may be transferred to and stored by us in our
                secure data centers which may provide a different level of protection for personal information than in your country of
                residence. By providing us with personal or genetic information, you should be aware that it will be transferred, processed,
                and stored in our data centers, located in the United States. You understand that your information will be stored in the United
                States as provided in our Terms and Conditions.
            </p>
            <p>
                PplLdr takes appropriate steps to ensure that transfers of personal information are done in accordance with applicable law
                and carefully managed to protect your privacy rights and interests. Accordingly, transfers are limited to countries which
                are recognized as providing an adequate level of legal protection or where we can be satisfied that alternative safeguards
                are in place to protect your privacy rights. In particular, we rely on the EU Commission standard contractual clauses. You
                have a right to contact us for more information about the safeguards we have put in place.
            </p>
            <p>
                We implement and maintain reasonable security, appropriate to the nature of the personal information that we collect, use,
                retain, transfer or otherwise process. We are committed to developing, implementing, maintaining, monitoring, and updating
                a reasonable information security program, but no such program can be perfect; in other words, all risks cannot reasonably
                be eliminated. Data security incidents and breaches can occur due to vulnerabilities, criminal exploits or other factors that
                cannot reasonably be prevented. Accordingly, while our reasonable security program is designed to manage data security risks
                and thus help prevent data security incidents and breaches, it cannot be assumed that the occurrence of any given incident
                or breach results from our failure to implement and maintain reasonable security.
            </p>
            <p>
                You are responsible for keeping your password for the Service confidential. PplLdr requires you to not share your password
                with anyone. PplLdr requires you to not use the same password that you use on PplLdron any other service.
            </p>
            <p>
                PplLdr will comply with applicable laws in the event of any breach of the security, confidentiality, or integrity of your
                personal information and, where we consider appropriate or where required by applicable law, notify you via email, in the
                most expedient time possible and without unreasonable delay, insofar as it is consistent with any measures necessary to
                determine the scope of the breach and restore the reasonable integrity of the data system.
            </p>

            <h2>9. Data Retention</h2>
            <p>
                We will retain your personal information only for as long as necessary to fulfil the purpose(s) for which it was collected
                and to comply with applicable laws. This means that we store your personal information for as long as it is required to
                deliver our services, except where we have a lawful basis for saving it for an extended period of time (for instance, after
                your subscription expires, we may still have a legitimate interest in using your contact details for marketing our service
                to you). We also retain the personal information we need for the execution of pending tasks and to realize our legal rights
                and our claims, as well as retain certain personal information that we must store for a legally mandatory period of time (in
                that latter case, the processing of such information by us is limited).
            </p>
            <p>
                In some cases, where you or we delete your content, copies of that information may remain viewable elsewhere to the extent
                any such copy has been shared with others, was otherwise distributed pursuant to your privacy settings, or was copied or
                stored by other users. Removed and deleted information may persist in backup copies for a limited time for our internal
                business purposes, but it will not be available to you or other users.
            </p>

            <h2>10. Managing Your Privacy</h2>
            <p>
                You can control how you share personal information by changing your privacy settings in the following areas:
            </p>
            <p>
                <b>Email Preferences:</b> this allows you to control what marketing emails and other emails we may send you.
                All non-transactional emails sent to you by PplLdr have a footer link that leads directly to the Email Preferences
                page in which you can conveniently turn off any particular email type you do not wish to receive any longer or re-enable
                some emails that you have turned off in the past. Family site emails have a link at their footer to the site preferences
                page which allows you to unsubscribe from or re-subscribe to event reminder emails and family site activity newsletter emails.
            </p>
            <p>
                PplLdr typically refrains from sending commercial offers originating from partners, affiliates and third parties.
                To the extent that PplLdr sends an offer, it is most likely about a service owned and operated by PplLdr or
                integrated into the Service.
            </p>
            <p>
                <b>Privacy Preferences:</b> this allows you to control the information that others can see about you and your personal information.
            </p>

            <h2>11. Your Rights</h2>
            <p>
                You may revise your personal information by adjusting your account settings. You also have the right to ask PplLdr to
                amend any personal information it holds about you if it is inaccurate or misleading as to any matter of fact. PplLdr has
                instituted a process for verifying that the person making a request is the customer about whom we have collected information.
                We may ask you to provide identifying information to compare it to the personal information already maintained by us. We may
                require a more stringent verification process depending on the sensitivity of the personal information involved. Any request
                to amend personal Information in PplLdr records should be in writing and addressed by email to <a href="mailto:info@pplldr.com">info@pplldr.com</a>.
            </p>

            <h2>12. Data Subject Rights</h2>
            <p>
                PplLdr members residing in the European Union, the European Economic Area, Andorra, Argentina, Australia, Brazil, California,
                Canada, Faroe Islands, Guernsey, Hong Kong, Israel, Isle of Man, Japan, Jersey, Mexico, New Zealand, Singapore, South Korea,
                Switzerland, Uruguay, and other jurisdictions have certain data subject rights. For members located in the European Economic
                Area, those rights include those set out below (and related limitations). For those members residing outside of the European
                Economic Area, these rights vary, but they may include the following rights, which may be subject to certain exemptions: 1.1
                The right to access information held about you. This right can normally be exercised free of charge. However, we reserve the
                right to charge an appropriate administrative fee where permitted by applicable law. 1.2 The right to object to processing
                which has our legitimate interests as its lawful basis (see &quot;Legal Grounds for the Processing of Personal Information&quot;
                section above). 1.3 The right to obtain a portable copy of personal information which is processed on the basis of your consent,
                or which is necessary for the performance of a contract between us (see &quot;Legal Grounds for the Processing of Personal
                Information&quot; section above). 1.4 The right to request rectification or restriction of the information we process about you.
                1.5. The right to withdraw your consent when we rely on this legal basis to process your personal information (see &quot;Legal
                Grounds for the Processing of Personal Information&quot; section above). 1.6 The right to request deletion of your personal
                information. Any request should be in writing and addressed to PplLdr by email at <a href="mailto:info@pplldr.com">info@pplldr.com</a>. PplLdr will use reasonable
                efforts to supply personal information about you on its files. PplLdr shall endeavor to respond as soon as practicably possible.
                PplLdr will make every effort to resolve all requests that we receive. However, if you are dissatisfied with our response to a
                request, you may have the right to lodge a complaint. Without limitation, in jurisdictions where the GDPR applies (i.e., the
                European Economic Area and the United Kingdom), you have the right to lodge a complaint with the data protection supervisory
                authorities.
            </p>

            <h2>13. GDPR</h2>
            <p>
                PplLdr has taken steps to ensure compliance with all applicable privacy laws, including the general data protection regulation (GDPR).
            </p>

            <h2>14. US State Privacy Notice</h2>
            <p>
                For information regarding privacy rights you may have under US state laws and related disclosures, please see our Terms & Conditions.
            </p>

            <h2>15. Brazil&apos;s Data Protection Law</h2>
            <p>
                This section addresses the legal obligations and rights laid out in the Lei Geral De Proteção De Dados (&quot;LGPD&quot;),
                which are applicable if:
            </p>
            <ul>
                <li><p>The processing operation is carried out in Brazil</p></li>
                <li><p>
                    The purpose of the processing activity is to offer or provide goods or services,
                    or the processing of data of individuals located in Brazil
                </p></li>
                <li><p>The personal data was collected in Brazil</p></li>
            </ul>
            <p>
                Under the foregoing circumstances, you have the following rights:
            </p>
            <ul>
                <li><p>Confirmation of the existence of processing;</p></li>
                <li><p>Access to your personal information;</p></li>
                <li><p>Correction of incomplete, inaccurate or out-of-date information;</p></li>
                <li><p>Portability your personal information;</p></li>
                <li><p>Deletion of your personal information;</p></li>
                <li><p>Information about third parties with whom personal information is shared;</p></li>
                <li><p>Access to this Privacy Policy and the Terms and Conditions;</p></li>
                <li><p>To revoke your consent.</p></li>
            </ul>
            <p>All these rights can be exercised free of charge.</p>
            <p>To exercise any of the rights described above, please email <a href="mailto:info@pplldr.com">info@pplldr.com</a>.</p>

            <h2>16. South Africa&apos;s Data Protection Law</h2>
            <p>
                This section addresses the legal obligations and rights laid out in the Protection of Personal Information Act,
                2013 (&quot;POPIA&quot;), which are applicable when the processing of personal information falls under the scope of
                POPIA, i.e., when personal information that is entered in a record is processed in South Africa.
            </p>
            <p>Under the above circumstances, you have following data subject rights:</p>
            <ul>
                <li><p>
                    to request access to, and information regarding the nature of personal information held and to whom it has been disclosed.
                    Such request for access may be refused on the basis of the grounds of refusal in the Promotion of Access to Information
                    Act, 2000;
                </p></li>
                <li><p>
                    to request the restriction of processing of personal information in the circumstances contemplated in POPIA;
                </p></li>
                <li><p>
                    to not have personal information processed for direct marketing purposes by means of unsolicited electronic
                    communications, save for in the circumstances permitted by POPIA;
                </p></li>
                <li><p>
                    to not to be subjected to a decision which is based solely on the automated processing of personal information intended
                    to provide a profile of such person, save for in the circumstances permitted by POPIA;
                </p></li>
                <li><p>
                    to request to rectify or correct personal information that is inaccurate, irrelevant, excessive, out of date, incomplete,
                    misleading or obtained unlawfully, subject to POPIA;
                </p></li>
                <li><p>
                    to request deletion of personal information that is inaccurate, irrelevant, excessive, out of date, incomplete, misleading
                    or obtained unlawfully or where we are no longer authorized to retain it, subject to POPIA;
                </p></li>
                <li><p>
                    to object to processing of personal information where the processing is on the basis that it protects the data subject&apos;s
                    legitimate interests; where the processing is on the basis that it is necessary for pursuing our legitimate interests or
                    a third party to whom it is disclosed; or where the processing is for the purposes of direct marketing other than by
                    unsolicited electronic communications;
                </p></li>
                <li>
                    <p>to lodge a complaint with the Information Regulator in South Africa using the details below:</p>
                    <p>
                        The Information Regulator<br/>
                        JD House, 27 Stiemens Street, Braamfontein, Johannesburg, South Africa<br/><br/>
                        email: POPIAComplaints@inforegulator.org.za / PAIAComplaints@inforegulator.org.za
                    </p>
                </li>
                <li><p>to institute civil proceedings regarding the alleged interference with the protection of personal information.</p></li>
            </ul>
            <p>To exercise any of the rights described above, please email <a href="mailto:info@pplldr.com">info@pplldr.com</a>.</p>

            <h2>17. Children</h2>
            <p>
                Our Services are not directed or targeted to children under the age of 18, and PplLdr does not knowingly collect personally
                identifiable information from children under the Minimum Age. If PplLdr learns that a child under the Minimum Age has provided
                personally identifiable information to the Website, we will use reasonable efforts to remove such information from our files.
                If you provide information about a child, you, as parent or legal guardian, consent to the processing and use of such
                information by us in accordance with this Privacy Policy.
            </p>

            <h2>18. Contacting Us About Privacy</h2>
            <p>
                If you have any questions about this Privacy Policy, the practices of this Website, or your dealings with this Website, you can
                contact us via email at <a href="mailto:info@pplldr.com">info@pplldr.com</a>. You can also contact us if you wish to request access to or receive information about
                the personal information that we maintain about you, and have such information deleted. If you have legitimate reason, you can
                oppose the processing that was carried out with your personal information. Note that the right to access certain personal
                information may be limited in some circumstances.
            </p>
        </div>
    );
};

export default PrivacyPolicy;
