import { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { getAuthenticationHeader, backendURL, logOut } from '../Utils/utils';
import './Chatbot.css';
import '../Utils/common.css';

// Handling AI chat interactions and user interface.
const Chatbot = () => {
    const [autoPlay, setAutoPlay] = useState(false);
    const [recording, setRecording] = useState(false);
    const [startNew, setStartNew] = useState(null);
    const [conversation, setConversation] = useState([]);
    const [startSession, setStartSession] = useState(false);
    const [chatMode, setChatMode] = useState('normalChat');

    const [messages, setMessages] = useState([]);
    const [inputText, setInputText] = useState('');
    const [stopVoicePlay, setStopVoicePlay] = useState(false);

    const currentDate = new Date();

    const [botResponse, setBotResponse] = useState('');
    const [startCall, setStartCall] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [synthesisComplete, setSynthesisComplete] = useState(true);
    const [isTyping, setIsTyping] = useState(false);
    const scrollableDivRef = useRef(null);

    let voiceStreamMsg;

    // recognition object for voice-to-voice
    const recognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;

    const recognitionInstance = new recognition();

    // recorder/recgnition object for speech use in normal chat
    const mediaRecorder = useRef(null);

    useEffect(() => {

        if (chatMode === 'normalChat') { return; }

        if (startCall) {
            recognitionInstance.continuous = true;

            recognitionInstance.onstart = () => {
                console.log('Speech recognition started...');
            };

            recognitionInstance.onresult = (event) => {
                const last = event.results.length - 1;
                const userSpeech = event.results[last][0].transcript;
                if (synthesisComplete) {
                    setInputText(userSpeech);
                    voiceStreamMsg = userSpeech;
                }
            };

            recognitionInstance.onspeechend = async () => {
                if (!voiceStreamMsg) {
                    return;
                }
                if (voiceStreamMsg.trim() !== '' && !processing && synthesisComplete) {
                    setProcessing(true);
                    // Call your chatbot API here and update botResponse
                    await handleChatbotRequest(voiceStreamMsg);
                    setProcessing(false);
                }
            };

            if (synthesisComplete) {
                recognitionInstance.start();
            }
        }
        else {
            recognitionInstance.stop();
            setBotResponse('');
        }

    }, [inputText, startCall, synthesisComplete]);


    const handleChatbotRequest = async (question) => {
        try {

            if (question.trim() === '') return;
            setMessages((prevMessages) => [...prevMessages, { id: uuidv4(), text: question, user: true }]);
            setInputText('');
            setIsTyping(false);

            const config = getAuthenticationHeader();

            const requestData = {
                conversation: conversation,
                user_input: question,
                start_new: startNew,
            };

            // Send user message to the chatbot API
            setProcessing(true);
            const response = await axios.post(`${backendURL}/openai/chatbot/`, requestData, config);
            if (response.status === 200) {
                const chatResponse = response.data.chat_response;
                setProcessing(false);
                setBotResponse(chatResponse);
                setConversation(response.data.conversation);
                setStartNew(false);
                // Add chatbot response to the chat
                setMessages((prevMessages) => [...prevMessages, { id: uuidv4(), text: chatResponse, user: false }]);
            }


        } catch (error) {
            logOut();
            console.error('Error in OpenAI API request:', error);
        }
    };

    useEffect(() => {
        // Use text-to-speech for bot response
        const synth = window.speechSynthesis;

        if (botResponse.trim() !== '') {

            const utterance = new SpeechSynthesisUtterance(botResponse);

            // when response speacking is complete
            utterance.onend = () => {
                setSynthesisComplete(true);
            };

            setSynthesisComplete(false);

            if (chatMode === 'normalChat') {
                if (autoPlay) {
                    synth.speak(utterance);
                }
                else {
                    setSynthesisComplete(true);
                }
            }
            else {
                synth.speak(utterance);
            }
        }
        if (stopVoicePlay) {
            synth.cancel();
        }

    }, [botResponse, stopVoicePlay]);

    useEffect(() => {
        // Scroll to the bottom when message changes
        if (scrollableDivRef.current) {
            scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
        }
    }, [messages]);

    const handleCall = () => {

        if (!startCall) {
            setBotResponse('You have been connected withh AI assistant, You can talk to it now.');
        }

        setStartCall(!startCall);
    };

    const startRecording = async () => {

        mediaRecorder.current = new recognition();

        mediaRecorder.current.continuous = true;
        mediaRecorder.current.start();
        setRecording(true);

        mediaRecorder.current.onresult = (event) => {
            const last = event.results.length - 1;
            const userSpeech = event.results[last][0].transcript;
            if (synthesisComplete) {
                setInputText(userSpeech);
            }
        };
    };

    const stopRecording = () => {
        mediaRecorder.current.stop();
        setRecording(false);
    };

    const handleRecording = async () => {
        try {
            if (!recording) {
                await startRecording();
            } else {
                stopRecording();
            }
        } catch (error) {
            console.error('Error handling recording:', error);
        }
    };

    const closeSession = () => {
        // initiate all
        setStartSession(false);
        setMessages([]);
        setRecording(false);
        setIsTyping(false);
        setStopVoicePlay(true);
        setInputText('');
        setBotResponse('');
        setStartCall(false);
        setSynthesisComplete(true);
    };

    const startChat = () => {
        setStartSession(true);
        setStartNew(true);
        setStopVoicePlay(false);
    };

    const handleChatModeChange = (event) => {
        setChatMode(event.target.value);
    };

    const handleInput = (e) => {
        setInputText(e.target.value);
        if (e.target.value.trim() !== '') {
            setIsTyping(true);
        }
        else {
            setIsTyping(false);
        }
    };

    return (
        <div className='ai-chat-body'>

            {!startSession &&
                (<div>
                    <h2>Welcome to Our AI Chat Tool</h2>
                    <p>
                        Our AI chat tool is here to assist you with questions related to leadership and psychology. Please keep in mind the following:
                    </p>
                    <ul>
                        <li>The answers generated by the AI are for reference purposes only.</li>
                        <li>We do not take responsibility for the results or decisions based on the AI-generated answers.</li>
                        <li>We do not save the chat history, and it will be cleared once you close the chat window.</li>
                    </ul>
                    <p>
                        Feel free to ask questions, but be aware that the responses are generated by AI and may not always be accurate. Use the information as a starting point for further exploration and consult with qualified professionals for critical decisions.
                    </p>
                    <p>Thank you for using our AI chat tool!</p>
                    <h6>Please select Chat Mode</h6>
                    <div className='ai-chat-setting'>
                        <label>
                            <input
                                type="radio"
                                value="realTimeCall"
                                checked={chatMode === 'realTimeCall'}
                                onChange={handleChatModeChange}
                            />
                            Real Time Call
                        </label>

                        <label>
                            <input
                                type="radio"
                                value="normalChat"
                                checked={chatMode === 'normalChat'}
                                onChange={handleChatModeChange}
                            />
                            Normal Chat
                            <label>
                                &nbsp;&nbsp;--with auto-play response audio &nbsp;
                                <input type='checkbox' checked={autoPlay} onChange={() => setAutoPlay(prevAutoPlay => !prevAutoPlay)} disabled={chatMode === 'realTimeCall'} />
                            </label>
                        </label>
                    </div>

                    <div className='ai-chat-tool-bar'>
                        <button className='theme-button' onClick={startChat}>start chat</button>
                    </div>
                </div>)}

            {startSession &&

                (<div>
                    <h4>Chat with AI assistant</h4>
                    <div className='ai-chat-room' ref={scrollableDivRef}>
                        <i>The AI assistant is available to assist you with any inquiries regarding leadership, and you can input or vocalize your questions for a response.</i>
                        <div className='ai-chat-current-date'>{currentDate.toDateString()}</div>
                        {messages.map((message) => (
                            <div key={message.id} style={{ textAlign: message.user ? 'right' : 'left' }}>
                                {!message.user ? <p>AI assistant:</p> : <p>you:</p>}
                                <div className={`ai-chat-msg-container-${message.user ? 'right' : 'left'}`}>
                                    <div className={message.user ? 'ai-chat-user-msg' : 'ai-chat-sys-msg'}>{message.text}</div>
                                </div>
                            </div>
                        ))}
                        {processing && <div className='ai-chat-btm-msg'>AI assistant is responding... please wait</div>}
                    </div>

                    {chatMode === 'normalChat' ? (
                        <div className='ai-chat-tool-bar'>

                            {!recording ? (<div className='ai-chat-typing-option'>
                                <div className='ai-chat-input'>
                                    <textarea
                                        value={inputText}
                                        rows={2}
                                        onChange={(e) => handleInput(e)}
                                        placeholder='Ask your questions here'
                                        style={{
                                            overflow: 'hidden',
                                            resize: 'none',
                                            width: '100%',
                                        }}
                                    />
                                    {!isTyping && (<button className='ai-chat-input-btm-button' onClick={handleRecording}>
                                        <i className='fa-solid fa-microphone'></i>
                                    </button>)}
                                </div>

                                <button className='theme-button' onClick={() => handleChatbotRequest(inputText)}>Send</button>
                            </div>)
                                :
                                (<Button variant='primary' onClick={handleRecording}>
                                    <Spinner
                                        as='span'
                                        animation='grow'
                                        size='sm'
                                        role='status'
                                        aria-hidden='true'
                                    />
                                    Recording...click to stop
                                </Button>)}

                        </div>)
                        :
                        (<div>
                            {startCall && (<Button variant='primary' disabled>
                                <Spinner
                                    as='span'
                                    animation='grow'
                                    size='sm'
                                    role='status'
                                    aria-hidden='true'
                                />
                                In Call with AI Assistant...
                            </Button>)}

                            <div className='ai-chat-tool-bar'>
                                <button className={!startCall ? 'ai-chat-green-phone' : 'ai-chat-red-phone'} onClick={handleCall}>
                                    <i className="fa-solid fa-phone fa-lg"></i>
                                    {!startCall ? 'Start Call' : 'End Call'}
                                </button>
                            </div>
                        </div>)}

                    <div className='ai-chat-tool-bar'>
                        <button className='theme-button' onClick={closeSession}>
                            Close Session
                        </button>
                    </div>
                </div>)}
        </div>
    );
};

export default Chatbot;