import SecuritySetting from './SecuritySetting';
import NotificationSetting from './NotificationSetting';
import PrivacySetting from './PrivacySetting';
import { useState } from 'react';
import './Setting.css';

// Component for setting (security, notification, privacy)
const Setting = () => {
    const [selectedFunc, setSelectedFunc] = useState('security');

    const navigateToSecurity = () => {
        setSelectedFunc('security');
    };

    const navigateToNotification = () => {
        setSelectedFunc('notification');
    };

    const navigateToPrivacy = () => {
        setSelectedFunc('privacy');
    };

    return (
        <div className="settings-page">
            <div className="settings-sidebar">
                <ul>
                    <li
                        className={`setting-item ${selectedFunc === 'security' ? 'selected' : ''}`}
                        onClick={navigateToSecurity}
                    >
                        <i className="fa-solid fa-lock"/>
                        Security
                    </li>
                    <li
                        className={`setting-item ${selectedFunc === 'privacy' ? 'selected' : ''}`}
                        onClick={navigateToPrivacy}
                    >
                        <i className="fa-solid fa-shield-halved"/>
                        Privacy
                    </li>
                    <li
                        className={`setting-item ${selectedFunc === 'notification' ? 'selected' : ''}`}
                        onClick={navigateToNotification}
                    >
                        <i className="fa-solid fa-bell"/>
                        Notification
                    </li>
                </ul>
            </div>
            <div className="settings-content">
                { selectedFunc === 'security' && <SecuritySetting/>}
                { selectedFunc === 'notification' && <NotificationSetting/>}
                { selectedFunc === 'privacy' && <PrivacySetting/>}
            </div>
        </div>
    );
};

export default Setting;
