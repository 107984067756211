import './Faq.css';
import PropTypes from 'prop-types';

// Renders a FAQ item with a question, answer, and optional links.
const FaqItem = (props) => {
    let expanded = false;

    const toggleContent = (e) => {
        e.stopPropagation();
        const item = document.getElementById(`${props.id}-p`);
        const links = document.getElementsByClassName(`${props.id}-a`);

        expanded = !expanded;
        if (expanded) {
            item.style.lineHeight = '25px';
            item.style.color = 'black';
            item.style.cursor = 'text';

            for (const link of links) {
                link.style.position = 'relative';
                link.style.lineHeight = '25px';
                link.style.color = 'var(--main-accent-blue)';
                link.style.cursor = 'pointer';
            }
        }
        else {
            item.style.lineHeight = '0px';
            item.style.color = 'var(--transparent)';
            item.style.cursor = 'default';

            for (const link of links) {
                link.style.position = 'absolute';
                link.style.lineHeight = '0px';
                link.style.color = 'var(--transparent)';
                link.style.cursor = 'default';
            }
        }
    };

    return (
        <div className = "faq-item">
            <div onClick={toggleContent}>
                <h3>{props.question}</h3>
                <i className="fa-solid fa-chevron-down fa-2xl faq-expand-chevron" onClick={toggleContent}></i>
            </div>
            <p id={`${props.id}-p`}>{props.answer}</p>
            {props.links && props.links.map((link, idx) => {
                return (
                    <a
                        href={link}
                        key={`${props.id}-${idx}`}
                        className={`${props.id}-a`}
                    >{link}</a>
                );
            })}
        </div>
    );
};

FaqItem.propTypes = {
    question: PropTypes.string,
    answer: PropTypes.string,
    links: PropTypes.any,
    id: PropTypes.string,
};

export default FaqItem;