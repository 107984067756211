import { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import './Awards.css';
import { backendURL, convertDate, getAuthenticationHeader } from '../Utils/utils';

// List of awards/achievements with deletion functionality.
const Awards = (props) => {
    const [index, setIndex] = useState(1);
    const [items, setItems] = useState(props.data);

    useEffect(() => setItems(props.data), [props.data]);

    const scrollIntoView = () => {
        const container = document.getElementById('awards-container');
        const child = document.getElementById(`user-achievement-${index}`);
        if (child) {
            container.scrollTo({
                left: child.offsetLeft,
                behavior: 'smooth',
                block: 'start',
                inline: 'start',
            });
        }
    };

    const return_edit_item = (returnIndex) => props.callback(items[returnIndex]);

    const delete_item = async (id) => {
        try {
            const config = getAuthenticationHeader();
            await axios.delete(`${backendURL}/achievements?id=${id}`, config);
            props.handleDelete();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div id="awards-section-wrapper">
            <button className="carousel-btn" onClick={() => { setIndex((index - 1) % (items.length - 2)); scrollIntoView(); }}><i className="fa-solid fa-circle-chevron-left"></i></button>
            <button className="carousel-btn" onClick={() => { setIndex((index + 1) % (items.length - 2)); scrollIntoView(); }}><i className="fa-solid fa-circle-chevron-left fa-rotate-180"></i></button>
            <div id="awards-container">
                {items.map((item, idx) => {
                    return (
                        <div
                            key={`user-achievement-${idx}`}
                            className="achievement-item-container"
                            id={`user-achievement-${idx}`}
                        >
                            <div className="top-bar">
                                <h3>{item.title}</h3>
                                {props.edit_achievement && <i className="fa-solid fa-pen edit-btn" onClick={() => { return_edit_item(idx); }}></i>}
                                {props.edit_achievement && <i className="fa-solid fa-trash del-btn" onClick={() => { delete_item(item.id); }}></i>}
                            </div>
                            <div className="education-duration">
                                {item.date && convertDate(item.date)}
                            </div>
                            <p>{item.description}</p>
                            {((item.external_link && item.external_link !== '') ||
                                (item.thumbnail_link && item.thumbnail_link !== '')) &&
                                <div className="achievement-external-link">
                                    <p>{item.title}</p>
                                    <p>{item.external_link}</p>
                                    {item.external_link && item.file_link &&
                                        <a
                                            href={item.file_link}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="achievement-file-link"
                                        >
                                            <p className="achievement-file-link">View Document</p>
                                        </a>
                                    }
                                    <img
                                        onClick={() => {
                                            window.open(
                                                item.external_link ? item.external_link :
                                                    item.file_link ? item.file_link : null,
                                                'noreferrer',
                                            );
                                        }}
                                        src={item.thumbnail_link}
                                        alt="thumbnail"
                                    />
                                </div>
                            }
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

Awards.propTypes = {
    data: PropTypes.array,
    callback: PropTypes.func,
    edit_achievement: PropTypes.bool,
    handleDelete: PropTypes.func,
};

export default Awards;