import propTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import './FeaturedContent.css';
import defaultPfp from '../../assets/default_pfp.png';


const leadershipClassificationColor = {
    '10': 'rgb(0, 169, 207)',
    '9': 'rgb(0, 207, 176)',
    '8': 'rgb(45, 207, 0)',
    '7': 'rgb(107, 207, 0)',
    '6': 'rgb(173, 207, 0)',
    '5': 'rgb(207, 204, 0)',
    '4': 'rgb(207, 176, 0)',
    '3': 'rgb(193, 128, 30)',
    '2': 'rgb(173, 75, 0)',
    '1': 'rgb(130, 0, 0)',
    '0': 'rgb(100, 0, 0)',
};

// Renders a featured leader card with the leader's profile information and average score.
const FeaturedLeader = (props) => {
    const styles = {
        backgroundColor: leadershipClassificationColor[parseInt(props.avgScore)],
        width: '100%',
        boxSizing: 'content-box',
        borderLeft: `20px solid ${leadershipClassificationColor[parseInt(props.avgScore)]}`,
        borderRight: `20px solid ${leadershipClassificationColor[parseInt(props.avgScore)]}`,
        borderRadius: '10px 10px 0 0',
        color: 'white',
    };

    const navigate = useNavigate();

    const toProfile = () => {
        navigate(`/user/ratingscore/${props.leader.first_name}-${props.leader.last_name}-${props.leader.id}`);
    };

    return (
        <div className='featured-leader-card' onClick={toProfile}>
            <h5 style={styles}>
                {props.avgScore}
            </h5>
            <img
                src={props.leader.profile_image ?? defaultPfp}
                alt='leader pfp'
                onError={(e) => {
                    e.target.src = defaultPfp;
                }}
            />
            <h5>{props.leader.first_name} {props.leader.last_name}</h5>
            {props.leader.bio &&
                <p>
                    {props.leader.bio.substring(0, 50)}
                    {(props.leader.bio.length > 50) && <>...</>}
                </p>
            }
        </div>
    );
};

FeaturedLeader.propTypes = {
    leader: propTypes.object,
    avgScore: propTypes.string,
};

export { FeaturedLeader };