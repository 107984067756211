import { useState } from 'react';
import axios from 'axios';
import { Form, Button } from 'react-bootstrap';
import { getAuthenticationHeader, backendURL, logOut } from '../Utils/utils';
import { toast } from 'react-toastify';
import '../Utils/common.css';

// Component for security settings
const SecuritySetting = () => {
    const [isPwdExpanded, setIsPwdExpanded] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');

    const togglePwd = () => {
        setIsPwdExpanded(!isPwdExpanded);
    };

    const handleChangePassword = async (e) => {
        e.preventDefault();

        try {
            const config = getAuthenticationHeader();

            if (newPassword !== repeatPassword) {
                toast.error('Your re-entered password does not match');
                setCurrentPassword();
                return;
            }

            if (newPassword.length < 8) {
                toast.error('Password must be at least 8 characters long');
                setCurrentPassword();
                return;
            }

            const requestData = {
                'password': currentPassword,
                'new_password': newPassword,
            };

            const response = await axios.post(`${backendURL}/setting/change_password/`, requestData, config);
            if (response.status === 200) {
                toast.success('Password changed successfully');
            }
            else {
                toast.error(response.data);
            }
            setNewPassword('');
            setRepeatPassword('');
            setCurrentPassword('');

        } catch (err) {
            logOut();
            console.error(err);
        }
    };

    return (
        <div className="security-settings">
            <h3>Security settings</h3>
            <p>Change your security preferences</p>
            <div className='setting-pwd-header' onClick={togglePwd}>
                {isPwdExpanded ? <span><i className="fa-solid fa-caret-down fa-xl" />&nbsp; Change Password</span>
                    : <span><i className="fa-solid fa-caret-right fa-lg" />&nbsp; Change Password</span>}
            </div>
            {isPwdExpanded && (
                <Form className='edit-form-group' onSubmit={handleChangePassword}>
                    <Form.Group >
                        <Form.Control
                            type="password"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            placeholder="Current Password"
                            required />
                    </Form.Group>

                    <Form.Group>
                        <Form.Control
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            placeholder="New Password"
                            required />
                    </Form.Group>

                    <Form.Group>
                        <Form.Control
                            type="password"
                            value={repeatPassword}
                            onChange={(e) => setRepeatPassword(e.target.value)}
                            placeholder="Repeat New Password"
                            required />
                    </Form.Group>
                    <Button type="submit" className='setting-save-change-button theme-button'>
                        Save password
                    </Button>
                </Form>)}
        </div >
    );
};

export default SecuritySetting;
