import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import './Subscription.css';
import { getAuthenticationHeader, backendURL } from '../Utils/utils';
import Loading from '../Utils/Loading';

// Component for cancel subscription
const CancelSubscription = () => {
    const [plan_txt, setPlan_txt] = useState([]);
    const [plan_data, setPlan_data] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const navigate = useNavigate();

    // Store the token in localStorage for later use
    const currentplan = localStorage.getItem('currentplan');

    useEffect(() => {
        get_membership_txt();
        get_membership();
    }, []);

    const get_membership = async () => {
        try {
            const config = getAuthenticationHeader();
            const response = await axios.get(`${backendURL}/membership/${currentplan}`, config);

            setPlan_data(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const keepplan = () => {
        navigate('/createpost');
    };

    const get_membership_txt = async () => {
        try {
            const config = getAuthenticationHeader();

            // get all membership benefit description
            const response = await axios.get(`${backendURL}/membership_txt`, config);
            const plan_txts = response.data;

            // filter membership benefits description by membership type
            const currentplantext = plan_txts.filter((text) => text.membership_type === currentplan);
            setPlan_txt(currentplantext);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const cancelplan = async () => {
        const config = getAuthenticationHeader();
        const response = await axios.delete(`${backendURL}/stripe/subs_cancel`, config);
        if (response.status === 201) {
            console.log('navigating to stripe window');
            const stripe_url = response.data;
            window.location.replace(stripe_url);
        }
    };

    if (isLoading) {
        return <Loading message={'Loading cancellation page'}/>;
    }

    return (
        <div className='plans-container'>
            <div className='subscription-title'>
                <h3>Pricing Plan</h3>
                <h4>List of our pricing packages</h4>
            </div>
            {/*  if user is on default free memebership, then display message and tell user no plan to cancel*/}
            {!currentplan && (<h3>You have not subscribed any plan. Please enroll new plan.</h3>)}

            {currentplan && (<div className='plans-cancel-row '>
                <div className='plan-desc'>
                    <h3>{currentplan === 'Free' ? <i className="fas fa-rocket" /> : <i className="far fa-gem" />} {currentplan}</h3>
                    <div className="price-row">
                        {plan_data.map((plan, index) => (
                            <h5 key={index}>
                                <sup>$</sup>{plan.price} {plan.terms}
                            </h5>
                        ))}
                    </div>
                    {plan_txt.map((plan, index) => (
                        <p key={index}><i className="fa-solid fa-circle-check" style={{ color: '#4caf50' }} />{plan.text}</p>
                    ))}

                    <div className='footer-btns'>
                        <button type="submit" className='keep-plan-button' onClick={keepplan}>Keep {currentplan}</button>
                        <button type="submit" className='set-plan-button' onClick={(e) => cancelplan(e)}>Confirm Cancellation</button>
                    </div>
                </div>
            </div>)}
        </div>
    );
};

export default CancelSubscription;
