import PropTypes from 'prop-types';
import './Loading.css';

// Component for loading
const Loading = (props) => {
    return (
        <div className="loading-component-container">
            <div className="outer-circ-loading">
                <div className="inner-circ-loading">
                    <div className="inner-outer-circ-loading">
                        <div className="inner-inner-circ-loading">
                        </div>
                    </div>
                </div>
            </div>
            <h4>{props.message}</h4>
        </div>
    );
};

Loading.propTypes = {
    message: PropTypes.string,
};

export default Loading;