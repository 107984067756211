import { useState } from 'react';
import axios from 'axios';
import { Form, Button } from 'react-bootstrap';

import UserThumbnail from '../Utils/UserThumbnail';
import '../Utils/common.css';
import propTypes from 'prop-types';
import './Post.css';
import { getAuthenticationHeader, backendURL, logOut } from '../Utils/utils';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocate from 'dayjs/plugin/updateLocale';
dayjs.extend(relativeTime);
dayjs.extend(updateLocate);

dayjs.updateLocale('en', {
    relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: 'a few seconds',
        m: '1m',
        mm: '%dm',
        h: '1h',
        hh: '%dh',
        d: '1d',
        dd: '%dd',
        M: '1mo',
        MM: '%dmo',
        y: '1y',
        yy: '%dy',
    },
});

// Rendering a post with like, comment, and other options.
const Post = (props) => {
    const [activeLike, setActiveLike] = useState(props.activeLike);
    const [post, setPost] = useState(props.post);
    const [viewComments, setViewComments] = useState(false);
    const [comments, setComments] = useState([]);
    const [userComment, setUserComment] = useState(undefined);
    const [likeSpam, setLikeSpam] = useState(false);
    const [commentSpam, setCommentSpam] = useState(false);
    const [edit, setEdit] = useState(false);
    const [editContent, setEditContent] = useState(post.content);
    const [moreComments, setMoreComments] = useState(false);
    const [commentsPage, setCommentsPage] = useState(0);
    const date = new Date(props.createdAt);

    const handleLikeClick = async () => {
        if (likeSpam) return;

        setLikeSpam(true);
        const config = {
            ...getAuthenticationHeader(),
        };

        const data = { data: { 'post_id': post.id } };

        if (activeLike) {
            const response = await axios.delete(`${backendURL}/social_post_like/`, { ...config, ...data });
            if (response.status === 204) {
                setActiveLike(false);
                setPost(prevPost => ({ ...prevPost, likes: prevPost.likes - 1 }));
            }
            else if (response.status === 401) logOut();
        }
        else {
            const response = await axios.put(`${backendURL}/social_post_like/`, data.data, config);
            if (response.status === 204) {
                setActiveLike(true);
                setPost(prevPost => ({ ...prevPost, likes: prevPost.likes + 1 }));
            }
            else if (response.status === 401) logOut();
        }
        setLikeSpam(false);
    };

    const textareaHeightAdjust = (e) => {
        e.style.height = 'auto';
        e.style.height = `calc( ${(e.scrollHeight)}px + 5px)`;
    };

    const fetchComments = async () => {
        const config = {
            ...getAuthenticationHeader(),
            params: {
                post_id: post.id,
                page: commentsPage,
            },
        };
        try {
            const response = await axios.get(`${backendURL}/social_post_comment/`, config);

            if (response.status === 401) {
                logOut();
            }
            setComments(prev => [...prev, ...response.data.comments]);
            if (response.data.more_available) {
                setCommentsPage(prev => (prev + 1));
                setMoreComments(true);
            }
            else setMoreComments(false);
        }
        catch (e) {
            console.error(e);
        }
    };

    const toggleCommentPanel = () => {
        setViewComments(!viewComments);

        if (comments.length === 0) fetchComments();
    };

    const makeComment = async (e) => {
        e.preventDefault();

        if (commentSpam || userComment.split(' ').join('') === '') return;

        setCommentSpam(true);
        const config = getAuthenticationHeader();

        const data = {
            post_id: post.id,
            content: userComment,
        };

        try {
            const response = await axios.post(`${backendURL}/social_post_comment/`, data, config);

            if (response.status === 200) {
                const commentList = [response.data, ...comments];
                setPost(prevData => ({ ...prevData, comments: prevData.comments + 1 }));
                setComments(commentList);
                setUserComment('');
                document.getElementById('post-comment-text-area').style.height = 'auto';
            }
            else if (response.status === 401) {
                logOut();
            }
        }
        catch (error) {
            console.error(error);
        }
        setCommentSpam(false);
    };

    const deleteComment = async (comment_id, idx) => {
        const config = {
            ...getAuthenticationHeader(),
            data: {
                post_id: post.id,
                comment_id: comment_id,
            },
        };

        try {
            const response = await axios.delete(`${backendURL}/social_post_comment/`, config);

            if (response.status === 204) {
                setPost(prevData => ({ ...prevData, comments: prevData.comments - 1 }));
                const commentList = [...comments.slice(0, idx), ...comments.slice(idx + 1)];
                setComments(commentList);
            }
            else if (response.status === 401) {
                logOut();
            }
        }
        catch (e) {
            console.error(e);
        }
    };

    const deletePost = async () => {
        if (!toggled) {
            return;
        }
        try {
            const config = {
                ...getAuthenticationHeader(),
                data: {
                    post_id: post.id,
                },
            };

            axios.delete(`${backendURL}/social_post/`, config);
            props.dynamicPostRemoveCallBack(props.idx);
        }
        catch (e) {
            console.error(e);
        }
    };

    const editPost = async (e) => {
        e.preventDefault();
        try {
            const config = getAuthenticationHeader();
            const data = {
                content: editContent,
                post_id: post.id,
            };

            const response = await axios.patch(`${backendURL}/social_post/`, data, config);
            setPost({
                ...post,
                content: response.data,
            });
            setEdit(false);
            setEditContent(response.data);
        }
        catch (err) {
            console.error(err);
        }
    };

    let toggled = false;
    const toggleOptions = (panelId) => {
        try {
            const optionPanel = document.getElementById(panelId);

            if (toggled) {
                optionPanel.style.transform = 'translateY(calc(-100% - 60px))';
                optionPanel.style.opacity = 0;
                setTimeout(() => {
                    optionPanel.style.zIndex = '-100';
                }, 200);
            }
            else {
                optionPanel.style.transform = 'translateY(0)';
                optionPanel.style.opacity = 1;
                optionPanel.style.zIndex = '100';
            }
            toggled = !toggled;
        }
        catch (error) {
            console.error(error);
        }
    };

    return (
        <div className={`post-card ${props.highlighted ? 'post-highlighted' : ''}`}>
            <div>
                <UserThumbnail userProfile={post.user} self={false} />
                <div className='right-info-container'>
                    <p className='post-date'>{dayjs(date).fromNow()}</p>
                    {props.userId === post.user.id &&
                        <>
                            <button
                                className='post-toggle-btn'
                                onClick={() => {toggleOptions(`option-${post.id}-panel`);}}
                            >
                                <i className="fa-solid fa-ellipsis"></i>
                            </button>
                            <div className='post-option' id={`option-${post.id}-panel`}>
                                <button onClick={deletePost}>
                                    Delete this post
                                </button>
                                <button onClick={() => {
                                    setEdit(!edit);
                                    toggleOptions(`option-${post.id}-panel`);
                                }}>
                                    Edit this post
                                </button>
                            </div>
                        </>
                    }
                </div>
            </div>
            {edit ?
                <Form className='edit-form-group post-edit-form' onSubmit={editPost}>
                    <Form.Control
                        as='textarea'
                        onChange={e => {
                            setEditContent(e.target.value);
                        }}
                        onKeyUp={e => {
                            textareaHeightAdjust(e.target);
                        }}
                        value={editContent}
                    />
                    <div>
                        <Button variant='secondary' onClick={() => {
                            setEdit(false);
                            setEditContent(post.content);
                        }}>
                            cancel
                        </Button>
                        <Button className='confirm' type='submit'>save</Button>
                    </div>
                </Form>
                :
                <pre className="post-content-with-buttons">{post.content}</pre>
            }
            {post.image_url && <img src={post.image_url} alt="post" className="post-content-preview-image" />}
            {post.video_url &&
                <video
                    className="post-content-preview-image"
                    controls
                >
                    <source src={post.video_url} type='video/mp4' />
                    <source src={post.video_url} type='video/mov' />
                    <source src={post.video_url} type='video/webm' />
                </video>
            }
            {post.file_url &&
                <a
                    href={post.file_url}
                    target="_blank"
                    rel="noreferrer"
                    className="post-file-link"
                >
                    <p className="post-file-link">
                        <i className="fa-solid fa-paperclip"></i>
                        View Attached Document
                    </p>
                </a>
            }
            <div className="like-comment-buttons">
                <div>
                    <span>{post.likes} like{post.likes > 1 ? 's' : ''}</span>
                    <button
                        className={`like-button ${activeLike && 'like-button-active'}`}
                        onClick={handleLikeClick}
                    >
                        <i className="fas fa-thumbs-up"></i>
                        Like{activeLike ? 'd' : ''}
                    </button>
                </div>
                <div>
                    <span>{post.comments} comment{post.comments > 1 ? 's' : ''}</span>
                    <button
                        className="comment-button"
                        onClick={toggleCommentPanel}
                    >
                        <i className="fas fa-comment"></i>
                        Comment
                    </button>
                </div>
            </div>

            {viewComments &&
                <div className="comment-panel">
                    <div className="post-comment-container">
                        {comments.length > 0 && comments.map((comment, idx) => {
                            return (
                                <div key={`post-comment-${post.id}-${comment.id}`}>
                                    <UserThumbnail userProfile={comment.user} self={false} style="simplified" />
                                    <div className='post-comment-content-delete-field'>
                                        <pre>{comment.content}</pre>
                                        {comment.user.id === props.userId &&
                                            <button onClick={() => { deleteComment(comment.id, idx); }}>
                                                <span>&times;</span>
                                            </button>
                                        }
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className='load-more-comments'>
                        {moreComments ?
                            <button onClick={fetchComments}>
                                load more comments
                            </button>
                            :
                            <p>
                                last of comments
                            </p>
                        }
                    </div>
                    <form onSubmit={makeComment}>
                        <textarea
                            id="post-comment-text-area"
                            className="comment-text-field"
                            placeholder="Make a comment..."
                            value={userComment}
                            onChange={(e) => { setUserComment(e.target.value); }}
                            onKeyUp={e => { textareaHeightAdjust(e.target); }}
                        />
                        <button type="submit" className="theme-button">Comment</button>
                    </form>
                </div>
            }
        </div>
    );
};

Post.propTypes = {
    post: propTypes.object,
    activeLike: propTypes.bool,
    highlighted: propTypes.bool,
    userId: propTypes.number,
    idx: propTypes.number,
    dynamicPostRemoveCallBack: propTypes.func,
    createdAt: propTypes.instanceOf(Date),
};

export default Post;
