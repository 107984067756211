import { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import './SkillsEdit.css';
import './ProfileEdit.css';
import { getAuthenticationHeader, backendURL } from '../Utils/utils';

// Component for editing skills
const SkillsEdit = (props) => {
    const [skills, setSkills] = useState(props.skillsArr);
    const [suggestions, setSuggestions] = useState(null);
    const [input, setInput] = useState(null);

    const submitSkillChange = async () => {
        try {
            const config = getAuthenticationHeader();

            await axios.post(`${backendURL}/skills/`, skills, config);
            toast.success('Successfully updated your skills');
            props.closePanel(true);
        } catch (error) {
            console.error(error);
        }
    };

    let isEditing = false;
    const inputChange = async (e) => {
        e.preventDefault();
        if (isEditing) return;
        if (e.target.value === '') {
            setSuggestions(null);
            setInput(null);
            return;
        }

        isEditing = true;
        setTimeout(async () => {
            isEditing = false;

            if (e.target.value === '' || e.target.value.length === 0) {
                setSuggestions(null);
                setInput(null);
                return;
            }
            try {
                setInput(e.target.value.replace(/\b\w/g, l => l.toUpperCase()));
                const request = await axios.get(`${backendURL}/suggest_skills/`, { params: { input: e.target.value } });
                setSuggestions(request.data.suggest_skills);
            } catch (error) {
                console.error(error);
            }
        }, 500);
    };

    return (
        <Modal
            className="edit-form-group profile-edit"
            backdrop="static"
            centered
            show={props.show}
            onHide={props.closePanel}
        >
            <Modal.Header closeButton>
                <h3>Your skills</h3>
            </Modal.Header>
            <Modal.Body>
                {/* Search Box and suggestions component */}
                <input
                    type="text"
                    id="skill-search"
                    placeholder="Search for a Skill"
                    onChange={inputChange}
                ></input>

                {suggestions ?
                    <div id="skill-suggestion-container">
                        <h3>Suggestions</h3>
                        <div>
                            {suggestions ?
                                [...new Set([input, ...suggestions])].map(suggestion => {
                                    return (
                                        <p
                                            key={`${suggestion}-edit-skill`}
                                            onClick={() => {
                                                setSkills([...new Set([...skills, suggestion])]);
                                            }}
                                        >
                                            {suggestion}
                                        </p>
                                    );
                                })
                                :
                                null
                            }
                        </div>
                    </div>
                    :
                    null
                }
                {/* Display all current Skills */}
                <div className="skill-item-container edit-skill-item-container">
                    <p>Your Current Skills</p>
                    {skills ?
                        skills.map(skill => {
                            return (
                                <div key={skill} className="skill-item">
                                    <p>{skill}</p>
                                    <span
                                        className="skill-remove-btn"
                                        onClick={() => {
                                            const i = skills.indexOf(skill);
                                            const r = [...skills];
                                            r.splice(i, 1);
                                            setSkills(r);
                                        }}>
                                        &times;
                                    </span>
                                </div>
                            );
                        })
                        :
                        null
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={props.closePanel}>
                    Cancel
                </Button>
                <Button className='confirm' onClick={submitSkillChange}>
                    Update Profile
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

SkillsEdit.propTypes = {
    skillsArr: PropTypes.array,
    closePanel: PropTypes.func,
    show: PropTypes.bool,
};

export default SkillsEdit;