import axios from 'axios';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Form, Modal, Button } from 'react-bootstrap';

import { getAuthenticationHeader, backendURL } from '../Utils/utils';
import '../Utils/common.css';
import './profile-modal.css';

// Modal for editing awards and achievements.
const AwardsEdit = (props) => {
    const [formData, setFormData] = useState(props.formData ? props.formData : {
        title: '',
        date: '',
        external_link: '',
        description: '',
        thumbnail: null,
        file: null,
    });

    const handleChange = (e) => {
        if (e.target.name === 'thumbnail') {
            setFormData({ ...formData, thumbnail: e.target.files[0] });
        }
        else if (e.target.name === 'file') setFormData({ ...formData, file: e.target.files[0] });
        else setFormData({ ...formData, ...{ [e.target.name]: e.target.value } });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!formData.title || !formData.date) {
            return;
        }
        try {
            const config = getAuthenticationHeader('multipart/form-data');

            if (props.edit) await axios.patch(`${backendURL}/achievements/`, formData, config);
            else await axios.post(`${backendURL}/achievements/`, formData, config);
            props.closePanel(true);
            toast.success('Updated awards and achievements successfully');
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Modal
            className="edit-form-group profile-edit"
            backdrop="static"
            centered
            show={props.show}
            onHide={props.closePanel}
        >
            <Modal.Header closeButton>
                <h3>Your awards & achievements</h3>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label htmlFor="title">Title:</Form.Label>
                        <Form.Control
                            type="text"
                            name="title"
                            onChange={handleChange}
                            value={formData.title}
                            required
                        />
                    </Form.Group>
                    <div className='two-column-profile-edit'>
                        <Form.Group>
                            <Form.Label htmlFor="date">Date:</Form.Label>
                            <Form.Control
                                type="month"
                                name="date"
                                onChange={handleChange}
                                value={formData.date}
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="thumbnail">Thumbnail:</Form.Label>
                            <Form.Control
                                type="file"
                                name="thumbnail"
                                accept="image/*"
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </div>

                    <Form.Group>
                        <Form.Label htmlFor="file">File:</Form.Label>
                        <Form.Control
                            type="file"
                            name="file"
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label htmlFor="external_link">External Link:</Form.Label>
                        <Form.Control
                            type="text"
                            name="external_link"
                            onChange={handleChange}
                            value={formData.external_link}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label htmlFor="description">Description:</Form.Label>
                        <Form.Control
                            as='textarea'
                            name="description"
                            onChange={handleChange}
                            value={formData.description}
                            required
                        />
                    </Form.Group>

                    <div className='form-footer-btn-group'>
                        <Button variant='secondary' onClick={props.closePanel}>
                            Cancel
                        </Button>
                        <Button className='confirm' type='submit'>
                            Update Profile
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

AwardsEdit.propTypes = {
    edit: PropTypes.bool,
    closePanel: PropTypes.func,
    formData: PropTypes.shape({
        title: PropTypes.string,
        date: PropTypes.string,
        external_link: PropTypes.string,
        description: PropTypes.string,
        thumbnail: PropTypes.string,
        file: PropTypes.instanceOf(File),
    }),
    show: PropTypes.bool,
};


export default AwardsEdit;