import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { backendURL } from '../Utils/utils';
import { useNavigate } from 'react-router-dom';
import Loading from '../Utils/Loading';
import './VerifyEmail.css';

// Verify the email token and navigate the user based on the token validity.
const VerifyEmail = () => {
    const { uidb64, token } = useParams();
    const [isTokenValid, setTokenValid] = useState(null);
    const navigate = useNavigate();

    const checkToken = async () => {
        try {
            await axios.get(`${backendURL}/verify_email_token?uidb64=${uidb64}&token=${token}`);
            setTokenValid(true);
        }
        catch (err) {
            console.error(err);
            setTokenValid(prev => {
                return prev ? true : false;
            });
        }

        setTimeout(() => {
            navigate('/');
        }, 5000);
    };

    useEffect(() => {
        // Verify the reset token before rendering the page
        checkToken();
    }, []);

    if (isTokenValid === null) {
        return (
            <Loading message='Creating your profile'/>
        );
    }

    return (
        <div className='veriy-email-container'>
            {isTokenValid === true &&
                <>
                    <h1>Sucessfully verified your email</h1>
                    <h2>Welcome to PplLdr</h2>
                    <p>Heading back to login page</p>
                </>
            }
            {isTokenValid === false &&
                <>
                    <h1>Something went wrong...</h1>
                    <h2>Please try again.</h2>
                </>
            }
        </div>
    );
};

export default VerifyEmail;