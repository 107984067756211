import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getAuthenticationHeader, backendURL } from '../Utils/utils';
import PropTypes from 'prop-types';
import './Notification.css';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Loading from '../Utils/Loading';
import '../Utils/common.css';
dayjs.extend(relativeTime);


const Notification = (props) => {
    const navigate = useNavigate();
    const [key, setKey] = useState('all');
    const [notifications, setNotifications] = useState([]);
    const [counter, setcounter] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [unReadNotifications, setUnReadNotifications] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentNotifications, setCurrentNotifications] = useState([]);
    const [totalPages, setTotalPages] = useState();
    const notificationsPerPage = 10;

    useEffect(() => {
        fetchNotification();
    }, [counter]);

    const nextPage = () => {
        if (currentPage < totalPages) {
            const currentPageNo = currentPage + 1;
            setCurrentPage(currentPageNo);
            fetchCurrentNotifs(currentPageNo, key === 'all' ? notifications : unReadNotifications);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            const currentPageNo = currentPage - 1;
            setCurrentPage(currentPageNo);
            fetchCurrentNotifs(currentPageNo, key === 'all' ? notifications : unReadNotifications);
        }
    };

    // fetch all notifications
    const fetchNotification = async () => {
        try {
            const config = getAuthenticationHeader();

            const response = await axios.get(`${backendURL}/notifications/`, config);

            if (response.status === 200) {
                const allNotif = response.data;
                setNotifications(allNotif);
                const unReadNotif = allNotif.filter(notif => notif.is_read === false);
                setUnReadNotifications(unReadNotif);

                fetchCurrentNotifs(currentPage, key === 'all' ? allNotif : unReadNotif);
                setLoading(false);
            } else if (response.status === 204) {
                setNotifications([]);
                setLoading(false);
            }

        } catch (err) {
            console.error(err);
        }
    };

    // mark notificat read/unread
    const changeNotifReadStatus = async (notif_list, status) => {

        try {
            const config = getAuthenticationHeader();

            const requestData = {
                notif_ids: notif_list,
                is_read: status,
            };

            const response = await axios.put(`${backendURL}/notifications/`, requestData, config);
            if (response.status === 200) {
                window.dispatchEvent(new Event('storage'));
                setcounter(counter + 1);
            }

        } catch (err) {
            console.error(err);
        }
    };

    const clearAllNotifs = async () => {
        try {
            const config = getAuthenticationHeader();

            const response = await axios.delete(`${backendURL}/notifications/`, config);

            if (response.status === 204) {
                setNotifications([]);
                setUnReadNotifications([]);
                setCurrentPage(1);
                setCurrentNotifications([]);
                setTotalPages();
                setcounter(0);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const handleMarkRead = () => {
        const notif_list = notifications.map(notif => notif.id);
        if (notif_list.length > 0) changeNotifReadStatus(notif_list, true);
    };

    // click any message to mark it read it is unread
    const handleMarkSingleRead = (notif) => {
        if (notif.is_read === false) {
            const notif_list = [parseInt(notif.id)];
            if (notif_list.length > 0) changeNotifReadStatus(notif_list, true);
        }
    };

    const viewContent = (notif) => {
        if (
            notif.notification_type === 4 ||
            notif.notification_type === 5
        ) {
            navigate(`/user/myposts/${notif.target_object.post_id}`);
        }
        else if (notif.notification_type === 6) {
            window.open(notif.target_object.url, '_blank');
        }
        else if (notif.notification_type === 7) {
            const user = `${props.userProfile.first_name}-${props.userProfile.last_name}-${props.userProfile.id}`;
            navigate(`/user/ratingscore/${user}/${notif.target_object.post_id}`);
        }
        else if (notif.notification_type === 2) {
            const user = `${notif.target_object.first_name}-${notif.target_object.last_name}-${notif.target_object.leader_id}`;
            navigate(`/user/ratingscore/${user}/${notif.target_object.post_id}`);
        }

        return;
    };

    const showAllNotif = () => {
        setKey('all');
        setCurrentPage(1);
        fetchCurrentNotifs(1, notifications);
    };

    const showURNotif = () => {
        setKey('unread');
        setCurrentPage(1);
        fetchCurrentNotifs(1, unReadNotifications);
    };

    const fetchCurrentNotifs = (currentPageNo, notifs) => {
        const indexOfLastNotif = currentPageNo * notificationsPerPage;
        const indexOfFirstNotif = indexOfLastNotif - notificationsPerPage;
        setTotalPages(Math.ceil(notifs.length / notificationsPerPage));
        setCurrentNotifications(notifs.slice(indexOfFirstNotif, indexOfLastNotif));
    };

    const TimeAgo = ({ timestamp }) => {
        const [timeAgo, setTimeAgo] = useState('');

        useEffect(() => {

            // update time difference
            const updateTimeAgo = () => {
                const created = dayjs(timestamp);
                const distance = created.fromNow();
                setTimeAgo(distance);
            };

            // Update time difference immediately
            updateTimeAgo();

            // Set up interval to update time difference every minute
            const interval = setInterval(updateTimeAgo, 60000);

            return () => clearInterval(interval);
        }, [timestamp]);

        return <span>{timeAgo}</span>;
    };

    TimeAgo.propTypes = {
        timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
    };

    if (isLoading) {
        return <Loading message='Loading notifications'/>;
    }

    return (
        <div className='notif-homebody'>
            <div className='notifs-container'>
                <div className='notif-head'>
                    <h3>Notification</h3>
                </div>
                <div className='notif-tabs'>
                    <button className={key === 'all' ? 'btn-active' : null} onClick={showAllNotif} >All</button>
                    <button className={key === 'unread' ? 'btn-active' : null} onClick={showURNotif}>Unread</button>
                    <div className='all-read-btn theme-button' onClick={handleMarkRead}>
                        <span>Read all</span>
                    </div>
                    <div className='all-read-btn theme-button' onClick={clearAllNotifs}>
                        <span>Clear all</span>
                    </div>
                </div>

                {currentNotifications.map((notif, index) => (
                    <div
                        className='notif-card'
                        key={index}
                        onClick={() => {
                            viewContent(notif);
                            handleMarkSingleRead(notif);
                        }}
                    >
                        {notif.is_read ? <div className='gray-dot' /> : <div className='blue-dot' />}
                        <pre className='notif-content'>
                            {notif.is_read ? <>{notif.notification_content}</> : <b>{notif.notification_content}</b>}
                        </pre>
                        <div className='notif-datetime'>
                            <TimeAgo timestamp={notif.date_created} />
                        </div>
                    </div>
                ))}

                { (totalPages > 0) && (<div className='notif-pagination'>
                    <button onClick={prevPage} disabled={currentPage === 1}>Previous</button>
                    <span>{`Page ${currentPage} of ${totalPages}`}</span>
                    <button onClick={nextPage} disabled={currentPage === totalPages}>Next</button>
                </div>)}

                {((key === 'all' && notifications.length === 0)) &&
                    (<div className='no-notif-bg'>
                        <i className="fa-regular fa-bell-slash fa-xl"></i>
                        <p>No notifications</p>
                    </div>)
                }
                {((key === 'unread' && notifications.filter(notif => notif.is_read === false).length === 0)) &&
                    (<div className='no-notif-bg'>
                        <i className="fa-regular fa-bell-slash fa-xl"></i>
                        <p>No unread notifications</p>
                    </div>)
                }
            </div>
        </div>);
};

Notification.propTypes = {
    userProfile: PropTypes.object,
};

export default Notification;
