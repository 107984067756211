import './AboutUs.css';

// Renders the About Us section of the PplLdr platform, showcasing the company's vision, values, and mission.
const AboutUs = () => {
    return (
        <div className="about-us-container">
            <div>
                <h1>About PplLdr</h1>
                <p className = "about-intro">
                    PplLdr publicly displays a leadership profile that assesses people
                    leader abilities and personalities. The leader assesses herself and
                    our algorithm then compares those scores to what her current and past
                    direct reports / team members have rated her in those same categories.
                    Periodic updates occur every 6-months ensuring that top talent
                    applicants are getting an accurate and current leadership profile of
                    their potential new boss. Additionally, Premium subscribers get access
                    to the library of training, instruction, consultants, institutional
                    links, and references on leadership.
                </p>
            </div>

            <div className="about-us-layout">
                <h2> Vision </h2>
                <p>
                    PplLdr becomes the go-to platform for showcasing quality people leaders through their
                    public leadership profiles. Top leaders showcase their quality to potential top talent trying
                    to find a leader based upon assessments by current and former direct reports / team
                    members to assess their leadership skills. These leadership profiles mitigate risks of
                    increased voluntary terminations by associates due to poor performing managers as well
                    as mitigates organizational reputational risks by identifying toxic managers.
                    Top talent makes an informed decision based upon each leadership profile - does my
                    potential new leader fit my expectations and needs as a quality people leader?
                </p>
            </div>

            <div className="about-us-layout">
                <h2> Values </h2>
                <div>
                    <p><span>Integrity</span> - Nothing but the highest level of integrity is acceptable.</p>
                    <p><span>Teamwork</span> - Effective team work is the key to our company&apos;s success.</p>
                    <p><span>Individuality</span> - Individuals are valued, trusted, and respected assets for the company.</p>
                    <p><span>Performance</span> - We expect a high level of achievement and contribution from all
                        employees. Employees will be compensated based on individual and team performance.</p>
                    <p><span>Community</span> - We will contribute a percentage of our time and resources to
                        improving the community in which we live and work.</p>
                </div>
            </div>

            <div className="about-us-layout">
                <h2> Mission </h2>
                <p>
                    PplLdr helps quality people leaders attract top talent. It is a professional networking
                    platform for people leaders to showcase their quality leadership skills assessed by their
                    current and former direct reports / team members in an effort to attract top talent and
                    mitigate risks of voluntary resignations by associates due to toxic managers.
                    Quality leaders seek feedback and assessment of their attributes and skill sets from direct
                    reports / team members. Quality leaders want job applicants to be fully informed in
                    determining fit as potential direct reports / team members. These leaders want to show
                    that their leadership, as people leaders, is above the average manager.
                </p>
            </div>
        </div>
    );
};

export default AboutUs;
