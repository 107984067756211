import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

import './Contact.css';

// Modal displaying contact information.
const Contact = (props) => {
    return (
        <Modal
            className="edit-form-group"
            backdrop="static"
            centered
            show={props.show}
            onHide={props.closePanel}
        >
            <Modal.Header closeButton>
                <h3>Contact information</h3>
            </Modal.Header>
            <Modal.Body>
                <div className = "contact-field">
                    <i className="fa-sharp fa-solid fa-envelope"></i>
                    <p className = "field-title">Email:</p>
                    <p className = "field-detail">{props.email}</p>
                </div>
                <div className = "contact-field">
                    <i className="fa-sharp fa-solid fa-phone"></i>
                    <p className = "field-title">Phone:</p>
                    <p className = "field-detail"> {props.phone}</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={props.closePanel}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

Contact.propTypes = {
    closePanel: PropTypes.func,
    email: PropTypes.string,
    phone: PropTypes.string,
    show: PropTypes.bool,
};

export default Contact;