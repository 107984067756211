import { useState } from 'react';
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import PropTypes from 'prop-types';

import GoogleLoginModule from './GoogleLoginModule';
import { LinkedinLoginModule } from './LinkedinLoginModule';
import { backendURL } from '../Utils/utils';
// import Loading from '../Utils/Loading';
import './Register.css';
import '../Utils/common.css';
import 'react-toastify/dist/ReactToastify.css';

// User registration
const Register = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        password_repeat: '',
        first_name: '',
        last_name: '',
        bio: '',
        phone_number: '',
        address: '',
    });

    const [showDisclaimer, setShowDisclaimer] = useState(false);
    const [userAgree, setUserAgree] = useState(false);
    const [ageConfirm, setAgeConfirm] = useState(false);
    const [privacyPolicy, setPrivacyPolicy] = useState(false);
    const [resendEmail, setResendEmail] = useState(false);
    const location = useLocation();
    const next_url = location.state?.next_url;
    const [verifyModal, setverifyModal] = useState(false);

    const navigate = useNavigate();

    const handleChange = (e) => {
        if (formData.bio.length >= 599) {
            toast.info('Your bio is too long, please limit to 600 characters');
        }
        if (formData.bio.length > 600) {
            return;
        }

        else if (e.target.name === 'legal-disclaimer-checkbox') setUserAgree(e.target.checked);
        else if (e.target.name === 'age-checkbox') setAgeConfirm(e.target.checked);
        else if (e.target.name === 'privacy-policy-checkbox') setPrivacyPolicy(e.target.checked);
        else setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handlePrivacyPolicyClick = (event) => {
        event.preventDefault();
        window.open('/privacypolicy', '_blank');
    };

    const handleRegisterThirdParty = async (ptoken, platform) => {
        try {
            const config = {
                headers: {},
            };
            let platform_route;

            if (platform === 'google') {
                config.headers.google_token = ptoken;
                platform_route = 'register_user_google';
            } else if (platform === 'linkedin') {
                config.headers.linkedin_token = ptoken;
                platform_route = 'register_user_linkedin';
            }

            await axios.post(`${backendURL}/${platform_route}/`, config);

            // Show toaster notification for successful login
            toast.success('Registration successful! Heading back to login screen');
            setTimeout(() => {
                navigate('/',
                    {
                        state: {
                            next_url: next_url,
                        },
                    },
                );
            }, 3000);

        } catch (err) {
            console.error(err);
            if (err.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (err.response.status === 400) {
                    toast.error('Restration failed, please try again');
                } else if (err.response.status === 401) {
                    toast.info('Info outdated, refeshing screen');
                } else if (err.response.status === 406) {
                    toast.info('User already exist! Heading back to login screen');
                    setTimeout(() => {
                        navigate('/');
                    }, 3000);
                }
            }
        }
    };

    const handleRegisterGoogle = (token) => {
        handleRegisterThirdParty(token, 'google');
    };

    const handleRegisterLinkedin = (token) => {
        handleRegisterThirdParty(token, 'linkedin');
    };

    const userAgreeIndicator = () => {
        if (!userAgree) {
            toast.info('Please read and agree to our legal disclaimer.');
            return false;
        }
        if (!ageConfirm) {
            toast.info('Please confirm you are at least 18 years old.');
            return false;
        }
        if (!privacyPolicy) {
            toast.info('Please read and agree to our privacy policy.');
            return false;
        }
        return true;
    };

    const verifyEmail = async (e) => {
        e.preventDefault();

        if (formData.password !== formData.password_repeat) {
            toast.error('Passwords do not match');
            return;
        }

        if (formData.bio.length > 600) {
            toast.info('Your bio is too long, please limit to 600 characters');
            return;
        }

        if (formData.password.length < 8) {
            toast.error('Password must be at least 8 characters long');
            return;
        }

        if (!userAgreeIndicator()) {
            return;
        }

        try {
            const response = await axios.post(`${backendURL}/verify_email/`, formData);
            setverifyModal(true);
            if (response.status === 204) {
                toast.info('User already exist! Heading back to login screen');
                setTimeout(() => {
                    navigate('/',
                        {
                            state: {
                                next_url: next_url,
                            },
                        },
                    );
                }, 3000);
                setverifyModal(false);
                delete formData.password_repeat;
            }
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className="register-form-container">
            <Modal
                show={verifyModal}
                centered
                className="verify-container"
                onHide={() => {
                    setverifyModal(false);
                    setResendEmail(false);
                }}
            >
                <Modal.Header closeButton>
                    <h3>For your safety, you need to verify your email</h3>
                </Modal.Header>
                <Modal.Body>
                    <p>Please check your email inbox (including spam) for <span>PplLdr Verify Email</span> and follow the link</p>
                    <p>It could take the email up to a few minutes to reach your inbox.</p>
                </Modal.Body>
                <Modal.Footer>
                    {resendEmail &&
                        <p style={{ color: 'var(--success-green)' }}>
                            Email sent!
                        </p>
                    }
                    <div className="resend-email">
                        <p>Didn&apos;t get confirmation email?</p>
                        <Button variant='secondary' onClick={async (e) => {
                            await verifyEmail(e);
                            setResendEmail(true);
                            setTimeout(() => {setResendEmail(false);}, 2000);
                        }}>Resend email</Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <h3>Register New Account</h3>
            <p>Create an account with PplLdr to start viewing and rating other people leaders</p>

            {showDisclaimer && <LegalDisclaimerModal closePanel={setShowDisclaimer} />}
            <form className="register-form" onSubmit={verifyEmail}>
                <p>* indicates required field</p>
                <div className="edit-form-group">
                    <label htmlFor="email">*Email:</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        autoComplete='email'
                    />
                </div>
                <div className="edit-form-group">
                    <label htmlFor="password">*Password:</label>
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                        autoComplete='new-password'
                    />
                </div>
                <div className="edit-form-group">
                    <label htmlFor="password-repeat">*Repeat Password:</label>
                    <input
                        type="password"
                        name="password_repeat"
                        onChange={handleChange}
                        required
                        autoComplete='new-password'
                    />
                </div>
                <div className='register-name-row'>
                    <div className="edit-form-group">
                        <label htmlFor="first_name">*First Name:</label>
                        <input
                            type="text"
                            name="first_name"
                            value={formData.first_name}
                            onChange={handleChange}
                            required
                            autoComplete='given-name'
                        />
                    </div>
                    <div className="edit-form-group">
                        <label htmlFor="last_name">*Last Name:</label>
                        <input
                            type="text"
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleChange}
                            required
                            autoComplete='family-name'
                        />
                    </div>
                </div>
                <div className="edit-form-group">
                    <label htmlFor="bio">Bio:</label>
                    <br />
                    <input type="textbox"
                        name="bio"
                        value={formData.bio}
                        onChange={handleChange}
                        maxLength={600}
                    />
                </div>
                <div className="edit-form-group">
                    <label htmlFor="phone_number">Phone Number:</label>
                    <input
                        type="text"
                        name="phone_number"
                        value={formData.phone_number}
                        onChange={handleChange}
                        autoComplete='tel'
                    />
                </div>
                <div className="edit-form-group">
                    <label htmlFor="address">Address:</label>
                    <input
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        autoComplete='street-addres'
                    />
                </div>
                <div className="legal-disclaimer-check-container">
                    <label htmlFor="legal-disclaimer-checkbox">
                        <input
                            type="checkbox"
                            id="legal-disclaimer-checkbox"
                            name="legal-disclaimer-checkbox"
                            onChange={handleChange}
                        />
                        I read and understood the <Link onClick={() => { setShowDisclaimer(true); }}>legal discalimer</Link> for PplLdr
                    </label>
                </div>

                <div className="legal-disclaimer-check-container">
                    <label htmlFor="privacy-policy-checkbox">
                        <input
                            type="checkbox"
                            id="privacy-policy-checkbox"
                            name="privacy-policy-checkbox"
                            onChange={handleChange}
                        />
                        I read and understood the <Link onClick={handlePrivacyPolicyClick}>privacy policy</Link> for PplLdr
                    </label>
                </div>

                <div className="legal-disclaimer-check-container">
                    <label htmlFor="age-checkbox">
                        <input
                            type="checkbox"
                            id="age-checkbox"
                            name="age-checkbox"
                            onChange={handleChange}
                        />
                        I certify that I am at least <span className="age">18 </span> years old
                    </label>
                </div>

                <button type="submit">Continue</button>
                <p>Or</p>

                <div className="register-form-third-party-container">

                    {(!userAgree || !ageConfirm || !privacyPolicy) &&
                        <div className="block-register-agree" onClick={userAgreeIndicator}></div>
                    }

                    <div id="google-register-container">
                        <GoogleLoginModule
                            handlerFunction={handleRegisterGoogle}
                            useOneTap={false}
                            oneTapParentId="one-tap-container" />
                    </div>


                    <LinkedinLoginModule handleRegister={handleRegisterLinkedin} />
                </div>
                <p>Already have an account? <Link to="/login" state={{ next_url: next_url }}>Login</Link></p>
            </form>
        </div>
    );
};
const LegalDisclaimerModal = (props) => {
    return (
        <div className="legal-disclaimer-modal-container">
            <div className="legal-disclaimer-form-container">
                <h2>
                    Legal Disclaimer for Use of the PplLdr (People Leader) Platform.
                    <span className="edit-close-btn" onClick={() => { props.closePanel(false); }}>
                        &times;
                    </span>
                </h2>
                <p>
                    By using this platform or any of its offerings, <i>you are consenting to making your scores,
                        assessments, comments, content, and ratings</i> by you or your direct reports / team members,
                    or any other contributors, available for public showcase, review, or use for forming an
                    opinion as a quality people leader.
                </p>
                <p>
                    <i>You further acknowledge and consent that all scores,
                        assessments, comments, content, and ratings</i> by you or your direct reports / team members,
                    or any other contributors, are solely their own opinion and do not reflect the opinions or
                    views of PplLdr (People Leader) LLC or its officers, agents, employees, or contractors.
                </p>
            </div>
        </div>
    );
};

LegalDisclaimerModal.propTypes = {
    closePanel: PropTypes.func,
};

export default Register;
