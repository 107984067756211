import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import './LeaderScore.css';
import Loading from '../Utils/Loading';
import RatingPosts from './RatingPosts';
import LinkedinShare from './LinkedinShare';
import defaultPfp from '../../assets/default_pfp.png';
import gold_badge_img from '../../assets/gold_badge.png';
import bronze_badge_img from '../../assets/bronze_badge.png';
import silver_badge_img from '../../assets/silver_badge.png';
import { getAuthenticationHeader, backendURL, attributeQuestionList } from '../Utils/utils';
import '../Utils/common.css';

import { Modal, Button } from 'react-bootstrap';

// Calculate and display the leader's score and rating based on the user's input and interactions.
const LeaderScore = ({ userProfile }) => {
    const [avgQuestionScore, setAvgQuestionScore] = useState([]);
    const [ratingDistr, setRatingDistr] = useState([]);
    const [leaderProfile, setLeaderProfile] = useState([]);
    const [selfRateQScores, setSelfRateQScores] = useState([]);
    const [score, setScore] = useState();
    const [rating, setRating] = useState('');
    const [selfRate, setSelfRate] = useState(false);
    const [selfRateScore, setSelfRateScore] = useState();
    const location = useLocation();
    const [isLoading, setLoading] = useState(true);
    const navigate = useNavigate();

    const { leaderInfo, postId } = useParams();
    let leaderParams = null;
    if (leaderInfo) {
        const splitdata = leaderInfo.split('-');
        leaderParams = {
            params: {
                first_name: splitdata[0],
                last_name: splitdata[1],
                id: splitdata[2],
            },
        };
    }
    const hilightPostId = parseInt(postId);

    const [badgeName, setBadgeName] = useState();
    const [badgeType, setBadgeType] = useState();
    const wasCalled = useRef();

    const [mobileScreen, setMobileScreen] = useState(false);
    const [showDefModal, setShowDefModal] = useState(null);
    const [defModalTitle, setDefModalTitle] = useState(null);
    const [defModalContent, setDefModalContent] = useState(null);
    // parametes from questionnaire page
    const selfRatingFlg = location.state?.selfRate;
    const leaderProf = location.state?.leader;

    useEffect(() => {
        if (wasCalled.current === leaderParams.params.id) return;
        setLoading(true);
        wasCalled.current = leaderParams.params.id;

        checkScreenSize();

        if (!selfRatingFlg) {
            getRatingDistr();
        }
        else {
            setSelfRate(selfRatingFlg);
        }

        getUserData();

        getAvgQuestionScore();

        getRatingScore();

        window.addEventListener('resize', function() {
            checkScreenSize();
        });

        getQnnaireResult();

        return (() => {
            window.removeEventListener('resize', checkScreenSize);
        });
    }, [selfRate, leaderInfo]);

    const checkScreenSize = () => {
        const screenWidth = document.getElementById('navbar-container').offsetWidth;

        if (screenWidth < 855) {
            setMobileScreen(true);
        }
        else {
            setMobileScreen(false);
        }
    };

    // get average score for each questions based on the leader id
    const getAvgQuestionScore = async () => {
        try {
            const config = {
                ...getAuthenticationHeader(),
                ...leaderParams,
            };

            const response = await axios.get(`${backendURL}/user/avg_question_score`, config);
            setAvgQuestionScore(response.data);

        } catch (error) {
            console.error(error);
        }
    };

    // get rating disctribution for the leader id
    const getRatingDistr = async () => {
        try {
            const config = {
                ...getAuthenticationHeader(),
                ...leaderParams,
            };

            const response = await axios.get(`${backendURL}/user/rating_distribution`, config);
            setRatingDistr(response.data);

        } catch (error) {
            console.error(error);
        }
    };

    // get both current user and leader data
    const getUserData = async () => {
        try {
            const config = getAuthenticationHeader();

            if (!leaderProf) {
                const leaderResponse = await axios.get(`${backendURL}/user/view_profile`, {
                    ...config,
                    ...leaderParams,
                });
                setLeaderProfile(leaderResponse.data);
            }
            else {
                setLeaderProfile(leaderProf);
            }
            setLoading(false);
        } catch (err) {
            navigate('/404');
            console.error(err);
        }
    };

    // get rating disctribution for the leader id
    const getRatingScore = async () => {
        try {
            const config = {
                ...getAuthenticationHeader(),
                ...leaderParams,
            };

            const response = await axios.get(`${backendURL}/rating_score/`, config);
            setScore(response.data);
            getRating(response.data);

        } catch (error) {
            console.error(error);
        }

    };

    const getSelfRateScore = async () => {
        try {
            const config = {
                ...getAuthenticationHeader(),
                ...leaderParams,
            };

            const response = await axios.get(`${backendURL}/get_rating_list`, config);

            if (response.status === 200) {
                const selfRating = response.data.filter(ratingList => ratingList.selfRate = true);
                if (selfRating.length > 0) {
                    setSelfRateScore(selfRating[0].average_score);
                    getRating(selfRating[0].average_score);
                }
            }

        } catch (error) {
            console.error(error);
        }

    };

    const getQnnaireResult = async () => {

        try {
            const config = {
                ...getAuthenticationHeader(),
                ...leaderParams,
            };

            const response = await axios.get(`${backendURL}/questionnaire_result/`, config);
            const qresult = response.data;
            const selfRateRecords = qresult.filter((result) => result.leader_id === result.rater_id);

            setSelfRateQScores(selfRateRecords);
        } catch (error) {
            console.error(error);
        }
    };

    const between = (x, min, max) => {
        return x >= min && x <= max;
    };

    const getRating = (points) => {
        setRating(null);
        setBadgeName(null);
        setBadgeType(null);

        if (!points) {
            return;
        }

        if (between(points, 0, 1.9)) {
            setRating('Dud');
        }

        if (between(points, 2, 2.9)) {
            setRating('Manages Tasks');
        }
        if (between(points, 3, 4.9)) {
            setRating('Supervisor');
        }
        if (between(points, 5, 6.9)) {
            setRating('Average Leader');
        }
        if (between(points, 7, 8.9)) {
            setRating('Good Leader');
            if (points < 8) {
                setBadgeName(bronze_badge_img);
                setBadgeType('Bronze');
            }
            else {
                setBadgeName(silver_badge_img);
                setBadgeType('Silver');
            }
        }
        if (between(points, 9, 9.9)) {
            setRating('Great Leader');
            setBadgeName(gold_badge_img);
            setBadgeType('Gold');
        }
        if (between(points, 10, 10)) {
            setRating('Superior Leader');
            setBadgeName(gold_badge_img);
            setBadgeType('Gold');
        }
    };

    const displaySelfRate = async () => {
        setSelfRate(true);
        await getSelfRateScore();
        getRating(selfRateScore);
    };

    const displayOverallRate = () => {
        setSelfRate(false);
        getRating(score);
    };

    const openDefModal = (title, content) => {
        setShowDefModal(true);
        setDefModalTitle(title);
        setDefModalContent(content);
    };

    const closeDefModal = () => {
        setShowDefModal(false);
    };

    if (isLoading) {
        return <Loading message='Loading Leader Score' />;
    }

    return (
        <div className='rating-page-container'>
            {userProfile &&
                <button
                    onClick={() => {
                        navigate(`/profiles/${leaderInfo}`);
                    }}
                    className='to-profile'
                >
                    To {leaderParams.params.first_name} {leaderParams.params.last_name}&apos;s profile
                </button>
            }
            <div
                className="panel-row"
                id="leader-score-container-screen"
                style={
                    {
                        alignItems: ((!selfRate && avgQuestionScore.length === 0) ||
                            (selfRate && selfRateQScores.length === 0)) ? 'center' : 'flex-start',
                    }
                }
            >
                <div className='leader-sub-panel-row'>
                    <div className='leader-head'>
                        {userProfile && (userProfile.id === leaderProfile.id) &&
                            <LinkedinShare />
                        }
                        {(selfRateScore || score) && (selfRate === false ?
                            (<h2><b>{score}</b><sub>/10</sub></h2>)
                            :
                            (<h2><b>{selfRateScore}</b><sub>/10</sub></h2>)
                        )}
                        <a
                            className="leaderscore-profile-img"
                            href={userProfile ?
                                `/profiles/${leaderProfile.first_name}-${leaderProfile.last_name}-${leaderProfile.id}`
                                :
                                '#'
                            }
                        >
                            <img
                                src={leaderProfile.profile_image}
                                alt="Profile"
                                className="user-prof-img-small"
                                onError={(e) => {
                                    e.target.src = defaultPfp;
                                }}
                            />
                        </a>
                        <div className="lead-info-rating">
                            <h2>{leaderProfile.first_name} {leaderProfile.last_name}</h2>
                            {!selfRate && badgeType && badgeName &&
                                <h6>{`Badge: ${badgeType}`} &nbsp;
                                    <img id="lead-info-badge" src={badgeName} alt="badge"></img>
                                </h6>}
                            {rating && (selfRate === false ?
                                <h6>{`Overall Rating: ${rating}`}</h6>
                                :
                                <h6>{`Self-Rating: ${rating}`}</h6>
                            )}
                            <div className="rating-result-btn-grp">
                                {selfRate ?
                                    <button className="theme-button" onClick={displayOverallRate}>
                                        <span><i className="fa-solid fa-chart-bar fa-lg" /> Overall Result</span>
                                    </button>
                                    :
                                    <button className="theme-button" onClick={displaySelfRate}>
                                        <span><i className="fa-solid fa-chart-bar fa-lg" /> Self-rating Result</span>
                                    </button>
                                }
                            </div>
                        </div>

                        {userProfile && (userProfile.id === leaderProfile.id) && (
                            <div className="invite-teammember-btn" onClick={() => navigate('/user/myteams')}>
                                <span><i className="fa-solid fa-user-plus fa-lg" />&nbsp;  Invite Direct Reports/Team Member</span>
                            </div>
                        )}
                    </div>

                    {/* display rating distributions only when it is not for self-rating results */}
                    {selfRate === false && leaderProfile.is_leader === true && (
                        <div className="progress-score-container">
                            <h3>Rating Distribution</h3>
                            {ratingDistr.toReversed().map((ratingData, index) => (
                                <div className="rating-row" key={index} style={{ width: '100%' }}>
                                    <div className="question-header-score">
                                        <p>{ratingData.level}</p>
                                        <p>{ratingData.numberofraters}/{ratingData.totalraters}</p>
                                    </div>
                                    <div className="progress ratingbar">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            aria-valuemin={0}
                                            aria-valuemax={10}
                                            aria-valuenow={ratingData.percentage}
                                            style={{ width: `${ratingData.percentage}%` }}
                                        >
                                        </div>
                                    </div>
                                </div>
                            ),
                            )}
                        </div>)}

                    {(selfRate === false) && !mobileScreen && (
                        <div className="post-to-rating-panel">
                            <RatingPosts
                                leader={leaderProfile}
                                user={userProfile}
                                hilightPostId={hilightPostId}
                            />
                        </div>
                    )}
                </div>

                {(!selfRate && avgQuestionScore.length === 0) || (selfRate && selfRateQScores.length === 0) ?
                    <div className='no-progress-score-container'>
                        {(selfRate === false) ? (<h3>Attributes Average Score</h3>) : (<h3>Self Rating Attribute Score</h3>)}
                        <p>
                            {leaderParams.params.first_name} {leaderParams.params.last_name} has not {selfRate ? 'self-' : 'been '}rated yet
                        </p>
                    </div>
                    :
                    <div className='progress-score-container'>
                        {(selfRate === false) ? (<h3>Attributes Average Score</h3>) : (<h3>Self Rating Attribute Score</h3>)}
                        {attributeQuestionList.map((question, index) => {
                            const filteredAvgScore = (selfRate === false)
                                ? avgQuestionScore.filter(avgscore => avgscore.question_id === question.id)
                                : selfRateQScores.filter(avgscore => avgscore.question_id === question.id);

                            const averageScore = filteredAvgScore.length > 0
                                ? (selfRate ? filteredAvgScore[0].score : filteredAvgScore[0].avgscore)
                                : 0;

                            return (
                                <div className="rating-row" key={`questionaire-${index}`}>
                                    <div className="question-header-score">
                                        <div className='question-attr-tip'>
                                            {question.questioncontent}
                                            <div
                                                className="rating-attr-tooltip"
                                                onClick={() => {
                                                    openDefModal(question.questioncontent, question.tooltip);
                                                }}
                                            >
                                                &nbsp;
                                                <i className="fa-solid fa-circle-question" />
                                                <span>{question.tooltip}</span>
                                            </div>
                                        </div>
                                        <p>{averageScore}</p>
                                    </div>
                                    <div className="progress ratingbar">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            aria-valuemin={0}
                                            aria-valuemax={10}
                                            aria-valuenow={averageScore}
                                            style={{ width: `${averageScore / 10 * 100}%` }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                }

                {/* Attribute definition for mobile */}
                {mobileScreen &&
                    <Modal
                        show={showDefModal}
                        onHide={closeDefModal}
                        centered
                    >
                        <Modal.Header closeButton>
                            <h3>{defModalTitle}</h3>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{defModalContent}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant='secondary'
                                onClick={closeDefModal}
                            >
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                }

                {/* Comment section for mobile */}
                {(selfRate === false) && mobileScreen && (
                    <div className="post-to-rating-panel">
                        <RatingPosts
                            leader={leaderProfile}
                            user={userProfile}
                            hilightPostId={hilightPostId}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

LeaderScore.propTypes = {
    userProfile: PropTypes.object,
};

export default LeaderScore;
