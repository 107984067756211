import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Register from './components/LogInOut/Register.jsx';
import Login from './components/LogInOut/Login.jsx';
import Nav from './components/NavBar/Nav.jsx';
import BottomNav from './components/NavBar/BottomNav.jsx';
import ViewUsers from './components/DEVTOOLS/ViewUsers.jsx';
import Profile from './components/Profile/Profile.jsx';
import LandingPage from './components/LogInOut/LandingPage.jsx';
import ForgotPassword from './components/Profile/ForgotPassword.jsx';
import CreatePost from './components/Posts/CreatePost.jsx';
import UserPosts from './components/Posts/UserPosts.jsx';
import Subscription from './components/Subscription/Subscription.jsx';
import ResetPassword from './components/Profile/ResetPassword.jsx';
import { LinkedInCallback } from './components/LogInOut/LinkedinLoginModule.jsx';
import SuccessPayment from './components/Subscription/SucessPayment.jsx';
import ConfirmCancel from './components/Subscription/ConfirmCancel.jsx';
import AboutUs from './components/AboutUs/AboutUs.jsx';
import ContactUs from './components/AboutUs/ContactUs.jsx';
import ContactFormSuccceed from './components/AboutUs/ContactFormSucceed.jsx';
import PrivacyPolicy from './components/AboutUs/PrivacyPolicy.jsx';
import ManagePlans from './components/Subscription/ManagePlans.jsx';
import CancelSubscription from './components/Subscription/CancelSubscription.jsx';
import Questionnaire from './components/Questionnaire/Questionnaire.jsx';
import LeaderScore from './components/Search/LeaderScore.jsx';
import TermsandCondition from './components/AboutUs/TermsandCondition.jsx';
import VerifyEmail from './components/LogInOut/VerifyEmail.jsx';
import Faq from './components/Faq/Faq.jsx';
import Notification from './components/Notifications/Notification.jsx';
import Setting from './components/Setting/Setting.jsx';
import TeamDashboard from './components/TeamMember/MyTeamDashboard.jsx';
import AiTools from './components/BookRecommendation/AiTools.jsx';
import NotFound from './components/Utils/NotFound.jsx';
import './App.css';
import { isProduction } from './components/Utils/utils.js';
import UnsubscribeNewsletter from './components/LogInOut/UnsubscribeNewsletter.jsx';
import Podcast from './components/Podcast/Podcast.jsx';

function App() {
    // userProfile here is just for displaying basic info, not to be edited or operated upon
    const [userProfile, setUserProfile] = useState(null);

    return (
        <Router>
            <Nav setUserAppData={setUserProfile} userAppData={userProfile}/>
            <div className='NAV-PAD'></div>
            <Routes>
                <Route path='/' element={<LandingPage />} />
                <Route path='/podcast/:playid?' element={<Podcast />} />
                <Route path='/faq' element={<Faq />} />
                <Route path='/login' element={<Login />} />
                <Route path='/register' element={<Register />} />
                {isProduction && <Route path='/users' element={<ViewUsers />} />}
                <Route exact path='/profile' element={<Profile setUserAppData={setUserProfile}/>} />
                <Route path='/verify-email/:uidb64/:token' element={<VerifyEmail />} />
                <Route path='/profiles/:userViewOnly' element={<Profile />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
                <Route path='/createpost' element={<CreatePost userProfile={userProfile}/>} />
                <Route path='/plan/subscription' element={<Subscription />} />
                <Route path='/reset-password/:uidb64/:token' element={<ResetPassword />} />
                <Route exact path='/linkedinCallback' element={<LinkedInCallback />} />
                <Route path='/plan/SuccessPayment' element={<SuccessPayment />} />
                <Route path='/plan/confirmcancel' element={<ConfirmCancel />} />
                <Route path='/plan/manageplan' element={<ManagePlans />} />
                <Route path='/plan/cancelplan' element={<CancelSubscription />} />
                <Route path='/aboutus' element={<AboutUs />} />
                <Route path='/contactus' element={<ContactUs />} />
                <Route path='/termsandcondition' element={<TermsandCondition/>}/>
                <Route path='/successContact' element={<ContactFormSuccceed />} />
                <Route path='/privacypolicy' element={<PrivacyPolicy />} />
                <Route path='/questionnaire/:teammemberUidb64/:ratedUser' element={<Questionnaire/>}/>
                <Route path='/user/ratingscore/:leaderInfo' element={<LeaderScore userProfile={userProfile}/>}/>
                <Route path='/user/ratingscore/:leaderInfo/:postId' element={<LeaderScore userProfile={userProfile}/>}/>
                <Route path='/user/myteams' element={<TeamDashboard userProfile={userProfile}/>}/>
                <Route path='/user/myposts/:postId' element={<UserPosts userProfile={userProfile}/>}/>
                <Route path='/user/myposts/' element={<UserPosts userProfile={userProfile}/>}/>
                <Route path='/posts/:userViewOnly' element={<UserPosts userProfile={userProfile}/>}/>
                <Route path='/notification' element={<Notification userProfile={userProfile}/>}/>
                <Route path='/setting' element={<Setting/>}/>
                <Route path='/unsubscribe/:uuid' element={<UnsubscribeNewsletter />} />
                <Route path='/aitools' element={<AiTools/>}/>
                <Route path='/404' element={<NotFound />}/>
            </Routes>
            <BottomNav/>
            <ToastContainer
                autoClose={2000}
                position="bottom-right"
            />
        </Router>
    );
}

export default App;