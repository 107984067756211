import { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import './Experience.css';
import { backendURL, convertDate, getAuthenticationHeader } from '../Utils/utils';

// Component to display and manage individual work experience entries
const Experience = (props) => {
    const [skillList, setSkillList] = useState([]);

    useEffect(() => {
        fetchWorkSkills();
        props.refresh_skill_call_back(false);
    }, [props.refresh_skill]);

    const fetchWorkSkills = async () => {
        try {
            const config = getAuthenticationHeader();

            const response = await axios.get(`${backendURL}/get_work_skills/`, { ...config, params: { id: props.item_id } });
            setSkillList(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleUpdate = () => {
        if (!props.edit_exp) return;

        const data = {
            title: props.title,
            employment_type: props.employment_type,
            company_name: props.company_name,
            location: props.location,
            location_type: props.location_type,
            currently_working: (props.end_month && props.end_year) ? false : true,
            start_month: props.start_month,
            start_year: props.start_year,
            end_month: props.end_month,
            end_year: props.end_year,
            // TODO: industry is not dynamically set
            industry: '',
            description: props.description,
            skillsArr: skillList,
        };

        props.edit_click(data, props.item_id);
    };

    const handleDelete = () => {
        if (!props.edit_exp) return;
        props.delete_click(props.item_id);
    };

    return (
        <div className="experience-card">
            <div className="top-bar">
                <h3>{props.title}</h3>
                {props.edit_exp && <i className="fa-solid fa-pen edit-btn" onClick={handleUpdate}></i>}
                {props.edit_exp && <i className="fa-solid fa-trash del-btn" onClick={handleDelete}></i>}
            </div>
            <div className="experience-company">
                <h4>{props.company_name}</h4>
                <p>{props.employment_type}</p>
            </div>
            <div className="experience-location">
                {/* Todo: make this month - year */}
                <p>{convertDate(`${props.start_month} ${props.start_year}`)} - {(props.end_month && props.end_year) ? convertDate(`${props.end_month} ${props.end_year}`) : 'current'}</p>
                <p>{props.location} • {props.location_type}</p>
            </div>
            {skillList && skillList.length > 0 ? (
                <div className="skill-item-container">
                    {skillList.map(skill => {
                        return (
                            <div key={`experience-${skill}`} className="skill-item"><p>{skill}</p></div>
                        );
                    })}
                </div>
            ) : (
                null
            )}
            {props.description && <p>{props.description}</p>}
        </div>
    );
};

Experience.propTypes = {
    refresh_skill: PropTypes.bool,
    refresh_skill_call_back: PropTypes.func,
    item_id: PropTypes.number,
    edit_exp: PropTypes.bool,
    title: PropTypes.string,
    employment_type: PropTypes.string,
    company_name: PropTypes.string,
    location: PropTypes.string,
    location_type: PropTypes.string,
    currently_working: PropTypes.bool,
    start_month: PropTypes.string,
    start_year: PropTypes.number,
    end_month: PropTypes.string,
    end_year: PropTypes.number,
    description: PropTypes.string,
    edit_click: PropTypes.func,
    delete_click: PropTypes.func,
};

export default Experience;