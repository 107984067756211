import { Link } from 'react-router-dom';
import axios from 'axios';
import './Subscription.css';
import { getAuthenticationHeader, backendURL } from '../Utils/utils';

// Component for manage subscription
const ManagePlans = () => {
    const handleManagePayment = async () => {

        const config = getAuthenticationHeader();
        const response = await axios.get(`${backendURL}/stripe/manage_payment`, config);

        if (response.status === 200) {
            console.log('navigating to stripe payment mangement window');
            const stripe_url = response.data;
            window.location.replace(stripe_url);
        }
    };

    return (
        <div className = "manage-plan-page-container">
            <div>
                <h3>Manage Your Subscription</h3>
                <h4>What would you like to do today?</h4>
            </div>

            <Link to="/plan/subscription">Enroll/Switch Plan</Link>

            <Link to="/plan/cancelplan">Cancel Subscription</Link>

            <Link onClick={handleManagePayment}>Manage Payment Method</Link>
        </div>
    );

};

export default ManagePlans;