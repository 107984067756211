import { useNavigate } from 'react-router-dom';
import './ContactUs.css';

// Displaying the successfully submitted contact form information.
const ContactFormSuccceed = () => {
    const navigate = useNavigate();
    const email = localStorage.getItem('email');
    const firstname = localStorage.getItem('firstname');
    const lastname = localStorage.getItem('lastname');
    const topic = localStorage.getItem('topic');
    const phone = localStorage.getItem('phone');
    const message = localStorage.getItem('message');

    const handlereturn = () => navigate('/contactus');

    return (
        <div className="contact-us-submitted-info-container">
            <h1>Contact Us</h1>
            <div>
                <div>You have succecssfully submitted your request.</div>
            </div>

            <table>
                <tbody>
                    <tr>
                        <th><b>First Name</b></th>
                        <th>{firstname}</th>
                    </tr>
                    <tr>
                        <th><b>Last Name</b></th>
                        <th>{lastname}</th>
                    </tr>
                    <tr>
                        <th><b>Topic</b></th>
                        <th>{topic}</th>
                    </tr>
                    <tr>
                        <th><b>Email</b></th>
                        <th>{email}</th>
                    </tr>
                    <tr>
                        <th><b>Phone number</b></th>
                        <th>{phone}</th>
                    </tr>
                    <tr>
                        <th><b>Message</b></th>
                        <th className='success-content-message'>{message}</th>
                    </tr>
                </tbody>
            </table>
            <div className='edit-form-group'>
                <button className="theme-button" onClick={handlereturn}>Return</button>
            </div>
        </div>
    );
};

export default ContactFormSuccceed;
