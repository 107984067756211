const customParseFormat = require('dayjs/plugin/customParseFormat');
const dayjs = require('dayjs');
dayjs.extend(customParseFormat);

export const isProduction = Boolean(window._env_.REACT_APP_NODE_ENV?.toLowerCase()?.startsWith('prod'));
export const backendURL = window._env_.REACT_APP_BACKEND_ENDPOINT ?? process.env.REACT_APP_BACKEND_ENDPOINT ?? 'http://localhost:8000';
export const frontendURL = window._env_.REACT_APP_FRONTEND_ENDPOINT ?? process.env.REACT_APP_FRONTEND_ENDPOINT ?? 'http://localhost:3000';

export const websocketURL = `${isProduction ? 'wss' : 'ws'}://${(new URL(backendURL)).host}/ws`;

export function convertDate(date_str) {
    if (dayjs(date_str, 'MMMM YYYY').isValid()) {
        return dayjs(date_str, 'MMMM YYYY').format('MMM YYYY');
    }
    else if (dayjs(date_str, 'YYYY-MM').isValid()) {
        return dayjs(date_str, 'YYYY-MM').format('MMM YYYY');
    }
    else {
        return '';
    }
}

export function parseDateFormData(formData) {
    const start_date = dayjs(`${formData.start_month} - ${formData.start_year}`, 'MMMM YYYY').format('YYYY-MM');
    const end_date = formData.end_month && formData.end_year ? dayjs(`${formData.end_month} - ${formData.end_year}`, 'MMMM YYYY').format('YYYY-MM') : '';
    return { start_date: start_date, end_date: end_date };
}

export function inputToRequestDateFormat(date_str) {
    const data = dayjs(date_str).format('MMMM-YYYY').split('-');
    return { month_val: data[0], year_val: data[1] };
}

export const degreeTypes = [
    {
        label: 'Associate\'s Degrees',
        options: [
            'Associate of Arts (A.A.)',
            'Associate of Science (A.S.)',
            'Associate of Applied Science (A.A.S.)',
            'Associate of Business Administration (A.B.A.)',
            'Associate of Fine Arts (A.F.A.)',
            'Associate of Engineering (A.E.)',
            'Associate of Nursing (A.N.)',
            'Associate of Computer Science (A.C.S.)',
        ],
    },
    {
        label: 'Bachelor\'s Degrees',
        options: [
            'Bachelor of Arts (B.A.)',
            'Bachelor of Science (B.S.)',
            'Bachelor of Fine Arts (B.F.A.)',
            'Bachelor of Business Administration (B.B.A.)',
            'Bachelor of Music (B.Mus.)',
            'Bachelor of Engineering (B.Eng.)',
            'Bachelor of Education (B.Ed.)',
            'Bachelor of Nursing (B.N.)',
            'Bachelor of Social Work (B.S.W.)',
            'Bachelor of Architecture (B.Arch.)',
        ],
    },
    {
        label: 'Master\'s Degrees',
        options: [
            'Master of Arts (M.A.)',
            'Master of Science (M.S.)',
            'Master of Business Administration (MBA)',
            'Master of Fine Arts (M.F.A.)',
            'Master of Education (M.Ed.)',
            'Master of Public Administration (MPA)',
            'Master of Social Work (M.S.W.)',
            'Master of Engineering (M.Eng.)',
            'Master of Music (M.Mus.)',
            'Master of Health Administration (MHA)',
        ],
    },
    {
        label: 'Doctoral Degrees',
        options: [
            'Doctor of Philosophy (Ph.D.)',
            'Doctor of Medicine (M.D.)',
            'Doctor of Jurisprudence (J.D.)',
            'Doctor of Dental Medicine (D.M.D.)',
            'Doctor of Veterinary Medicine (D.V.M.)',
            'Doctor of Psychology (Psy.D.)',
            'Doctor of Nursing Practice (DNP)',
            'Doctor of Education (Ed.D.)',
            'Doctor of Pharmacy (Pharm.D.)',
            'Doctor of Theology (Th.D.)',
        ],
    },
    {
        label: 'Professional Doctorates',
        options: [
            'Juris Doctor (J.D.) - Law',
            'Doctor of Medicine (M.D.) - Medical',
            'Doctor of Dental Medicine (D.M.D.) - Dental',
            'Doctor of Optometry (O.D.) - Optometry',
            'Doctor of Physical Therapy (DPT) - Physical Therapy',
            'Doctor of Chiropractic (D.C.) - Chiropractic',
            'Doctor of Pharmacy (Pharm.D.) - Pharmacy',
        ],
    },
    {
        label: 'Honorary Doctorates',
        options: [],
    },
    {
        label: 'Postgraduate Certificates/Diplomas',
        options: [
            'Postgraduate Certificate',
            'Postgraduate Diploma',
        ],
    },
    {
        label: 'Undergraduate Certificates/Diplomas',
        options: [
            'Undergraduate Certificate',
            'Undergraduate Diploma',
        ],
    },
    {
        label: 'Professional Certifications',
        options: [
            'Certified Public Accountant (CPA)',
            'Project Management Professional (PMP)',
            'Certified Information Systems Security Professional (CISSP)',
            'Certified Financial Planner (CFP)',
        ],
    },
    {
        label: 'Vocational/Technical Certificates',
        options: [
            'Welding Certificate',
            'Culinary Arts Certificate',
            'Automotive Repair Certificate',
            'Electrician Certificate',
            'HVAC Technician Certificate',
        ],
    },
    {
        label: 'Online Degrees',
        options: [],
    },
    {
        label: 'Dual Degrees',
        options: [],
    },
    {
        label: 'Joint Degrees',
        options: [],
    },
    {
        label: 'Terminal Degrees',
        options: [],
    },
    {
        label: 'Non-Degree Programs',
        options: [],
    },
];

export const getAuthenticationHeader = (contentType) => {
    const token = localStorage.getItem('token');
    if (!token) {
        console.error('Token not found. User not logged in.');
        // Handle case where the token is not available or expired

        // maybe redirect to login login page
        if (
            window.location.href !== `${frontendURL}/` &&
            window.location.href !== `${frontendURL}/login` &&
            window.location.href !== `${frontendURL}/register` &&
            window.location.href !== `${frontendURL}/faq` &&
            window.location.href !== `${frontendURL}/unsubscribe` &&
            !window.location.href.startsWith(`${frontendURL}/podcast`) &&
            !window.location.href.startsWith(`${frontendURL}/verify-email`) &&
            !window.location.href.startsWith(`${frontendURL}/reset-password`) &&
            !window.location.href.startsWith(`${frontendURL}/questionnaire`) &&
            !window.location.href.startsWith(`${frontendURL}/privacypolicy`) &&
            !window.location.href.startsWith(`${frontendURL}/user/ratingscore`) &&
            !window.location.href.startsWith(`${frontendURL}/unsubscribe`)
        ) {
            window.location.replace(`${frontendURL}/`);
        }
        return null;
    }

    const config = {
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    };

    if (contentType) {
        config.headers['Content-Type'] = contentType;
    }

    return config;
};

export const getAuthenticationToken = () => localStorage.getItem('token') ?? null;

export function logOut() {
    // Clear the token and isLoggedIn flag from local storage
    localStorage.clear();
    window.dispatchEvent(new Event('storage'));
    window.location.replace(`${frontendURL}/`);
}

export const attributeQuestionList = [
    {
        'id': 1,
        'questioncontent': 'Decisive',
        'tooltip': 'Consistently demonstrates the ability and capacity to make a decision in a timely and effective manner even with imperfect information. Can demonstrate agile thinking and decision-making by adapting to updated information and making progress.',
    },
    {
        'id': 2,
        'questioncontent': 'Communication',
        'tooltip': 'Consistently demonstrates effective messaging of information at an appropriate level of transparency which ensures that direct reports/team members can plan, anticipate, and act in furtherance of their goals and objectives.',
    },
    {
        'id': 3,
        'questioncontent': 'Seeks Diversity & Demonstrates Inclusiveness',
        'tooltip': 'Bring different perspectives together in a psychologically safe forum to fight workplace biases by making all direct reports/team members feel included and heard.',
    },
    {
        'id': 4,
        'questioncontent': 'Champion of New Ideas & Innovation',
        'tooltip': 'Seeks new ideas and is open to thinking outside of the box by direct reports/team members in solving complex real-world/ business problems – consistently rewards and encourages critical thinking skills development and implementation.',
    },
    {
        'id': 5,
        'questioncontent': 'Self-Awareness / Self-Regulated Emotions',
        'tooltip': 'Consistently seeks feedback from direct reports/team members as essential. Controls emotional outbursts, self reflects on actions and behaviors in the workplace. Admits to and accepts accountability for actions and mistakes.',
    },
    {
        'id': 6,
        'questioncontent': 'Displays Empathy & Compassion',
        'tooltip': 'Drives performance and accountability while balancing caring for direct reports/team members with consistent behaviors in treating people with dignity and respect.',
    },
    {
        'id': 7,
        'questioncontent': 'Inspires Others to Success',
        'tooltip': 'Positively influences direct reports/team members by motivating them in achieving successful outcomes. People seek out this leader for opportunities to work on the team.',
    },
    {
        'id': 8,
        'questioncontent': 'Champions Change',
        'tooltip': 'Consistently seeks to adapt to a changing environment and reassures direct reports/team members of a successful outcome – fearless in the face of change and ambiguity because this is seen by the leader as opportunities.',
    },
    {
        'id': 9,
        'questioncontent': 'Leads by Example',
        'tooltip': 'Demonstrates excellence by teaching skill sets, coaches to adjust behaviors, and mentors future leaders by being the model for others to follow – the standard for morals, ethics, and can be counted upon to do the right thing consistently.',
    },
    {
        'id': 10,
        'questioncontent': 'Demonstrates Technical Competency Required of the Position',
        'tooltip': 'Earned the respect of and sought-out by peers as a subject matter expert in the position, field of work, or industry. Highly regarded for expertise by direct reports/team members.',
    },
    {
        'id': 11,
        'questioncontent': 'Develops a Compelling Vision',
        'tooltip': 'Concisely communicates a vision of current to target state for direct reports/team members to own and synchronize their own goals and objectives.',
    },
    {
        'id': 12,
        'questioncontent': 'Develops & Grows Leaders',
        'tooltip': 'Avoids micro-managing but instead provides the objectives, goals, and vision needed to support and empower direct reports/team members in achieving success. Shares power/authority through a collaborative arrangement to maximize group dynamics.',
    },
    {
        'id': 13,
        'questioncontent': 'Emotional Intelligence',
        'tooltip': 'Consistently demonstrates the ability to perceive, identify, use, assess, and understand self-emotions and that of direct reports/team members for promoting cohesion and welcoming collaboration.',
    },
    {
        'id': 14,
        'questioncontent': 'Builds Trust',
        'tooltip': 'Consistently builds rapport with direct reports/team members in furthering trust by listening, being approachable, and following-through with action.',
    },
    {
        'id': 15,
        'questioncontent': 'Toxic Culture Intolerant',
        'tooltip': 'Courageous, confident, transparent, treats all fairly and equitably, and mitigates external toxicity from bad managers.',
    },
    {
        'id': 16,
        'questioncontent': 'Builds High-Performing Teams',
        'tooltip': 'Consistently sets clear goals with stretch for direct reports/team members to grow, makes the effort to know what makes each individual tick, creates a rewarding, safe, and selfless personal and professional development.',
    },
    {
        'id': 17,
        'questioncontent': 'Sense of Purpose',
        'tooltip': 'Consistently provides direct reports/team members with a professional identity, meaning, and sense of purpose for each individual.',
    },
    {
        'id': 18,
        'questioncontent': 'Their Word is Their Bond',
        'tooltip': 'Consistently demonstrates that this leader will do what she/he stated or committed to doing-keeps their promise.',
    },
    {
        'id': 19,
        'questioncontent': 'Personal & Professional Growth',
        'tooltip': 'Consistently provides a psychologically safe learning environment, forgives honest mistakes as a learning process toward success, provides fair, timely, and unbiased feedback to direct reports/team members for strengths and opportunities.',
    },
    {
        'id': 20,
        'questioncontent': 'Delegates Authority But Retains Accountability for Outcome',
        'tooltip': 'Does not finger-point or blame direct reports/team members when failure occurs. Instead, defends them by redirecting harsh criticism or office politicking to his/her own short-comings or mistakes.',
    },
    {
        'id': 21,
        'questioncontent': 'Acquires & Retains Top Talent',
        'tooltip': 'Seeks and hires top talent as well as successfully retains them through opportunities for growth, development, sense of community, and his/her personal leadership style. People want to work with this leader as she makes the difference to her direct reports/team members.',
    },
];