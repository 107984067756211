import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { backendURL } from '../Utils/utils';
import 'react-toastify/dist/ReactToastify.css';
import './ForgotPassword.css';

// This page load when the user click on the reset password link in the email
const ResetPassword = () => {
    const { uidb64, token } = useParams();
    const [isTokenValid, setTokenValid] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Verify the reset token before rendering the page
        checkToken();
    }, []);

    const checkToken = async () => {
        try {
            const response = await axios.get(`${backendURL}/verify_reset_token?uidb64=${uidb64}&token=${token}`);

            if (response.status === 200) {
                setTokenValid(true);
            }
        }
        catch (e) {
            toast.error('Invalid reset token. Please try again.');
        }
    };

    const handlePasswordReset = (e) => {
        e.preventDefault();

        if (newPassword !== repeatPassword) {
            toast.error('Passwords don\'t match.');
            return;
        }

        if (newPassword.length < 8) {
            toast.error('Password must be at least 8 characters long.');
            return;
        }

        // Send request to reset password
        axios
            .post(`${backendURL}/reset_password/`, { uidb64, token, new_password: newPassword })
            .then(response => {
                if (response.status === 200) {
                    toast.success('Password reset successfully.');
                    setTimeout(() => {
                        navigate('/login');
                    }, 3000);
                } else toast.error('Password reset failed. Please try again.');
            })
            .catch(() => toast.error('Password reset failed. Please try again.'));
    };

    if (!isTokenValid) {
        return <div>Verifying reset token...</div>;
    }

    return (
        <div className="forgot-password-container-page">
            <div className="forgot-password-container edit-form-group">
                <h2>Create a new password</h2>
                <form onSubmit={handlePasswordReset}>
                    <input
                        type="password"
                        placeholder="New Password"
                        value={newPassword}
                        onChange={e => setNewPassword(e.target.value)}
                        autoComplete='new-password'
                    />
                    <input
                        type="password"
                        placeholder="Repeat Password"
                        value={repeatPassword}
                        onChange={e => setRepeatPassword(e.target.value)}
                        autoComplete='new-password'
                    />
                    <button type="submit" className="reset-password-button">
                        Reset Password
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;
