import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import PropTypes from 'prop-types';

// Google login module (SSO login)
const GoogleLoginModule = (props) => {
    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_OAUTH_CLIENT_ID ?? window._env_.REACT_APP_OAUTH_CLIENT_ID}>
            <GoogleLogin
                onSuccess={credentialResponse => {
                    props.handlerFunction(credentialResponse.credential);
                }}
                onError={() => {
                    console.error('Login Failed');
                }}
                useOneTap={props.useOneTap}
                prompt_parent_id={props.oneTapParentId}
                width="250px"
            />
        </GoogleOAuthProvider>
    );
};

GoogleLoginModule.propTypes = {
    handlerFunction: PropTypes.func,
    useOneTap: PropTypes.bool,
    oneTapParentId: PropTypes.string,
};

export default GoogleLoginModule;