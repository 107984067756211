import axios from 'axios';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button } from 'react-bootstrap';

import './Education.css';
import './profile-modal.css';
import '../Utils/common.css';
import { parseDateFormData, inputToRequestDateFormat, degreeTypes, getAuthenticationHeader, backendURL } from '../Utils/utils';

// Component for editing education details.
const EducationEdit = (props) => {
    // State for education form data, defaulting to passed props or blank fields
    const [educationFormData, setEducationFormData] = useState(props.formData ? props.formData : {
        institution: '',
        degree: '',
        gpa: '',
        gpa_scale: '',
        field_of_study: '',
    });

    // State for managing start and end dates, with parsing from formData if available
    const [dateData, setDateData] = useState(props.formData ? parseDateFormData(props.formData) : {
        start_date: '',
        end_date: '',
    });

    // State for managing dynamic achievement entries
    const [achievementsArr, setAchievementsArr] = useState(props.formData ? props.formData.achievements.items : []);

    // State to handle form validation status
    const [validated, setValidated] = useState(false);

    const handleChange = (e, origin = 'default') => {
        let newChange = null;
        if (origin === 'default') {
            newChange = {
                [e.target.name]: e.target.value,
            };
        }
        else if (origin === 'degree-select') {
            newChange = {
                degree: e.target.value,
            };
        }
        else if (origin === 'date') {
            const { month_val, year_val } = inputToRequestDateFormat(e.target.value);
            if (e.target.name === 'start_date') {
                newChange = {
                    start_month: month_val,
                    start_year: year_val,
                };
            }
            else {
                newChange = {
                    end_month: month_val,
                    end_year: year_val,
                };
            }
            setDateData({ ...dateData, ...{ [e.target.name]: e.target.value } });
        }
        setEducationFormData({ ...educationFormData, ...newChange });
    };

    const handleAchievementChange = (e, index) => {
        const obj = [...achievementsArr];
        obj[index] = { ...obj[index], [e.target.name]: e.target.value };
        setAchievementsArr(obj);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        const data = { ...educationFormData, achievements: { items: achievementsArr } };
        if (
            !data.institution ||
            !data.start_month ||
            !data.start_year ||
            !data.degree
        ) {
            setValidated(true);
            return;
        }
        for (const achievement of achievementsArr) {
            if (
                !achievement.name ||
                achievement.name.length === 0 ||
                !achievement.year
            ) {
                setValidated(true);
                return;
            }
        }
        setValidated(false);

        try {
            const config = getAuthenticationHeader();

            if (!props.editEducation) await axios.post(`${backendURL}/education/`, data, config);
            else await axios.patch(`${backendURL}/education/`, { ...data, ...{ id: props.formDataID } }, config);
            props.closePanel(true);
        } catch (error) {
            console.error(error);
        }
    };

    const addAchievements = () => setAchievementsArr([...[{ name: '', year: '', desc: '' }], ...achievementsArr]);
    const removeAchievement = (idx) => {
        const updatedArr = achievementsArr;
        updatedArr.splice(idx, 1);
        setAchievementsArr([...updatedArr]);
    };

    return (
        <Modal
            className="edit-form-group profile-edit"
            backdrop="static"
            centered
            show={props.show}
            onSubmit={handleSubmit}
            onHide={props.closePanel}
        >
            <Modal.Header closeButton>
                <h3>Your education</h3>
            </Modal.Header>
            <Modal.Body>
                <Form
                    onSubmit={handleSubmit}
                    noValidate
                    validated={validated}
                >
                    <Form.Group>
                        <Form.Label htmlFor="institution">Institution:</Form.Label>
                        <Form.Control
                            type="text"
                            name="institution"
                            value={educationFormData.institution}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group id="degree-gpa-edit">
                        <div>
                            <Form.Label>Degree:</Form.Label>
                            <Form.Select
                                onChange={e => handleChange(e, 'degree-select')}
                                value={educationFormData.degree}
                                required
                            >
                                <option value="">Choose your degree type</option>
                                {degreeTypes.map((optgroup, idx) => {
                                    return (
                                        <optgroup label={optgroup.label} key={`employment-type-${idx}`}>
                                            {optgroup.options.map((option, idxOpt) => {
                                                return (
                                                    <option value={option} key={`employment-type-${idx}-${idxOpt}`}>{option}</option>
                                                );
                                            })}
                                        </optgroup>
                                    );
                                })}
                            </Form.Select>
                        </div>
                        <div id="gpa-field">
                            <Form.Label htmlFor="gpa">GPA:</Form.Label>
                            <div>
                                <Form.Control
                                    type="number"
                                    name="gpa"
                                    value={educationFormData.gpa}
                                    onChange={handleChange}
                                    maxLength="2"
                                    placeholder="gpa"
                                    step="0.1"
                                />
                                <p>of</p>
                                <Form.Control
                                    type="number"
                                    name="gpa_scale"
                                    value={educationFormData.gpa_scale}
                                    onChange={handleChange}
                                    placeholder="gpa scale"
                                    step="0.1"
                                />
                            </div>
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label htmlFor="field_of_study">Field of Study:</Form.Label>
                        <br />
                        <Form.Control
                            type="text"
                            name="field_of_study"
                            value={educationFormData.field_of_study}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group id="education-start-end-date">
                        <div>
                            <Form.Label htmlFor="startDate">Start Date:</Form.Label>
                            <Form.Control
                                type="month"
                                name="start_date"
                                value={dateData.start_date}
                                onChange={e => {handleChange(e, 'date');}}
                                required
                            />
                        </div>
                        <div>
                            <Form.Label htmlFor="endDate">End Date (or Expected):</Form.Label>
                            <input
                                type="month"
                                name="end_date"
                                value={dateData.end_date}
                                onChange={e => {handleChange(e, 'date');}}
                            />
                        </div>
                    </Form.Group>

                    <div className='education-achievement-add-btn'>
                        <Button
                            className="theme-button"
                            onClick={addAchievements}
                            type="button"
                        >
                            <i className="fas fa-plus"></i>Add Achievements
                        </Button>
                    </div>
                    <div className="edit-education-achievements-list-container">
                        {achievementsArr && achievementsArr.map((item, index) => {
                            return (
                                <div key={`edit-education-achievement-${index}`}>
                                    <div className="education-achievement-item">
                                        <div>
                                            <Form.Control
                                                type='text'
                                                placeholder="Achievement Title"
                                                value={item.name}
                                                name="name"
                                                onChange={e => handleAchievementChange(e, index)}
                                                required
                                            />
                                            <Form.Control
                                                type='number'
                                                min="1900"
                                                max="2099"
                                                placeholder="Year"
                                                value={item.year}
                                                name="year"
                                                onChange={e => handleAchievementChange(e, index)}
                                                required
                                            />
                                            <i className="fa-solid fa-trash del-btn" onClick={indexAchievement => removeAchievement(indexAchievement)}></i>
                                        </div>
                                        <Form.Control
                                            as='textarea'
                                            placeholder="Achievement Description (optional)"
                                            className="edit-education-achievement-desc"
                                            value={item.desc}
                                            name="desc"
                                            onChange={e => handleAchievementChange(e, index)}
                                        />
                                    </div>
                                </div>
                            );
                        })
                        }
                    </div>
                    <div className='form-footer-btn-group'>
                        <Button variant='secondary' onClick={props.closePanel}>
                            Cancel
                        </Button>
                        <Button className='confirm' type='submit'>
                            Update Profile
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

EducationEdit.propTypes = {
    formData: PropTypes.shape({
        institution: PropTypes.string,
        degree: PropTypes.string,
        gpa: PropTypes.string,
        field_of_study: PropTypes.string,
        start_date: PropTypes.string,
        end_date: PropTypes.string,
        achievements: PropTypes.shape({
            items: PropTypes.arrayOf(PropTypes.shape({
                name: PropTypes.string,
                year: PropTypes.number,
                description: PropTypes.string,
            })),
        }),
    }),
    editEducation: PropTypes.bool,
    closePanel: PropTypes.func,
    formDataID: PropTypes.string,
    show: PropTypes.bool,
};

export default EducationEdit;