import { useState } from 'react';
import axios from 'axios';
import { Modal, Form, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import './ExperienceEdit.css';
import './profile-modal.css';
import { parseDateFormData, inputToRequestDateFormat, getAuthenticationHeader, backendURL } from '../Utils/utils';

// Component for editing user's work experience in a modal form.
const ExperienceEdit = (props) => {
    // State initialization for form data and date
    const [workExperienceFormData, setWorkExperienceFormData] = useState(props.formData ? props.formData : {
        title: '',
        employment_type: '',
        company_name: '',
        location: '',
        location_type: '',
        currently_working: true,
        industry: '',
        description: '',
        profile_headline: '',
        skills: [],
        media: [],
    });

    const [dateData, setDateData] = useState(props.formData ? parseDateFormData(props.formData) : {
        start_date: '',
        end_date: '',
    });

    const [skills, setSkills] = useState(props.formData ? props.formData.skillsArr : []);
    const [suggestions, setSuggestions] = useState(null);
    const [input, setInput] = useState(null);
    const [validated, setValidated] = useState(false);

    const handleChange = (e, changeType = 'default') => {
        let newChange = null;
        if (changeType === 'default') {
            const { name, value } = e.target;
            if (workExperienceFormData.currently_working) setDateData((prevFormData) => ({
                ...prevFormData,
                'end_date': '',
            }));

            newChange = { [name]: value };
        }
        else if (changeType === 'currently-working-type') {
            newChange = { 'currently_working': e.target.checked };
        }
        else if (changeType === 'employment-type') {
            newChange = { 'employment_type': e.target.value };
        }
        else if (changeType === 'location-type') {
            newChange = { 'location_type': e.target.value };
        }
        else if (changeType === 'date') {
            const { month_val, year_val } = inputToRequestDateFormat(e.target.value);
            if (e.target.name === 'start_date') {
                newChange = {
                    start_month: month_val,
                    start_year: year_val,
                };
            }
            else {
                newChange = {
                    end_month: month_val,
                    end_year: year_val,
                };
            }
            setDateData({ ...dateData, ...{ [e.target.name]: e.target.value } });
        }
        setWorkExperienceFormData({ ...workExperienceFormData, ...newChange });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (
            !workExperienceFormData.title ||
            !workExperienceFormData.employment_type ||
            !workExperienceFormData.company_name ||
            !dateData.start_date
        ) {
            setValidated(true);
            return;
        }
        if (!props.editExperience) await handleSubmitAdd(e);
        else await handleSubmitEdit(e);

        props.closePanel(true);
    };

    const handleSubmitAdd = async () => {
        try {
            const config = getAuthenticationHeader();
            await axios.post(`${backendURL}/work_experience/`, { ...workExperienceFormData, skills: skills }, config);
            toast.success('Added new experience successfully');
        } catch (error) {
            toast.error('Something unexpected happened');
            console.error(error);
        }
    };

    const handleSubmitEdit = async () => {
        try {
            const config = getAuthenticationHeader();
            await axios.patch(`${backendURL}/work_experience/`, { ...workExperienceFormData, ...{ id: props.editExperienceID }, skills: skills }, config);
            toast.success('Edited experience successfully');
        } catch (error) {
            toast.error('Something unexpected happened');
            console.error(error);
        }
    };

    let isEditing = false;
    const skillInputChange = async (e) => {
        e.preventDefault();
        if (isEditing) return;
        if (e.target.value === '') {
            setSuggestions(null);
            setInput(null);
            return;
        }

        isEditing = true;
        setTimeout(async () => {
            isEditing = false;

            if (e.target.value === '') {
                setSuggestions(null);
                setInput(null);
                return;
            }
            try {
                setInput(e.target.value.replace(/\b\w/g, l => l.toUpperCase()));
                const request = await axios.get(`${backendURL}/suggest_skills/`, { params: { input: e.target.value } });
                setSuggestions(request.data.suggest_skills);
            } catch (error) {
                console.error(error);
            }
        }, 500);
    };

    return (
        <Modal
            className="edit-form-group profile-edit"
            backdrop="static"
            centered
            show={props.show}
            onHide={props.closePanel}
        >
            <Modal.Header closeButton>
                <h3>Your professional experience</h3>
            </Modal.Header>
            <Modal.Body>
                <Form
                    id="profile-exp-form"
                    onSubmit={handleSubmit}
                    validated={validated}
                    noValidate
                >
                    <Form.Group>
                        <Form.Label htmlFor="title">Professional title:</Form.Label>
                        <Form.Control
                            type="text"
                            name="title"
                            value={workExperienceFormData.title}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group className="field-container">
                        <Form.Label>Employment Type:</Form.Label>
                        <Form.Select
                            onChange={e => handleChange(e, 'employment-type')}
                            value={workExperienceFormData.employment_type}
                            required
                        >
                            <option value="">Choose your employment type</option>
                            {employment_type.map((option, idx) => {
                                return (<option value={option} key={`employment-type-${idx}`}>{option}</option>);
                            })}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label htmlFor="company_name">Company name:</Form.Label>
                        <Form.Control
                            type="text"
                            name="company_name"
                            value={workExperienceFormData.company_name}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label htmlFor="location">Location:</Form.Label>
                        <Form.Control
                            type="text"
                            name="location"
                            value={workExperienceFormData.location}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group className="field-container">
                        <Form.Label>Location Type:</Form.Label>
                        <Form.Select
                            onChange={e => handleChange(e, 'location-type')}
                            value={workExperienceFormData.location_type}
                            required
                        >
                            <option value="">Choose your location type</option>
                            {location_type.map((option, idx) => {
                                return (<option value={option} key={`location-type-${idx}`}>{option}</option>);
                            })}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="field-container-check">
                        <Form.Check
                            type="checkbox"
                            className="check"
                            name="currently_working"
                            checked={workExperienceFormData.currently_working}
                            onChange={e => handleChange(e, 'currently-working-type')}
                            label='I am currently working in this role'
                        />
                    </Form.Group>

                    <Form.Group className="exp-date">
                        <div className="field-container">
                            <Form.Label htmlFor="start_date">Start date:</Form.Label>
                            <Form.Control
                                type="month"
                                name="start_date"
                                value={dateData.start_date}
                                onChange={e => { handleChange(e, 'date'); }}
                                required
                            />
                        </div>

                        {!workExperienceFormData.currently_working && (
                            <div className="field-container">
                                <Form.Label htmlFor="end_date">End date:</Form.Label>
                                <Form.Control
                                    type="month"
                                    name="end_date"
                                    value={dateData.end_date}
                                    onChange={e => { handleChange(e, 'date'); }}
                                    required
                                />
                            </div>
                        )}
                    </Form.Group>

                    <Form.Label htmlFor="description">Description:</Form.Label>
                    <Form.Control
                        as='textarea'
                        name="description"
                        value={workExperienceFormData.description}
                        onChange={handleChange}/>

                    <Form.Control
                        type="text"
                        id="skill-search"
                        placeholder="Add up to 5 skills"
                        onChange={skillInputChange}
                    />
                    {suggestions ?
                        <div id="skill-suggestion-container">
                            <h3>Suggestions</h3>
                            <div>
                                {suggestions ?
                                    [...new Set([input, ...suggestions])].map(suggestion => {
                                        return (
                                            <p
                                                key={`${suggestion}-edit-skill`}
                                                onClick={() => {
                                                    if (skills.length < 5) setSkills([...new Set([...skills, suggestion])]);
                                                }}
                                            >
                                                {suggestion}
                                            </p>
                                        );
                                    })
                                    :
                                    null
                                }
                            </div>
                        </div>
                        :
                        null
                    }
                    {/* Display all current Skills */}
                    <div className="skill-item-container edit-skill-item-container">
                        <p>Your Current Skills</p>
                        {skills ?
                            skills.map(skill => {
                                return (
                                    <div key={skill} className="skill-item">
                                        <p>{skill}</p>
                                        <span
                                            className="skill-remove-btn"
                                            onClick={() => {
                                                const i = skills.indexOf(skill);
                                                const r = [...skills];
                                                r.splice(i, 1);
                                                setSkills(r);
                                            }}>
                                            &times;
                                        </span>
                                    </div>
                                );
                            })
                            :
                            null
                        }
                    </div>
                    <div className='form-footer-btn-group'>
                        <Button variant='secondary' onClick={props.closePanel}>
                            Cancel
                        </Button>
                        <Button className='confirm' type='submit'>
                            Update Profile
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

ExperienceEdit.propTypes = {
    formData: PropTypes.shape({
        title: PropTypes.string,
        employment_type: PropTypes.string,
        company_name: PropTypes.string,
        location: PropTypes.string,
        location_type: PropTypes.string,
        currently_working: PropTypes.bool,
        industry: PropTypes.string,
        description: PropTypes.string,
        profile_headline: PropTypes.string,
        skills: PropTypes.array,
        media: PropTypes.array,
        skillsArr: PropTypes.array,
    }),
    editExperience: PropTypes.bool,
    closePanel: PropTypes.func,
    editExperienceID: PropTypes.string,
    show: PropTypes.bool,
};

const employment_type = [
    'Co-op',
    'Contract',
    'Fellowship',
    'Freelance',
    'Full-Time',
    'Internship',
    'Part-Time',
    'Research',
    'Other',
];

const location_type = [
    'Hybrid',
    'On-site',
    'Remote',
];

export default ExperienceEdit;