import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { frontendURL } from '../Utils/utils';

import LinkedinLoginIMG from '../../assets/linkedin.png';

const LinkedinLoginModule = (props) => {
    // This prevents multiple eventlistener from being invoked
    let allowFire = true;

    const handleMessageListener = (event) => {
        if (!allowFire) {
            return;
        }
        if (event.origin !== frontendURL || event.data.error) {
            allowFire = true;
            return;
        }
        // This block of code serves the following purpose:
        // Due to the nature of how LinkedinLoginModule implements window.addEventListener,
        // multiple instances in muyltiple routes of the component will fire when call back is called after loggin into Linkedin
        // Therefore, this block checks which instances is to be fired, given the destination in event.data.path
        // Instances not mentioned in even.data.path will try to invoke non existing prop to handle the login
        // So we check if the props exists before calling
        if (event.data.from === 'linkedin') {
            allowFire = false;

            if ((event.data.path === '/' || event.data.path === '/login') && props.handleLogin) props.handleLogin(event.data.code);
            else if (event.data.path === '/register' && props.handleRegister) props.handleRegister(event.data.code);
        }
    };

    useEffect(() => {
        window.addEventListener('message', event => handleMessageListener(event), false);

        return () => {
            // Clean up the event listener when the component unmounts
            window.removeEventListener('message', handleMessageListener);
        };
    }, []);

    const param = {
        scope: 'profile email openid w_member_social',
        redirect_uri: `${window.location.origin}/linkedinCallback`,
        client_id: process.env.REACT_APP_LINKEDIN_CLIENT_ID ?? window._env_.REACT_APP_LINKEDIN_CLIENT_ID,
    };
    const query_param = new URLSearchParams(param);
    const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&${query_param}`;
    const linkedInLogin = () => {
        window.open(
            url,
            '_blank',
        );
    };

    return (
        <div className="linkedinLogin-container" style={linkedinLogin_container_style}>
            <img
                onClick={linkedInLogin}
                src={LinkedinLoginIMG}
                alt="Sign in with Linked In"
                style={{ height: '38px', cursor: 'pointer' }}
            />
        </div>
    );
};

const linkedinLogin_container_style = {
    backgroundColor: 'var(--main-accent-blue)',
    width: '250px',
    height: '38px',
    borderRadius: '3px',
};

// This is the call back function that is the pop up after linkedin log in
const LinkedInCallback = () => {
    const search = window.location.search.split('code=')[1];
    const error = window.location.search.split('error=')[1];

    window.opener.postMessage(
        {
            code: search,
            error: error,
            from: 'linkedin',
            path: window.opener.location.pathname,
        },
        window.location.origin,
    );

    // For some reason, if window.close() immeadiately, home.svg icon on left of navbar disappears in the
    // Parent window, so delaying it just a bit will fix the issue
    setTimeout(() => window.close(), 1);
    return;
};

LinkedinLoginModule.propTypes = {
    handleLogin: PropTypes.func,
    handleRegister: PropTypes.func,
};

export {
    LinkedinLoginModule,
    LinkedInCallback,
};