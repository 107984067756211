import { useState } from 'react';
import './Chatbot.css';
import RecommendBook from './BookRecommendation';
import Chatbot from './Chatbot';
import '../Utils/common.css';
import './Chatbot.css';

// Component for AI tools, including a book recommendation and a chatbot.
const AiTools = () => {
    const [key, setKey] = useState('book');

    const showBookRec = () => {
        setKey('book');
    };

    const showChatbot = () => {
        setKey('chat');
    };

    return (
        <div className='ai-tool-homebody'>
            <div className='ai-tool_container'>
                <div className='ai-tool-tabs'>
                    <button className={key === 'book' ? 'ai-tool-btn-active' : null} onClick={showBookRec} >Book Recommendation</button>
                    <button className={key === 'chat' ? 'ai-tool-btn-active' : null} onClick={showChatbot}>Leadership AI consultant</button>
                </div>


                <div className="teamdashboard-content">
                    {key === 'book' ? (
                        <RecommendBook />
                    ) : (
                        <Chatbot />
                    )}
                </div>
            </div>
        </div>);
};
export default AiTools;
