import PropTypes from 'prop-types';
import UserThumbnail from '../Utils/UserThumbnail';
import './MyRatedLeaders.css';
import '../Utils/common.css';

// Component for displaying a rated leader
const RatedLeader = (props) => {
    return (
        <div className='rated-leader-card'>
            <UserThumbnail userProfile={props.leader} self={false} />
            <div className=''>
                My Rating: <strong>{props.averageScore}</strong>
            </div>
        </div>
    );
};

RatedLeader.propTypes = {
    averageScore: PropTypes.number,
    leader: PropTypes.object,
};

export default RatedLeader;
