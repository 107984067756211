import { useState } from 'react';
import axios from 'axios';
import './BookRecommendation.css';
import { getAuthenticationHeader, backendURL, logOut } from '../Utils/utils';
import { Form } from 'react-bootstrap';
import Loading from '../Utils/Loading';
import '../Utils/common.css';

// Retrieves book recommendations based on specified genres and authors.
const RecommendBook = () => {
    const [genres, setGenres] = useState();
    const [authors, setAuthors] = useState();
    const [recommendation, setRecommendation] = useState([]);
    const [loading, setLoading] = useState(null);

    const getRecommendation = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const config = getAuthenticationHeader();
            const data = {
                // prompt: recommend some books for someone who loves the following genres: ${genres} and the following authors: ${authors},
                'genres': genres,
                'authors': authors,
            };

            const response = await axios.post(`${backendURL}/openai/bookrecommendation/`, data, config);
            if (response.data.books.length === 0) {
                setLoading(false);
                setRecommendation({});
                return;
            }

            if (response.status === 201) {
                const books = response.data.books;
                setRecommendation(books);
                setLoading(false);
            }
        } catch (error) {
            logOut();
            console.error(error);
        }

    };

    return (
        <div className='book-rec-body'>
            <h2>Top 10 Book Recommendation</h2>
            <p>Learn to be a great people leader through highly rated leadership-related titles</p>
            <Form className='edit-form-group book-rec-form' onSubmit={getRecommendation}>
                <Form.Group>
                    <Form.Label>Genres</Form.Label>
                    <Form.Select id="genres" value={genres} onChange={(e) => setGenres(e.target.value)}>
                        <option value="">-- Select --</option>
                        {bookGenres.map((genre, idx) => {return (
                            <option value={genre} key={`genre-${idx}`}>{genre}</option>
                        );})}
                    </Form.Select>
                </Form.Group>
                <Form.Group>
                    <Form.Label htmlFor="authors">Authors (separated with commas)</Form.Label>
                    <Form.Control type="text" id="authors" name="authors" onChange={(e) => setAuthors(e.target.value)} />
                </Form.Group>
                <button className='theme-button' value="Get recommendations" type='submit'>Get recommendation</button>
            </Form>
            {loading ? <Loading message='Loading Book Recommendation List' />
                :
                (<div className='book-rec-list'>
                    {recommendation.map((book, index) => (
                        <div key={`rec-${index}`}>
                            <h5>{book.title}</h5>
                            <div className='book-rec-info'>
                                <p>
                                    <span>by</span> <b>{book.author}</b><span> - released {book.publication_dates}</span>
                                </p>
                                <p className='book-rec-genre'>{book.genres}</p>
                            </div>
                            <p>{book.intro}</p>
                        </div>
                    ))}
                </div>)}
        </div >
    );
};

const bookGenres = [
    'Leadership',
    'Psychology',
    'Self-help',
    'Biography',
    'Business and Economics',
    'Science and Nature',
    'Religion and Spirituality',
    'Historical',
];

export default RecommendBook;