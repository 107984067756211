import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { backendURL, convertDate } from '../Utils/utils';
import './Podcast.css';
import { useNavigate } from 'react-router-dom';

// Podcast player with a list of podcasts for streaming.
const Player = (props) => {
    const [podcastList, setPodcastList] = useState([]);
    const [currentPlay, setCurrentPlay] = useState();
    const navigate = useNavigate();

    const getPodcasts = async () => {
        const response = await axios.get(`${backendURL}/get_podcasts/`);
        setPodcastList(response.data);

        const playid = props ? props.playid : 0;
        setCurrentPlay(playid);

        const audio = document.getElementById('my-audio');
        audio.pause();
        audio.removeAttribute('src');
        audio.load();
        audio.setAttribute('src', response.data[playid].url);
    };

    const changeAudioSrc = (idx) => {
        setCurrentPlay(idx);
        const audio = document.getElementById('my-audio');
        if (audio) {
            audio.pause();
            audio.removeAttribute('src');
            audio.load();
            audio.setAttribute('src', podcastList[idx].url);
            audio.play();
        }

        // updating url
        const parts = window.location.pathname.split('/');
        parts[parts.length - 1] = idx.toString();
        navigate(parts.join('/'));
    };

    useEffect(() => {
        getPodcasts();
    }, []);

    return (
        <div className={`podcast-player ${props.minimized && 'player-minimized'}`}>
            <div className='player-info-container'>
                <video
                    controls
                    crossOrigin='anonymous'
                    id='my-audio'
                >
                    <source type='audio/mp3'/>
                </video>
                {podcastList.length != 0 &&
                    <>
                        <h5>{podcastList[currentPlay].title}</h5>
                        <p>{podcastList[currentPlay].subtitle}</p>
                        <div className='current-play-info'>
                            <p>{convertDate(podcastList[currentPlay].date)}</p>
                            <p>S{podcastList[currentPlay].season} E{podcastList[currentPlay].episode}</p>
                        </div>
                    </>
                }
            </div>
            <div className='podcast-list-panel'>
                <h4>Available for streaming</h4>
                <div className='podcast-list-avail'>
                    {podcastList.map((item, idx) => {
                        return (
                            <button
                                key={idx}
                                onClick={() => {changeAudioSrc(idx);}}
                                className={`${(currentPlay === idx) ? 'current-play' : ''}`}
                            >
                                <div>
                                    <p><b>{item.title}</b>{(currentPlay === idx) && <span>playing</span>}</p>
                                    <p>S{item.season} E{item.episode}</p>
                                </div>
                                <p>{item.subtitle.substring(0, 150)}{(item.subtitle.length > 150) && '...'}</p>
                            </button>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

Player.propTypes = {
    minimized: PropTypes.bool,
    playid: PropTypes.number,
};
export default Player;