import { useEffect, useState } from 'react';
import Switch from './Switch';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getAuthenticationHeader, backendURL, logOut } from '../Utils/utils';
import '../Utils/common.css';

// Component for notification settings
const NotificationSetting = () => {
    const [settings, setSettings] = useState({
        rating_update: false,
        rating_comment: false,
        subscription_change: false,
        social_post_like: false,
        social_post_comment: false,
        newsletter: false,
    });

    useEffect(() => {
        fetchNotifSetting();
    }, []);

    const fetchNotifSetting = async () => {
        try {
            const config = getAuthenticationHeader();

            const response = await axios.get(`${backendURL}/setting/notification/`, config);

            if (response.status === 200) {
                setSettings(response.data);
            }

        } catch (err) {
            logOut();
            console.error(err);
        }
    };

    const updateNotifSetting = async () => {
        try {
            const config = getAuthenticationHeader();

            const response = await axios.patch(`${backendURL}/setting/notification/`, settings, config);

            if (response.status === 200) {
                toast.success('Changes saved!');
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            }

        } catch (err) {
            logOut();
            console.error(err);
        }
    };

    const handleSwitchChange = (status, setting) => {
        const settingType = setting;
        setSettings(oldSettings => ({
            ...oldSettings,
            [settingType]: status,
        }));
    };

    return (
        <div className="notification-settings">
            <h3>Notifications setting</h3>
            <p>Choose what notifications you would like to receive</p>

            <h4>Leadership rating page</h4>
            <div className='notif-setting-card'>
                <p>Updates to your leadership score</p>
                <Switch defaultChecked={settings.rating_update} onChange={(status) => handleSwitchChange(status, 'rating_update')} />
            </div>
            <div className='notif-setting-card'>
                <p>New comments to your leadership page</p>
                <Switch defaultChecked={settings.rating_post} onChange={(status) => handleSwitchChange(status, 'rating_post')} />
            </div>
            <div className='notif-setting-card'>
                <p>Replies to your rating comments</p>
                <Switch defaultChecked={settings.rating_comment} onChange={(status) => handleSwitchChange(status, 'rating_comment')} />
            </div>

            <h4>Subscription</h4>
            <div className='notif-setting-card'>
                <p>Subscription changes</p>
                <Switch defaultChecked={settings.subscription_change} onChange={(status) => handleSwitchChange(status, 'subscription_change')} />
            </div>

            <h4>Social post</h4>
            <div className='notif-setting-card'>
                <p>Social post likes</p>
                <Switch defaultChecked={settings.social_post_like} onChange={(status) => handleSwitchChange(status, 'social_post_like')} />
            </div>
            <div className='notif-setting-card'>
                <p>Social post replies</p>
                <Switch defaultChecked={settings.social_post_comment} onChange={(status) => handleSwitchChange(status, 'social_post_comment')} />
            </div>

            <h4>PplLdr newsletter</h4>
            <div className='notif-setting-card'>
                <p>Newsletter</p>
                <Switch defaultChecked={settings.newsletter} onChange={(status) => handleSwitchChange(status, 'newsletter')} />
            </div>
            <div className='settings-info'>
                <p>
                    When Notifications are turned on, you may choose to receive the ones relevant to you.
                </p>
            </div>
            <button className='setting-save-change-button theme-button' onClick={updateNotifSetting}>Save changes</button>
        </div>
    );
};

export default NotificationSetting;
