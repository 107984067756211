import { Link } from 'react-router-dom';

import './BottomNav.css';

// Render a bottom navigation bar with links to various pages and current year in the footer.
const BottomNav = () => {
    const currentYear = new Date().getFullYear();
    return (
        <nav className="bottom-nav">
            <div className="nav-items">
                <div className="nav-item">
                    <Link to="/aboutus">
                    About Us
                    </Link>
                </div>
                <div className="nav-item">
                    <Link to="/contactus">
                    Contact Us
                    </Link>
                </div>
                <div className="nav-item">
                    <Link to="/faq">
                    FAQ
                    </Link>
                </div>
                <div className="nav-item">
                    <Link to="/privacypolicy">
                    Privacy Policy
                    </Link>
                </div>
                <div className="nav-item">
                    <Link to="/termsandcondition">
                    Terms and Conditions
                    </Link>
                </div>
            </div>
            <div className="footer">
                <p className="copyright">
                    <Link to="/legal">
                © PplLdr LLC {currentYear}
                    </Link>
                </p>
            </div>
        </nav>
    );
};

export default BottomNav;
