import { useNavigate } from 'react-router-dom';
import default_pfp from '../../assets/default_pfp.png';
import PropTypes from 'prop-types';
import './UserThumbnail.css';

const UserThumbnail = (props) => {
    const navigate = useNavigate();
    const handleClick = () => {
        if (props.self) navigate('/profile');
        else navigate(`/profiles/${props.userProfile.first_name}-${props.userProfile.last_name}-${props.userProfile.id}`);
    };
    const bioLength = (props.style !== 'simplified') ? 100 : 50;

    const style = {
        width: props.style === 'simplified' ? '25px' : '40px',
        height: props.style === 'simplified' ? '25px' : '40px',
        borderRadius: '50%',
        marginRight: '10px',
        objectFit: 'cover',
        objectPosition: 'center',
    };

    return (
        <div className="post-card-user-field" onClick={handleClick}>
            <img
                style={style}
                src={props.userProfile.profile_image ? props.userProfile.profile_image : default_pfp}
                alt="thumbnail"
                onError={(e) => {
                    e.target.src = default_pfp;
                }}
            />

            <div>
                <h3>
                    {`${props.userProfile.first_name} ${props.userProfile.last_name}`}
                    {(props.style !== 'simplified') && props.userProfile.is_leader && <span className='leader-tag'>Leader</span>}
                </h3>
                <p>
                    {props.userProfile.bio.substring(0, bioLength)}
                    {(props.userProfile.bio.length > 100) && <>...</>}
                </p>
            </div>
        </div>
    );
};

UserThumbnail.propTypes = {
    self: PropTypes.bool,
    userProfile: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        id: PropTypes.number,
        profile_image: PropTypes.string,
        bio: PropTypes.string,
        is_leader: PropTypes.bool,
    }),
    style: PropTypes.string,
};

export default UserThumbnail;