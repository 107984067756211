import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { getAuthenticationHeader, backendURL } from '../Utils/utils';
import './ContactUs.css';
import '../Utils/common.css';


// Handling the contact form submission and rendering the contact form UI.
const ContactUs = () => {

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        topic: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
        agree: false,
    });

    const { topic, firstName, lastName, email, phone, message, agree } = formData;

    const handleChange = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setFormData({ ...formData, [e.target.name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const contactformData = new FormData();

            contactformData.append('email', email);
            contactformData.append('firstname', firstName);
            contactformData.append('lastname', lastName);
            contactformData.append('topic', topic);
            contactformData.append('phone', phone);
            contactformData.append('message', message);

            const config = getAuthenticationHeader('multipart/form-data');

            const response = await axios.post(`${backendURL}/contactusform/`, contactformData, config);

            localStorage.setItem('email', email);
            localStorage.setItem('firstname', firstName);
            localStorage.setItem('lastname', lastName);
            localStorage.setItem('topic', topic);
            localStorage.setItem('phone', phone);
            localStorage.setItem('message', message);

            if (response.data === 'success') navigate('/successContact');

        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="contact-us-container">
            <h1>Contact Us</h1>

            <p>
                PplLdr was founded in 2023 as a limited liability company (LLC) in Arkansas. It is a professional networking platform for people
                leaders to showcase their quality leadership skills assessed by their current and former
                direct reports / team members in an effort to attract top talent and mitigate the risks of
                voluntary resignations by associates due to toxic managers.
            </p>
            <p>
                For inquiries or requests that require a more personal response, we will make every attempt to respond in a timely manner.
            </p>

            <form className="contact-us-form" onSubmit={handleSubmit}>
                <p>Fields marked with an asterisk (*) are required.</p>
                <div className="edit-form-group">
                    <label htmlFor="topic">
                        Topic *
                    </label>
                    <select
                        name="topic"
                        value={topic}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select topic</option>
                        <option value="careers">Careers</option>
                        <option value="ethics">Ethics</option>
                        <option value="events">Events</option>
                        <option value="industries">Industries</option>
                        <option value="press">Press/Media relations</option>
                        <option value="privacy">Privacy Services</option>
                        <option value="feedback">Website feedback</option>
                        <option value="other">Other</option>
                    </select>
                </div>
                <div className='two-column-profile-edit name-row'>
                    <div className="edit-form-group">
                        <label htmlFor="firstName">First name *</label>
                        <input
                            type="text"
                            name="firstName"
                            value={firstName}
                            onChange={handleChange}
                            required
                            autoComplete='given-name'
                        />
                    </div>
                    <div className="edit-form-group">
                        <label htmlFor="lastName">Last name *</label>
                        <input
                            type="text"
                            name="lastName"
                            value={lastName}
                            onChange={handleChange}
                            required
                            autoComplete='family-name'
                        />
                    </div>
                </div>
                <div className="edit-form-group">
                    <label htmlFor="email">Email *</label>
                    <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        required
                        autoComplete='email'
                    />
                </div>

                <div className="edit-form-group">
                    <label htmlFor="phone">Phone</label>
                    <input
                        type="tel"
                        name="phone"
                        value={phone}
                        onChange={handleChange}
                        autoComplete='tel'
                    />
                </div>

                <div className="edit-form-group">
                    <label htmlFor="message">Message *</label>

                    <textarea
                        name="message"
                        value={message}
                        onChange={handleChange}
                        required
                        rows="10"
                        cols="50"
                        placeholder="Type your inquiry in this box."
                    ></textarea>
                </div>

                <div className="edit-form-group">
                    <label htmlFor="agree" className="checkbox-label">
                        <input
                            type="checkbox"
                            name="agree"
                            checked={agree}
                            onChange={handleChange}
                            required
                        />
                        <span>
                            I agree to receive emailed reports, articles, event invitations and other information related to PplLdr products and services.
                            I understand I may unsubscribe at any time by clicking the link included in emails.
                        </span>
                    </label>
                </div>

                <div className="edit-form-group">
                    <button className="theme-button" type="submit">Submit</button>
                </div>
            </form>
        </div>
    );
};

export default ContactUs;
