import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import './UserResult.css';
import default_pfp from '../../assets/default_pfp.png';

// Component with leader's information and score
const UserResult = (props) => {
    const navigate = useNavigate();
    const ratingScore = (e) => {
        props.handleClose();
        if (props.leader.score == -1) {
            return;
        }

        e.stopPropagation();
        navigate(`/user/ratingscore/${props.leader.first_name}-${props.leader.last_name}-${props.leader.id}`);
    };

    const toProfile = () => {
        props.handleClose();
        navigate(`/profiles/${props.leader.first_name}-${props.leader.last_name}-${props.leader.id}`);
    };

    const scoreStyle = {
        backgroundImage: `linear-gradient(rgba(0,0,0,0), ${leadershipClassificationColor[parseInt(props.leader.score)]})`,
        boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.3)',
    };

    return (
        <div className="leader-card" onClick={toProfile}>
            <div className="leader-score"
                style={(props.leader.score !== -1) ? scoreStyle : { 'backgroundImage': scoreStyle.backgroundImage }}
                onClick={ratingScore}
            >
                {(props.leader.score === -1) ? <p>...</p> : <h1>{props.leader.score}</h1>}
                <h5>{leadershipClassification[parseInt(props.leader.score)]}</h5>
                {(props.leader.score !== -1) && <p className="leader-result-view-score">view scores...</p>}
            </div>
            <div className="leader-info">
                <img
                    src={props.leader.profile_image ? props.leader.profile_image : default_pfp}
                    alt="pfp"
                    onError={(e) => {
                        e.target.src = default_pfp;
                    }}
                />
                <div>
                    <h3>{props.leader.first_name} {props.leader.last_name}</h3>
                    {props.leader.work &&
                        <p>{`
                            ${props.leader.work.company_name} \uFF5C
                            ${props.leader.work.title} \uFF5C
                            ${props.leader.bio.substring(0, 100)}${props.leader.bio.length > 100 ? '...' : ''}
                        `}</p>}
                </div>
            </div>
        </div>
    );
};

UserResult.propTypes = {
    leader: PropTypes.shape({
        id: PropTypes.number,
        score: PropTypes.number,
        profile_image: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        work: PropTypes.shape({
            company_name: PropTypes.string,
            title: PropTypes.string,
        }),
        bio: PropTypes.string,
    }),
    handleClose: PropTypes.func,
};

const leadershipClassification = {
    '10': 'Superior Leader',
    '9': 'Great Leader',
    '8': 'Good Leader',
    '7': 'Good Leader',
    '6': 'Average Leader',
    '5': 'Average Leader',
    '4': 'Supervisor',
    '3': 'Supervisor',
    '2': 'Manages Tasks',
    '1': 'Dud',
    '0': 'Dud',
};

const leadershipClassificationColor = {
    '10': 'rgba(0, 169, 207, 0.5)',
    '9': 'rgba(0, 207, 176, 0.5)',
    '8': 'rgba(45, 207, 0, 0.5)',
    '7': 'rgba(107, 207, 0, 0.5)',
    '6': 'rgba(173, 207, 0, 0.5)',
    '5': 'rgba(207, 204, 0, 0.5)',
    '4': 'rgba(207, 176, 0, 0.5)',
    '3': 'rgba(193, 128, 30, 0.5)',
    '2': 'rgba(173, 75, 0, 0.5)',
    '1': 'rgba(130, 0, 0, 0.5)',
    '0': 'rgba(100, 0, 0, 0.5)',
};

export default UserResult;