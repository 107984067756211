import { useState, useEffect } from 'react';
import axios from 'axios';
import './Subscription.css';
import { getAuthenticationHeader, backendURL } from '../Utils/utils';
import Loading from '../Utils/Loading';

// Component for subscription
const Subscription = () => {
    const [premium, setPremium] = useState([]);
    const [freePriceId, setFreePriceId] = useState();
    const [premiumPriceId, setPremiumPriceId] = useState();
    const [premium_txt, setPremium_txt] = useState([]);
    const [freeplan_txt, setFreeplan_txt] = useState([]);
    const [isLoading, setLoading] = useState(true);

    const currentplan = localStorage.getItem('currentplan');

    useEffect(() => {
        get_membership();
        get_membership_text();
    }, []);

    const get_membership = async () => {
        try {
            const config = getAuthenticationHeader();

            const free_response = await axios.get(`${backendURL}/membership/Free`, config);
            const free_price = free_response.data.filter((membership) => membership.default === true);
            setFreePriceId(free_price[0].stripe_id);

            // convert array to JSON string using JSON.stringify(),save to localStorage using "regulararray" as the key and jsonArray as the value
            localStorage.setItem('freearray', JSON.stringify(free_response.data));

            const pre_response = await axios.get(`${backendURL}/membership/Premium`, config);

            setPremium(pre_response.data);
            const pre_price = pre_response.data.filter((membership) => membership.default === true);
            setPremiumPriceId(pre_price[0].stripe_id);
            // convert array to JSON string using JSON.stringify(),save to localStorage using "premiumarray" as the key and jsonArray as the value
            localStorage.setItem('premiumarray', JSON.stringify(pre_response.data));

        } catch (error) {
            console.error(error);
        }
    };

    const get_membership_text = async () => {
        try {
            const config = getAuthenticationHeader();

            // get all membership benefit description
            const response = await axios.get(`${backendURL}/membership_txt`, config);
            const plan_txts = response.data;

            // filter membership benefits description by membership type
            const filteredFree = plan_txts.filter((membership) => membership.membership_type === 'Free');
            setFreeplan_txt(filteredFree);

            const filteredPremium = plan_txts.filter((membership) => membership.membership_type === 'Premium');
            setPremium_txt(filteredPremium);
            setLoading(false);

        } catch (error) {
            console.error(error);
        }
    };

    const subscribe = async (priceId, plan) => {

        const config = getAuthenticationHeader();

        const data = {
            priceId: priceId,
            plan: plan,
        };

        localStorage.setItem('plan', plan);

        if (!currentplan) {

            const response = await axios.post(`${backendURL}/stripe/checkout`, data, config);

            if (response.status === 201) {
                console.log('navigating to stripe checkout window');
                const stripe_url = response.data;
                window.location.replace(stripe_url);
            }
        }
        else {
            const response = await axios.post(`${backendURL}/stripe/update_subs`, data, config);

            if (response.status === 201) {
                console.log('navigating to stripe checkout window');
                const stripe_url = response.data;
                window.location.replace(stripe_url);
            }
        }

    };

    if (isLoading) {
        return <Loading message={'Loading subscription plans'}/>;
    }

    return (
        <div className='plans-container'>
            <div className='subscription-title'>
                <h3>Pricing Plan</h3>
                <h4>List of our pricing packages</h4>
            </div>
            <div className="plans-enroll-row">
                <div className="plan-desc">
                    <h3><i className="fas fa-rocket" />Trial Version</h3>
                    <div className='price-row'>
                        <h5>Free For 10 Directs Reports/Team Members</h5>
                    </div>
                    {/* loop to print the description item text for free plan  */}
                    {freeplan_txt.map((free_txt, index) => (
                        <p key={index}><i className="fa-solid fa-circle-check" style={{ color: '#4caf50' }}/>{free_txt.text}</p>
                    ))}
                    {(currentplan === 'Free') ? (<button className='current-plan-btn' disabled>Current Plan</button>)
                        : (<button type="submit" className='set-plan-button' onClick={() => subscribe(freePriceId, 'Free')}>Try for free</button>)}
                </div>
                <div className="plan-desc">
                    <h3><i className="far fa-gem" />Premium</h3>
                    {/* loop to display the premium plan price option(montnly, yearly.etc) in case if there is more than one */}
                    <div className="price-row">
                        {premium.map((plan, index) => (
                            <h5 key={index}>
                                <sup>$</sup>{plan.price} {plan.terms}
                            </h5>
                        ))}
                    </div>
                    {premium_txt.map((plan, index) => (
                        <p key={index}><i className="fa-solid fa-circle-check" style={{ color: '#4caf50' }}/>{plan.text}</p>
                    ))}
                    {(currentplan === 'Premium') ? (<button className='current-plan-btn'>Current Plan</button>)
                        : (<button type="submit" className='set-plan-button' onClick={() => subscribe(premiumPriceId, 'Premium')}>Get Started</button>)}
                </div>
            </div>
        </div >
    );
};

export default Subscription;