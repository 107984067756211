import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './RatingPosts.css';
import Posts from './Post';
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { backendURL, getAuthenticationHeader } from '../Utils/utils';

// Component for displaying posts for rating
const RatingPosts = (props) => {
    const [posts, setPosts] = useState([]);
    const [postData, setPostData] = useState('');
    const [likedPosts, setLikedPosts] = useState({});

    const dynamicPostRemove = (idx) => {
        const postList = [...posts.slice(0, idx), ...posts.slice(idx + 1)];
        setPosts(postList);
    };

    useEffect(() => {
        getLikedPosts();
        getPosts();
    }, []);

    const getPosts = async () => {
        const endPoint = props.user ? `${backendURL}/post_to_rating` : `${backendURL}/post_to_rating_visitor`;
        const params = {
            params: {
                id: props.leader.id,
                first_name: props.leader.first_name,
                last_name: props.leader.last_name,
            },
        };
        const config = props.user ? {
            ...getAuthenticationHeader(),
            ...params,
        } : {
            ...params,
        };
        try {
            const response = await axios.get(`${endPoint}`, config);
            setPosts(response.data);
        } catch (error) {
            console.error(error);
            setPosts(null);
        }
    };

    const getLikedPosts = async () => {
        if (!props.user) {
            return;
        }
        try {
            const config = getAuthenticationHeader();

            const response = await axios.get(`${backendURL}/like_posts_of_ratings`, config);
            setLikedPosts(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const onChange = (e) => {
        setPostData(e.target.value);
    };

    const handlePost = async (e) => {
        e.preventDefault();
        try {
            const config = getAuthenticationHeader();
            const data = {
                id: props.leader.id,
                first_name: props.leader.first_name,
                last_name: props.leader.last_name,
                content: postData,
            };

            const response = await axios.post(`${backendURL}/post_to_rating/`, data, config);
            if (response.status === 201 && response.data) {
                const postList = [response.data, ...posts];
                setPosts(postList);
                setPostData('');

                toast.success(`Successfully uploaded your post for ${props.leader.first_name} ${props.leader.last_name}`);
            }

        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className="posts">
            {props.user ?
                <div className='post-to-rating-comment'>
                    <form onSubmit={handlePost}>
                        <textarea
                            placeholder="Have a comment about this leader?"
                            rows={5}
                            name="rating-post"
                            value={postData}
                            onChange={(e) => onChange(e)}
                        />
                        <button type="submit" className='theme-button'>Post</button>
                    </form>
                </div>
                :
                <div className='login-wall'>
                    <h4>You are signed out</h4>
                    <p>
                        Sign in to comment on {props.leader.first_name} {props.leader.last_name}&apos;s
                        leadership, view their profile, and interact with other users
                    </p>
                    <Link to="/" className="theme-button to-sign-in">
                        <p>Sign in</p>
                    </Link>
                    <Link to="/register">
                        <p>New to PplLdr? Join us now!</p>
                    </Link>
                </div>
            }
            {posts.map((post, idx) => {
                return (
                    <Posts
                        post={post}
                        key={post.id}
                        userId={(props.user) ? props.user.id : null}
                        activeLike={likedPosts[post.id] ? true : false}
                        idx={idx}
                        createdAt={new Date(post.created_at)}
                        dynamicPostRemoveCallBack={dynamicPostRemove}
                        highlighted={post.id === props.hilightPostId}
                    />
                );
            })}
        </div>
    );
};

RatingPosts.propTypes = {
    leader: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        id: PropTypes.number,
    }),
    user: PropTypes.object,
    hilightPostId: PropTypes.number,
};

export default RatingPosts;