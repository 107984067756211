import { useState, useEffect } from 'react';
import './Switch.css';
import PropTypes from 'prop-types';

// Component for switch
const Switch = ({ onChange, defaultChecked }) => {
    // const Switch = () => {
    const [isOn, setIsOn] = useState(defaultChecked || false);

    // Update state when defaultChecked prop changes
    useEffect(() => {
        setIsOn(defaultChecked || false);
    }, [defaultChecked]);

    const handleToggle = () => {
        let newVal;
        if (!isOn) {
            newVal = true;
        } else {
            newVal = false;
        }
        setIsOn(newVal);
        if (onChange) {
            onChange(newVal);
        }
    };

    return (
        <div className={`switch-container ${isOn ? 'on' : 'off'}`} onClick={handleToggle}>
            <div className={`switch-button ${isOn ? 'on' : 'off'}`} />
        </div>
    );
};

// Prop types validation
Switch.propTypes = {
    onChange: PropTypes.func,
    defaultChecked: PropTypes.bool,
};

export default Switch;