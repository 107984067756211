// src/components/ViewUsers.js

import { useState, useEffect } from 'react';
import axios from 'axios';

import { backendURL } from '../Utils/utils';
import '../../App.css';

// View a list of users and their details.
const ViewUsers = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
    // Fetch user data from the backend API
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${backendURL}/users/`);
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUsers();
    }, []);

    return (
        <div className="user-details-container">
            <h2>User Details</h2>
            <table className="user-table">
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>Email</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                        <tr key={user.id}>
                            <td>{user.username}</td>
                            <td>{user.email}</td>
                            <td>{user.first_name}</td>
                            <td>{user.last_name}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ViewUsers;
