import { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Form, Button } from 'react-bootstrap';

import { getAuthenticationHeader, backendURL } from '../Utils/utils';
import './MyTeamMembers.css';
import PropTypes from 'prop-types';

// Component for my teams
const Teams = ({ onSelectTeam }) => {
    const [isAddTeamModalOpen, setIsAddTeamModalOpen] = useState(false);
    const [isEditTeamMode, setIsEditTeamMode] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState({});
    const [isEditTeamModalOpen, setIsEditTeamModalOpen] = useState(false);
    const [delTeamConfopen, setDelTeamConfopen] = useState(false);
    const [companyName, setCompanyName] = useState();
    const [teamName, setTeamName] = useState('');
    const [workExperiences, setWorkExperiences] = useState([]);
    const [selectedWorkExperience, setSelectedWorkExperience] = useState('');
    const [fetchedTeamNames, setFetchedTeamNames] = useState(null);
    const [counter, setcounter] = useState(1);
    const [message, setMessage] = useState();
    const [showmsg, setshowmsg] = useState(false);

    const handleClose = () => setIsAddTeamModalOpen(false);

    // Function to fetch work experiences of the user
    const fetchWorkExperienceList = async () => {
        try {
            const config = getAuthenticationHeader();
            const response = await axios.get(`${backendURL}/work_experience/`, config);
            setWorkExperiences(response.data || []);
        } catch (err) {
            console.error(err);
        }
    };

    // Function to fetch team names from the backend
    const fetchTeamNames = async () => {
        try {
            const config = getAuthenticationHeader();
            const response = await axios.get(`${backendURL}/get-team-name/`, config);
            setFetchedTeamNames(response.data || []);
        } catch (err) {
            console.error('Error fetching team names:', err);
            return [];
        }
    };

    // Function to close the Add Team modal
    const onCloseAddTeamModal = () => {
        setIsAddTeamModalOpen(false);
        setTeamName('');
    };

    // Function to open the Add Team modal
    const onOpenAddTeamModal = () => setIsAddTeamModalOpen(true);

    // Function to open the Edit Team modal
    const onEditTeamMode = () => {
        if (isEditTeamMode) setIsEditTeamMode(false);
        else setIsEditTeamMode(true);
    };

    // Function to add a new team
    const addTeam = async () => {
        try {
            const config = getAuthenticationHeader();

            const requestData = {
                team_name: teamName,
                experience: selectedWorkExperience,
                company_name: companyName,
            };
            await axios.post(`${backendURL}/add-team/`, requestData, config);
            setcounter(counter + 1);
            onCloseAddTeamModal();
        } catch (err) {
            console.error(err);
        }
    };

    // Function to handle adding a new team
    const handleAddTeam = () => {
        onCloseAddTeamModal();
        addTeam();
    };

    const handleDelTeam = (team) => {
        setDelTeamConfopen(true);
        setSelectedTeam(team);
    };

    const deleteTeam = async (team) => {
        try {
            const config = getAuthenticationHeader();
            const requestData = {
                team_id: team.id,
            };

            const response = await axios.delete(`${backendURL}/delete_team/`, { ...config, data: requestData });

            if (response.status === 204) {
                setDelTeamConfopen(false);
                setcounter(counter + 1);
                setMessage(`Team ${team.team_name} got removed sucessfully`);
                setshowmsg(true);
            }

        } catch (err) {
            console.error(err);
        }
    };

    const handleEditTeam = (team) => {
        setSelectedTeam(team);
        setIsEditTeamModalOpen(true);
        const experience = workExperiences.filter(workExp => workExp.company_name === team.company_name);
        if (experience.length > 0) setSelectedWorkExperience(experience[0].id);
        else setSelectedWorkExperience('Others');
    };

    const handleEditTeamClose = () => setIsEditTeamModalOpen(false);

    const submitTeamChange = async () => {
        try {
            const config = getAuthenticationHeader();
            const requestData = {
                team_name: teamName,
                team_id: selectedTeam.id,
                experience: selectedWorkExperience,
                company_name: companyName,
            };
            const response = await axios.put(`${backendURL}/change_team/`, requestData, config);

            if (response.status === 200) {
                setcounter(counter + 1);
                handleEditTeamClose();
            }
        } catch (err) {
            console.error(err);
        }
    };

    const handleclosemsg = () => setshowmsg(false);
    const handleDTClose = () => setDelTeamConfopen(false);

    const handlememberdisplay = (team) => {
        onSelectTeam(team, fetchedTeamNames);
    };

    useEffect(() => {
        fetchWorkExperienceList();
        fetchTeamNames();
    }, [counter]);

    if (!fetchedTeamNames) {
        return <div className="notif-homebody">Loading...</div>;
    }

    return (
        <div className="my-team-homebody">
            <div className='myteam-head'>
                <h3>My Teams</h3>
            </div>
            <div className="team-edit-btn-grp">
                <button onClick={onOpenAddTeamModal}><i className="fa-solid fa-plus fa-lg" /> Add New Team</button>
                <button onClick={onEditTeamMode}><i className="fa-solid fa-pen-to-square fa-lg" /> Edit Team</button>
            </div>

            <div className="team-card-container">
                {fetchedTeamNames && fetchedTeamNames.map((fethcedTeamName) => (
                    <div key={fethcedTeamName.id} className="team-post-card">
                        {isEditTeamMode && (<div className="team-top-bar">
                            <i className="fa-solid fa-pen edit-btn" onClick={() => handleEditTeam(fethcedTeamName)}></i>
                            <i className="fa-solid fa-trash del-btn" onClick={() => handleDelTeam(fethcedTeamName)}></i>
                        </div>)}
                        <div className="team-head-info">
                            <div className='team-name'>
                                <h4>{fethcedTeamName.team_name}</h4>
                            </div>
                            <h5>{fethcedTeamName.company_name}</h5>
                        </div>
                        <div className="team-footer-bar">
                            <button className="theme-button" onClick={() => handlememberdisplay(fethcedTeamName)}>View Team Members</button>
                        </div>
                    </div>
                ))}
            </div>

            <Modal
                show={isAddTeamModalOpen}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                dialogClassname="team-modal"
                centered
                className="myteams-modal edit-form-group"
            >
                <Modal.Header closeButton>
                    <Modal.Title><h3>Information</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>To become a leader, please add your teammates and invite them to rate your questionnaire</p>

                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Team Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Team Name"
                                onChange={(e) => setTeamName(e.target.value)}
                                autoFocus
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>select company</Form.Label>
                            <Form.Select aria-label="Default select example" onChange={(e) => setSelectedWorkExperience(e.target.value)} value={selectedWorkExperience}>
                                <option value="" disabled>
                                    Choose an option
                                </option>
                                {workExperiences.map((experience) => (
                                    <option key={experience.id} value={experience.id}>
                                        {experience.company_name} - {experience.title}
                                    </option>
                                ))}
                                <option>
                                    Others
                                </option>
                            </Form.Select>
                        </Form.Group>
                        {(selectedWorkExperience === 'Others') && (<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Company Name"
                                onChange={(e) => setCompanyName(e.target.value)}
                                autoFocus
                            />
                        </Form.Group>)}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleAddTeam}>
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={isEditTeamModalOpen}
                onHide={handleEditTeamClose}
                backdrop="static"
                keyboard={false}
                centered
                className="myteams-modal edit-form-group"
            >
                <Modal.Header closeButton>
                    <Modal.Title><h3>Edit Team Information</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Team Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Team Name"
                                defaultValue={selectedTeam.team_name}
                                onChange={(e) => setTeamName(e.target.value)}
                                autoFocus
                            />
                        </Form.Group>

                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>select company</Form.Label>
                            <Form.Select aria-label="Default select example" onChange={(e) => setSelectedWorkExperience(e.target.value)} value={selectedWorkExperience}>
                                {workExperiences.map((experience) => (
                                    <option key={experience.id} value={experience.id} defaultValue={selectedWorkExperience}>
                                        {experience.company_name} - {experience.title}
                                    </option>
                                ))}
                                <option>
                                    Others
                                </option>
                            </Form.Select>
                        </Form.Group>
                        {(selectedWorkExperience === 'Others') && (<Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Company Name"
                                defaultValue={selectedTeam.company_name}
                                onChange={(e) => setCompanyName(e.target.value)}
                                autoFocus
                            />
                        </Form.Group>)}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleEditTeamClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={submitTeamChange}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={delTeamConfopen}
                onHide={handleDTClose}
                centered
                className="myteams-modal  edit-form-group"
            >
                <Modal.Header closeButton>
                    <Modal.Title><h3>Information</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure to delete this team ?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDTClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => deleteTeam(selectedTeam)}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* pop up message */}
            <Modal onHide={handleclosemsg}
                show={showmsg}
                centered
                className="myteams-modal  edit-form-group"
            >
                <Modal.Header closeButton>
                    <Modal.Title><h3>Information</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{message}</p>
                </Modal.Body>
            </Modal>
        </div>
    );
};
Teams.propTypes = {
    onSelectTeam: PropTypes.func.isRequired,
};

export default Teams;
