import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { backendURL, getAuthenticationHeader, logOut } from '../Utils/utils';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import '../Utils/common.css';
import './Setting.css';
import Switch from './Switch';

import axios from 'axios';
import Loading from '../Utils/Loading';

// Component for privacy settings
const PrivacySetting = () => {
    const [settings, setSettings] = useState({
        show_email: false,
        show_phone: false,
        show_address: false,
    });
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [statusMessage, setStatusMessage] = useState('Deleting your account');
    const config = getAuthenticationHeader();

    const navigate = useNavigate();

    const fetchSettings = async () => {
        try {
            const response = await axios.get(`${backendURL}/setting/privacy/`, config);
            setSettings(response.data);
        } catch (e) {
            logOut();
            console.error(e);
        }
    };

    const handleSwitchChange = (status, setting) => {
        const settingType = setting;
        setSettings(oldSettings => ({
            ...oldSettings,
            [settingType]: status,
        }));
    };

    const saveSettings = async () => {
        try {
            const response = await axios.patch(`${backendURL}/setting/privacy/`, settings, config);

            if (response.status === 202)
            {
                toast.success('Changes saved!');
            }
        } catch (e) {
            logOut();
            console.error(e);
        }
    };

    const deleteAccount = async () => {
        try {
            setDeleting(true);
            const response = await axios.delete(`${backendURL}/user/remove_user`, config);

            if (response.status === 200) {
                setStatusMessage('Successfully deleted your account, please check your inbox for confirmation');
                setTimeout(() => {
                    navigate('/');
                    logOut();
                }, 5000);
            }
        } catch (e) {
            logOut();
            console.error(e);
        }
    };

    useEffect(() => {
        fetchSettings();
    }, []);

    if (deleting) {
        return <Loading message={statusMessage} />;
    }

    return (
        <div className="security-settings">
            <h3>Privacy setting</h3>
            <p>Change your privacy preferences</p>

            <h4>Personal information</h4>
            <div className='privacy-setting-card'>
                <p>Make your email publicly viewable</p>
                <Switch defaultChecked={settings.show_email} onChange={(status) => handleSwitchChange(status, 'show_email')} />
            </div>
            <div className='privacy-setting-card'>
                <p>Make your phone number publicly viewable</p>
                <Switch defaultChecked={settings.show_phone} onChange={(status) => handleSwitchChange(status, 'show_phone')} />
            </div>
            <div className='privacy-setting-card'>
                <p>Make your location publicly viewable</p>
                <Switch defaultChecked={settings.show_address} onChange={(status) => handleSwitchChange(status, 'show_address')} />
            </div>
            <div className='settings-info'>
                <p>
                    Other users will not see infomations you disabled.
                </p>
            </div>
            <button className='setting-save-change-button theme-button' onClick={saveSettings}>
                Save changes
            </button>

            <h4>Account actions</h4>
            <div className='privacy-setting-card'>
                <p>Permanently delete you account</p>
                <button
                    className='theme-button delete-account-btn'
                    onClick={() => {
                        setShowWarningModal(true);
                    }}
                >
                    Delete account
                </button>
            </div>

            <Modal
                show={showWarningModal}
                centered
                onHide={() => {setShowWarningModal(false);}}
            >
                <Modal.Header closeButton>
                    <h3>Account deletion warning</h3>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        By deleting your account, all your infomations provided to PplLdr will be deleted. This includes but not limited to
                        your phone number, email, name, experience, and credit / debit card information.
                    </p>
                    <br/>
                    <p>
                        This operation cannot be undone, meaning your information cannot be retrieved
                        by any means. <b>Are you sure you want to delete your account?</b>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className='theme-button delete-account-btn'
                        onClick={deleteAccount}
                    >
                        Yes, I want to permanently delete my account
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default PrivacySetting;