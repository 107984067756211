import { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

import GoogleLoginModule from './GoogleLoginModule';
import { LinkedinLoginModule } from './LinkedinLoginModule';
import Loading from '../Utils/Loading';
import { backendURL } from '../Utils/utils';
import 'react-toastify/dist/ReactToastify.css';
import './LandingPage.css';

// Replace this with the path to your professional network image
import ProfessionalNetworkImage from '../../assets/PplLdr_mainlogobanner.png';

// component for the landing page of the application.
const LandingPage = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const [errorField, setErrorField] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const next_url = location.state?.next_url;

    const { email, password } = formData;
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        // Clear the error message when the user retypes
        setErrorField(null);
    };

    const handleLogin = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        try {
            const response = await axios.post(`${backendURL}/login/`, formData);
            const { token } = response.data;

            // Store the token in localStorage for later use
            localStorage.setItem('token', token);
            window.dispatchEvent(new Event('storage'));

            // Clear the form data after successful login
            setFormData({ email: '', password: '' });

            // Show toaster notification for successful login
            toast.success('Login successful!');
            // Redirect the user to the profile page after successful login
            setTimeout(() => {
                if (next_url) navigate(next_url);
                else navigate('/createpost');
            }, 3000);
        } catch (err) {
            console.error(err);

            if (err.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
                if (err.response.status === 401) {
                    // Unauthorized (wrong credentials)
                    setErrorField('password');
                } else if (err.response.status === 404) {
                    // Not Found (user does not exist)
                    setErrorField('email');
                } else {
                    setErrorField(null);
                }
            } else if (err.request) {
            // The request was made but no response was received
                setErrorField(null);
            } else {
            // Something happened in setting up the request that triggered an Error
                setErrorField(null);
            }
        }
        setIsLoading(false);
    };

    const handleLogin_thirdParty = async (ptoken, platform) => {
        setIsLoading(true);
        try {
            let config;
            let platform_route;
            if (platform === 'google') {
                config = {
                    headers: {
                        google_token: ptoken,
                    },
                };
                platform_route = 'login_g';
            }
            else if (platform === 'linkedin') {
                config = {
                    headers: {
                        linkedin_token: ptoken,
                    },
                };
                platform_route = 'login_l';
            }

            const response = await axios.post(`${backendURL}/${platform_route}/`, config);
            const { token, sub, linkedin_token } = response.data;

            // Store the token in localStorage for later use
            localStorage.setItem('token', token);
            localStorage.setItem('sub', sub);
            localStorage.setItem('linkedin_token', linkedin_token);
            window.dispatchEvent(new Event('storage'));

            // Clear the form data after successful login
            setFormData({ email: '', password: '' });

            // Show toaster notification for successful login
            toast.success('Login successful!');
            setTimeout(() => {
                navigate('/createpost');
            }, 3000);
        } catch (err) {
            console.error(err);

            if (err.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (err.response.status === 401) {
                // Unauthorized (expired credentials)
                    toast.error('Stale request. Refreshing page');
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                } else if (err.response.status === 404) {
                // Not Found (user does not exist)
                    toast.info('Account not found. Please register your account first!');
                } else {
                    setErrorField(null);
                }
            } else if (err.request) {
                // The request was made but no response was received
                setErrorField(null);
            } else {
                // Something happened in setting up the request that triggered an Error
                setErrorField(null);
            }
        }
        setIsLoading(false);
    };

    const handleLogin_google = (token) => {
        handleLogin_thirdParty(token, 'google');
    };

    const handleLogin_linkedin = (token) => {
        handleLogin_thirdParty(token, 'linkedin');
    };

    useEffect(() => {
        if (localStorage.getItem('token')) {
            navigate('/createPost');
        }
    });

    if (isLoading) {
        return <Loading message='Logging You In'/>;
    }

    return (
        <div className="landing-container">
            <div id="one-tap-container"
                style={{ position:'fixed', right:'0px', top:'10vh', zIndex: '100' }}>
            </div>

            <div className="landing-content">

                <h1 className="landing-header">Welcome to the Professional Network</h1>
                <p className="landing-subtext">Connect and grow with professionals around the world.</p>

                {/* Use the existing login form style */}
                <div className="login-section">
                    <h2>Login to Your Account</h2>
                    <form onSubmit={handleLogin}>
                        <div className={`form-group-landing ${errorField === 'email' ? 'error' : ''}`}>
                            <label>Email:</label>
                            <input
                                type="email"
                                name="email"
                                value={email}
                                onChange={handleChange}
                                placeholder="Enter your email"
                                required
                            />
                            {errorField === 'email' && (
                                <span className="error-message">Invalid email or user does not exist</span>
                            )}
                        </div>
                        <div className={`form-group-landing ${errorField === 'password' ? 'error' : ''}`}>
                            <label>Password:</label>
                            <div className="password-input-container">
                                <input
                                    type="password"
                                    name="password"
                                    value={password}
                                    onChange={handleChange}
                                    placeholder="Enter your password"
                                    required
                                />
                            </div>
                            {errorField === 'password' && (
                                <span className="error-message">Invalid password</span>
                            )}
                        </div>
                        <div className="password-pass">
                            <button type="submit" className="login-button theme-button">
                                Sign In
                            </button>
                            <Link to="/forgot-password">
                                <p>Forgot your password?</p>
                            </Link>
                        </div>
                    </form>

                    <div id="third-party-login-container">
                        <GoogleLoginModule
                            handlerFunction={handleLogin_google}
                            useOneTap={true}
                            oneTapParentId="one-tap-container"/>

                        <LinkedinLoginModule handleLogin={handleLogin_linkedin}/>
                    </div>

                    <Link to="/register" className="register-link-btn">
                        <p>New to PplLdr? Join us now!</p>
                    </Link>
                </div>
            </div>

            <img className="landing-image" src={ProfessionalNetworkImage} alt="Professional Network" />
        </div>
    );
};

export default LandingPage;