import FaqItem from './FaqItem';
import './Faq.css';
import { frontendURL } from '../Utils/utils';

// component with a list of frequently asked questions.
const Faq = () => {
    return (
        <div className="faq-page-container">
            <div className="faq-items-container">
                {questionAnswer.map((item, index) => {
                    return (
                        <FaqItem
                            question={item.question}
                            answer={item.answer}
                            links={item.links ? item.links : null}
                            key={`faq-item${index}`}
                            id={`faq-item${index}`}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default Faq;

const questionAnswer = [
    {
        question: 'Why do I need to use PplLdr (People Leader)?',
        answer: 'Quality people leaders need to show top talent, recruiters, and their organizations that they possess the skill sets needed in leading the most important resource: people. Our product gives these quality people leaders an edge over their competition - with a tight labor market, you need this product to distinguish yourself to attract the best talent available to you.',
    },
    {
        question: 'Can I have more than 10 direct reports / team members rate me?',
        answer: 'Yes - we encourage you to invite as many current and prior direct reports / team members to show that more than just a handful have rated you: the larger number of ratings across your entire work histories, the more credibility you have as a quality people leader since it shows a pattern of consistency on each rated attribute.',
    },
    {
        question: 'Why do I need to rate myself across the leadership attributes?',
        answer: 'Completing a self-rating enables you to see if your perceptions of your leadership skills are in-line with the ratings provided by your current and past direct reports / team members. This is an important part of leadership – self-awareness and lifelong learning. Significant differences in your self-ratings compared to your direct reports\' / team members\' ratings of you could be a red flag for a tendency to ignore or down-play feedback which ultimately helps develop you: quality people leaders seek feedback for self-improvement.',
    },
    {
        question: 'What can I do to increase my quality people leading skills?',
        answer: 'For our Premium subscribers, we are constantly updating our leadership content with links to courses, suggested reading lists, guest contributors, consultants, partnerships with podcast collaborators, and content from across our partners. We are passionate about contributing to your learning, development, and growth as a quality people leader. And you can display your ratings using our link to other professional networking and social media accounts by displaying your badges that attest to your quality people leading skills.',
    },
    {
        question: 'Can I cancel my Premium subscription?',
        answer: 'Yes. To modify your membership, be sure to log in then go to:',
        links: [`${frontendURL}/plan/manageplan`],
    },
    {
        question: 'I want to change my privacy and security preferences',
        answer: 'To customize your settings, be sure to log in then go to:',
        links: [`${frontendURL}/setting`],
    },
];