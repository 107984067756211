import { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Form, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getAuthenticationHeader, backendURL } from '../Utils/utils';
import validator from 'email-validator';
import './MyTeamMembers.css';
import '../Utils/common.css';
import { useNavigate } from 'react-router-dom';

// Component for my team members
const TeamMembers = ({ selectedTeam, onSelectTeam, userProfile, teamList }) => {
    const [selectedTeammember, setSelectedTeammeber] = useState({});
    const [isEditTMOpen, setIsEditTMOpen] = useState(false);
    const [teammatelist, setTeammateList] = useState([]);
    const [allTeammates, setAllTeammates] = useState([]);
    const [teamMemberData, setteamMemberData] = useState([]);
    const [isAddTMOpen, setisAddTMOpen] = useState();
    const [isEditTMMode, setIsEditTMMode] = useState();
    const [counter, setcounter] = useState(1);
    const [deleteConfOpen, setDeleteConfOpen] = useState();
    const [memberToDel, setMemberToDel] = useState([]);
    const [message, setMessage] = useState();
    const [showMsg, setShowMsg] = useState(false);
    const currrentteam = selectedTeam;
    const [validErrors, setValidErrors] = useState({});
    const [currentPlan, setCurrentPlan] = useState();
    const [notMatchedMembership, setNotMatchedMembership] = useState(false);
    const [hideInfoBtn, setHideInfoBtn] = useState(true);
    const navigate = useNavigate();

    // display the popup window to add new teammbers
    const addTeammates = () => {
        const maxMembers = validateTeammemberCount();
        if (!maxMembers) setisAddTMOpen(true);
    };

    const handleTMClose = () => {
        setValidErrors({});
        setisAddTMOpen(false);
    };

    // Function to create a teammate
    const createTeammate = async () => {
        // if any validation error happens, then stop the submit
        const inValidData = validateMemberData();
        if (inValidData) return;

        try {
            const config = getAuthenticationHeader();

            const requestData = {
                team_id: currrentteam.id,
                first_name: teamMemberData.firstname,
                middle_name: teamMemberData.middlename,
                last_name: teamMemberData.lastname,
                email: teamMemberData.email,
            };

            const response = await axios.post(`${backendURL}/teammates/`, requestData, config);

            // if creation is successful
            if (response.status === 201) {
                setValidErrors({});
                setteamMemberData([]);
                setcounter(counter + 1);
                handleTMClose();
            }
        } catch (err) {
            console.error(err);
        }
    };

    // Function to delete a temmate
    const deleteTeammate = async (param) => {
        try {
            const config = getAuthenticationHeader();

            const requestData = {
                teammate_id: param.id,
            };

            const response = await axios.delete(`${backendURL}/teammates/`, { ...config, data: requestData });

            // if deletion is successful
            if (response.status === 204) {
                setcounter(counter + 1);
                setDeleteConfOpen(false);
            }

        } catch (err) {
            console.error(err);
        }
    };


    // Function to get teammates
    const fetchTeammate = async () => {
        try {
            const config = getAuthenticationHeader();
            const response = await axios.get(`${backendURL}/teammates/`, config);
            if (response.status === 200) {
                setAllTeammates(response.data || []);
                const unDeletedTeammates = response.data.filter(teammate => teammate.isdeleted === false);
                setTeammateList(unDeletedTeammates);
            }

        } catch (err) {
            console.error(err);
        }
    };

    const sendRatingInvite = async (param) => {
        try {
            const config = getAuthenticationHeader();

            const requestData = {
                teammember_email: param.email,
                teammember_firstname: param.first_name,
                teammember_lastname: param.last_name,
                team_name_id: param.team_name_id,
            };

            const response = await axios.post(`${backendURL}/send_ratinginvite/`, requestData, config);

            if (response.status === 200) {
                setcounter(counter + 1);
                setMessage(`Rating invitation has been sent to ${param.first_name} ${param.middle_name ? param.middle_name : ''} ${param.last_name}`);
                setShowMsg(true);
            }

        } catch (err) {
            console.error(err);
        }
    };

    const handleEditMember = (teammember) => {
        setSelectedTeammeber(teammember);
        setIsEditTMOpen(true);
    };

    const handleEditTMClose = () => setIsEditTMOpen(false);

    const submitTMchange = async () => {
        // if any validation error happens, then stop the submit
        const inValidData = validateMemberData();
        if (inValidData) return;

        try {
            const config = getAuthenticationHeader();

            const requestData = {
                teammember_id: selectedTeammember.id,
                team_name_id: teamMemberData.teamname ? teamMemberData.teamname : selectedTeammember.team_name_id,
                first_name: teamMemberData.firstname ? teamMemberData.firstname : selectedTeammember.first_name,
                middle_name: teamMemberData.middlename ? teamMemberData.middlename : selectedTeammember.middle_name,
                last_name: teamMemberData.lastname ? teamMemberData.lastname : selectedTeammember.last_name,
                email: teamMemberData.email ? teamMemberData.email : selectedTeammember.email,
            };

            const response = await axios.put(`${backendURL}/teammates/`, requestData, config);

            // if change is successful
            if (response.status === 200) {
                setValidErrors({});
                setteamMemberData([]);
                setcounter(counter + 1);
                handleEditTMClose();
            }
        } catch (err) {
            console.error(err);
        }
    };

    const handleCloseMsg = () => setShowMsg(false);

    const newMemberData = (e) => {

        // Validate the form fields
        const errors = {};

        if (e.target.name === 'email') {
            const validEmail = validator.validate(e.target.value);
            if (!validEmail) {
                errors.email = 'Please enter correct email address';
                setValidErrors(errors);
                return;
            }
            else {
                setValidErrors({});
            }
            if (e.target.value === userProfile.email) {
                errors.email = 'You cannot add yourself as a team member.';
                setValidErrors(errors);
                return;
            }
        }

        setteamMemberData({
            ...teamMemberData,
            [e.target.name]: e.target.value,
        });
    };

    const validateMemberData = () => {
        // Validate the form fields
        const validDataErrors = {};

        // add new team members mode
        if (isAddTMOpen) {
            if (!teamMemberData.email?.trim()) {
                validDataErrors.email = 'Please enter email address';
            }
            else {
                const validEmail = teammatelist.filter(teammate => teammate.email === teamMemberData.email);
                if (validEmail?.length > 0) {
                    validDataErrors.email = 'The email already exists!';
                }
            }

            if (!teamMemberData.firstname?.trim()) {
                validDataErrors.firstname = 'Please enter firstname';
            }

            if (!teamMemberData.lastname?.trim()) {
                validDataErrors.lastname = 'Please enter lastname';
            }
        }

        // edit existing teammembers mode
        if (isEditTMOpen) {
            const email = teamMemberData.email?.trim();
            if (email?.length === 0) {
                validDataErrors.email = 'Please enter email address';
            }
            else {
                const validEmail = teammatelist.filter(teammate => teammate.email === email);
                if (validEmail?.length > 0) {
                    validDataErrors.email = 'The email already exists!';
                }
            }

            const firstName = teamMemberData.firstname?.trim();
            if (firstName?.length === 0) {
                validDataErrors.firstname = 'Please enter firstname';
            }

            const lastName = teamMemberData.lastname?.trim();
            if (lastName?.length === 0) {
                validDataErrors.lastname = 'Please enter lastname';
            }
        }

        if (Object.keys(validDataErrors).length > 0) {
            setValidErrors(validDataErrors);
            return true;
        }
        else {
            setValidErrors({});
            return false;
        }
    };

    const handleDelMember = (teammember) => {
        setDeleteConfOpen(true);
        setMemberToDel(teammember);
    };

    const onEditTeamMemberMode = () => {
        if (isEditTMMode) setIsEditTMMode(false);
        else setIsEditTMMode(true);
    };

    const handleDMClose = () => setDeleteConfOpen(false);

    // get user current membership plan
    const getUserMembership = async () => {
        try {
            const config = getAuthenticationHeader();
            const response = await axios.get(`${backendURL}/usermembership/`, config);
            setCurrentPlan(response.data);
            localStorage.setItem('currentplan', response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const validateTeammemberCount = () => {
        // if user membership plan is 'free', and already added 10 members, then suggest user to upgrade plan
        if (currentPlan === 'Free' && allTeammates.length >= 10) {
            setNotMatchedMembership(true);
            setMessage('You have reached the limit of 10 team members. To upgrade to Premium Plan, click "View plans"');
            setShowMsg(true);
            setHideInfoBtn(false);
            return true;
        }
        // if user has no plan, suggest user to enroll
        if (currentPlan === '') {
            setNotMatchedMembership(true);
            setMessage('You have not enrolled any plan. To see available subscription plans, click "View plans"');
            setShowMsg(true);
            setHideInfoBtn(false);
            return true;
        }
    };

    const handleYesMsg = () => {
        if (notMatchedMembership) {
            navigate('/plan/subscription');
        }
    };

    useEffect(() => {
        getUserMembership();
        fetchTeammate();
    }, [counter]);

    return (
        <div className="my-team-homebody">
            <button className="teammember-btn" onClick={() => onSelectTeam(null)}><i className="fa-solid fa-arrow-left" /></button>
            <div className='myteam-head'>
                <h3>My Direct Reports / Team Members in <strong> {currrentteam.team_name}- {currrentteam.company_name}</strong></h3>
            </div>
            <div className='teammember-edit-btn-grp'>
                <button onClick={() => addTeammates()}><i className="fa-solid fa-plus" />Add Team Members</button>
                <button onClick={onEditTeamMemberMode}><i className="fa-solid fa-pen-to-square fa-lg" /> Edit Team Members</button>
            </div>

            {/* Use the logic here below (Display Posts) to Display Teams in Cards */}
            <div className="teammember-card-container ">
                {teammatelist.filter(teammate => teammate.team_name_id === currrentteam.id).map((teammember, index) => (
                    <div className='teammember-card' key={index}>
                        {isEditTMMode && (<div className="team-top-bar">
                            <i className="fa-solid fa-pen edit-btn" onClick={() => handleEditMember(teammember)}></i>
                            <i className="fa-solid fa-trash del-btn" onClick={() => handleDelMember(teammember)}></i>
                        </div>)}
                        <div className='teammember-card-name-status-row'>
                            <div className='teammember-info-col'>
                                <h3>{teammember.first_name} {teammember.middle_name} {teammember.last_name}</h3>
                                <p>{teammember.email}</p>
                            </div>
                            {!teammember.isinvited ? (<div className='teammember-send-mail-col'><span className="teammember-send-mail-btn" onClick={() => sendRatingInvite(teammember)}><i className="fa-solid fa-envelope fa-lg" /> Click to Send</span></div>)
                                : (<div className='teammember-send-mail-col'>
                                    <span><i className="fa-solid fa-envelope-circle-check fa-lg" /> Sent</span>
                                    <p>Not received? <span className='teammember-resend-mail' onClick={() => sendRatingInvite(teammember)}>Resend</span></p>
                                </div>)}
                            {!teammember.hasevaluated ? (<span><i className="fa-solid fa-circle fa-lg" /> Rating Incomplete</span>)
                                : (<span><i className="fa-solid fa-circle-check fa-lg" /> Rating Complete</span>)}
                        </div>
                    </div>
                ))}
            </div>
            <button className="responsive-add-member-btn theme-button" onClick={onEditTeamMemberMode}><i className="fa-solid fa-pen-to-square fa-lg" /> Edit Team Members</button>
            <button className="responsive-add-member-btn theme-button" onClick={() => addTeammates()}><i className="fa-solid fa-plus" />Add Team Members</button>

            <Modal
                show={isAddTMOpen}
                onHide={handleTMClose}
                backdrop="static"
                keyboard={false}
                centered
                className='edit-form-group my-teammate-modal'
            >
                <Modal.Header closeButton>
                    <h3>Add new team member</h3>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Team Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="firstname"
                                placeholder="Team Name"
                                value={currrentteam.team_name}
                                disabled
                                autoFocus
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="firstname"
                                placeholder="First Name"
                                onChange={newMemberData}
                                autoFocus
                            />
                            {validErrors.lastname && <Form.Text className="text-danger">{validErrors.firstname}</Form.Text>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Middle Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="middlename"
                                placeholder="Middle Name"
                                onChange={newMemberData}
                                autoFocus
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="lastname"
                                placeholder="Last Name"
                                onChange={newMemberData}
                                autoFocus
                                required
                            />
                            {validErrors.lastname && <Form.Text className="text-danger">{validErrors.lastname}</Form.Text>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                placeholder="Email address"
                                onChange={newMemberData}
                                autoFocus
                                required
                            />
                            {validErrors.email && <Form.Text className="text-danger">{validErrors.email}</Form.Text>}
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleTMClose}>
                        Close
                    </Button>
                    <Button className='confirm' onClick={createTeammate}>
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={isEditTMOpen}
                onHide={handleEditTMClose}
                backdrop="static"
                keyboard={false}
                centered
                className='edit-form-group my-teammate-modal'
            >
                <Modal.Header closeButton>
                    <h3>Edit team member information</h3>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Team Name</Form.Label>
                            <Form.Select aria-label="Default select example"
                                name="teamname"
                                onChange={newMemberData}
                                defaultValue={selectedTeammember.team_name_id}
                                value={newMemberData.team_name_id}>

                                {teamList.map((team) => (
                                    <option key={team.id} value={team.id}>
                                        {team.team_name}
                                    </option>
                                ))}
                            </Form.Select>

                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="firstname"
                                placeholder="First Name"
                                defaultValue={selectedTeammember.first_name}
                                value={newMemberData.first_name}
                                onChange={newMemberData}
                                autoFocus
                            />
                            {validErrors.firstname && <Form.Text className="text-danger">{validErrors.firstname}</Form.Text>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Middle Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="middlename"
                                placeholder="Middle Name"
                                defaultValue={selectedTeammember.middle_name}
                                onChange={newMemberData}
                                autoFocus
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="lastname"
                                placeholder="Last Name"
                                defaultValue={selectedTeammember.last_name}
                                onChange={newMemberData}
                                autoFocus
                            />
                            {validErrors.lastname && <Form.Text className="text-danger">{validErrors.lastname}</Form.Text>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                placeholder="Email address"
                                defaultValue={selectedTeammember.email}
                                onChange={newMemberData}
                                autoFocus
                            />
                            {validErrors.email && <Form.Text className="text-danger">{validErrors.email}</Form.Text>}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleEditTMClose}>
                        Close
                    </Button>
                    <Button className='confirm' onClick={submitTMchange}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={deleteConfOpen} onHide={handleDMClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Information:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!memberToDel.hasevaluated ? (<p>Are you sure to delete this member?</p>)
                        : (<p>Considering that this teammate has provided a rating for your leadership attributes, you have the option to remove him/her from your team. However, please note that his/her rating score will still be included in your average leadership attributes score. Are you sure you want to proceed with the deletion?</p>)
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDMClose}>
                        Cancel
                    </Button>
                    <Button className='confirm' onClick={() => deleteTeammate(memberToDel)}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* pop up message */}
            <Modal onHide={handleCloseMsg} show={showMsg} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Information:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{message}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" hidden={hideInfoBtn} onClick={handleCloseMsg}>
                        Cancel
                    </Button>
                    <Button className='confirm' hidden={hideInfoBtn} onClick={() => handleYesMsg()}>
                        View plans
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

TeamMembers.propTypes = {
    selectedTeam: PropTypes.object.isRequired,
    onSelectTeam: PropTypes.func.isRequired,
    userProfile: PropTypes.object.isRequired,
    teamList: PropTypes.object.isRequired,
};

export default TeamMembers;
