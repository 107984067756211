import { useParams } from 'react-router-dom';
import '../Utils/common.css';
import './UnsubscribeNewsletter.css';
import axios from 'axios';
import { backendURL } from '../Utils/utils';
import { useState } from 'react';
import Loading from '../Utils/Loading';
import { toast } from 'react-toastify';

// Function for unsubscribing from the newsletter.
const UnsubscribeNewsletter = () => {
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const unsubscribe = async () => {
        setLoading(true);
        if (!params.uuid) {
            toast.error('Something happened, please try again');
        }
        try {
            const resposne = await axios.patch(`${backendURL}/unsubscribe/${params.uuid}`, params);
            if (resposne.status === 204) toast.success('Success! You won\'t get any more newsletter email from us');
            else toast.error('Something happened, please try again');
        }
        catch (e) {
            console.error(e);
            toast.error('Something happened, please try again');
        }
        setLoading(false);
    };

    if (loading) {
        return (<Loading message={'Updating your preference'} />);
    }
    return (
        <div className="unsubscribe-page">
            <h3>Unsubscribe to PplLdr newsletter</h3>
            <p>You won&apos;t receive any newsletter from PplLdr in the future.</p>
            <button className="theme-button" onClick={unsubscribe}>
                Unsubscribe
            </button>
        </div>
    );
};

export default UnsubscribeNewsletter;