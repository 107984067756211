import { useParams } from 'react-router-dom';
import Player from './Player';


// Podcast component that renders a podcast page with the specified playid.
const Podcast = () => {
    const { playid = 0 } = useParams();

    return (
        <div id='podcast-page'>
            <div>
                <h1>PplLdr Podcasts</h1>
                <p>Listen to experts explain the importance in leadership and organizational success.</p>
            </div>
            <Player playid={parseInt(playid)}/>
        </div>
    );
};

export default Podcast;