import { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

import GoogleLoginModule from './GoogleLoginModule';
import { LinkedinLoginModule } from './LinkedinLoginModule';
import Loading from '../Utils/Loading';
import { backendURL } from '../Utils/utils';
import 'react-toastify/dist/ReactToastify.css';
import './Login.css';
// import logo from "../../assets/PplLdr_mainlogobanner.png"

/*
Handles the login process for the user.
- It takes in the user's credentials, communicates with the backend to authenticate the user, and manages the form data and error handling.
- It also includes third-party login handling for Google and LinkedIn.
*/
const Login = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const [errorField, setErrorField] = useState(null);

    const { email, password } = formData;
    const navigate = useNavigate();
    const location = useLocation();
    const next_url = location.state?.next_url;

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        // Clear the error message when the user retypes
        setErrorField(null);
    };

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('token')) {
            navigate('/createPost');
        }
    });

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await axios.post(`${backendURL}/login/`, formData);
            const { token } = response.data;

            // Store the token in localStorage for later use
            localStorage.setItem('token', token);
            window.dispatchEvent(new Event('storage'));

            // Clear the form data after successful login
            setFormData({ email: '', password: '' });

            // Show toaster notification for successful login
            toast.success('Login successful!');

            if (next_url)navigate(next_url);
            // Redirect the user to the profile page after successful login
            else navigate('/createpost');
        } catch (err) {
            console.error(err);

            if (err.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (err.response.status === 401) {
                    // Unauthorized (wrong credentials)
                    setErrorField('password');
                } else if (err.response.status === 404) {
                    // Not Found (user does not exist)
                    setErrorField('email');
                } else {
                    setErrorField(null);
                }
            } else if (err.request) {
                // The request was made but no response was received
                setErrorField(null);
            } else {
                // Something happened in setting up the request that triggered an Error
                setErrorField(null);
            }
        }
        setIsLoading(false);
    };

    const handleLogin_thirdParty = async (ptoken, platform) => {
        setIsLoading(true);
        try {
            let config;
            let platform_route;
            if (platform === 'google') {
                config = {
                    headers: {
                        google_token: ptoken,
                    },
                };
                platform_route = 'login_g';
            }
            else if (platform === 'linkedin') {
                config = {
                    headers: {
                        linkedin_token: ptoken,
                    },
                };
                platform_route = 'login_l';
            }

            const response = await axios.post(`${backendURL}/${platform_route}/`, config);
            const { token, sub } = response.data;

            // Store the token in localStorage for later use
            localStorage.setItem('token', token);
            localStorage.setItem('sub', sub);
            window.dispatchEvent(new Event('storage'));

            // Clear the form data after successful login
            setFormData({ email: '', password: '' });

            // Show toaster notification for successful login
            toast.success('Login successful!');
            setTimeout(() => {
                navigate('/createpost');
            }, 3000);
        } catch (err) {
            console.error(err);

            if (err.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (err.response.status === 401) {
                // Unauthorized (expired credentials)
                    toast.error('Stale request. Refreshing page');
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                } else if (err.response.status === 404) {
                // Not Found (user does not exist)
                    toast.info('Account not found. Please register your account first!');
                } else {
                    setErrorField(null);
                }
            } else if (err.request) {
                // The request was made but no response was received
                setErrorField(null);
            } else {
                // Something happened in setting up the request that triggered an Error
                setErrorField(null);
            }
        }
        setIsLoading(false);
    };

    const handleLogin_google = (token) => {
        handleLogin_thirdParty(token, 'google');
    };

    const handleLogin_linkedin = (token) => {
        handleLogin_thirdParty(token, 'linkedin');
    };

    return (
        <div className="login-form-container">
            {isLoading && <Loading />}

            <form className="login-form edit-form-group" onSubmit={handleLogin}>
                <h2>Sign in</h2>
                <div className='third-party-container'>
                    <div id="google-register-container">
                        <GoogleLoginModule
                            handlerFunction={handleLogin_google}
                            useOneTap={false}
                            oneTapParentId="one-tap-container" />
                    </div>
                    <LinkedinLoginModule handleLogin={handleLogin_linkedin} />
                </div>

                <div className={`edit-form-group ${errorField === 'email' ? 'error' : ''}`}>
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        name="email"
                        value={email}
                        placeholder='Enter your email'
                        onChange={handleChange}
                        autoComplete='email'
                        required
                    />
                    {errorField === 'email' && (
                        <span className="error-message">Invalid email or user does not exist</span>
                    )}
                </div>
                <div className={`edit-form-group ${errorField === 'password' ? 'error' : ''}`}>
                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        name="password"
                        value={password}
                        placeholder='Enter your password'
                        onChange={handleChange}
                        required
                        autoComplete='current-password'
                    />
                    {errorField === 'password' && (
                        <span className="error-message">Invalid password</span>
                    )}
                </div>
                <div className="password-pass">
                    <button type="submit" className="login-button theme-button">
                        Sign In
                    </button>
                    <Link to="/forgot-password">
                        <p>Forgot your password?</p>
                    </Link>
                </div>
                <Link to="/register" className="register-link-btn">
                    <p>New to PplLdr? Join us now!</p>
                </Link>
            </form>
        </div>
    );
};

export default Login;
