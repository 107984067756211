import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import PropTypes from 'prop-types';

import HomeIconLogo from '../../assets/PplLdr_white.png';
import PremiumBtn from '../Utils/PremiumBtn';
import UserResult from './UserResult';
import { getAuthenticationHeader, backendURL, websocketURL, getAuthenticationToken, frontendURL } from '../Utils/utils';
import './Nav.css';
import '../Utils/common.css';

// Top navigation bar
const Nav = (props) => {
    const navigate = useNavigate();
    const [searchtxt, setSearchtxt] = useState();
    const [userProfile, setUserProfile] = useState(props.userAppData ? props.userAppData : null);
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    const [show_qlist, setShow_qlist] = useState(false);
    const [searchResult, setSearchResults] = useState(null);
    const [isSelfRateCompleted, setIsSelfRateCompleted] = useState(false);
    const [notifCount, setNotifCount] = useState(0);
    const [inviteLeaderForm, setInviteLeaderForm] = useState(false);
    const [email, setEmail] = useState('');
    const [leaderResultPage, setLeaderResultPage] = useState(0);
    const [noMoreResults, setNoMoreResults] = useState(false);

    const handleLogout = () => {
        // Clear the token and isLoggedIn flag from local storage
        localStorage.clear();
        window.dispatchEvent(new Event('storage'));
        navigate('/');
    };

    const fetchProfileData = async () => {
        if (props.userAppData) return;

        try {
            const response = await axios.get(`${backendURL}/user/profile/`, getAuthenticationHeader());
            setUserProfile(response.data);
            props.setUserAppData(response.data);
        } catch (error) {
            console.error(error);
            setUserProfile(null);
        }
    };

    useEffect(() => {
        setUserProfile(props.userAppData);
        if (getAuthenticationHeader()) {
            fetchProfileData();
            listen_notification();
            getSelfRatingStatus();
            fetchNewNotif();
        }
        window.addEventListener('storage', () => {
            if (getAuthenticationHeader()) {
                fetchProfileData();
                getSelfRatingStatus();
            } else {
                setUserProfile(null);
                setIsSelfRateCompleted(false);
            }
        }, false);
        // eslint-disable-next-line
    }, [isLoggedIn, props])

    const handleClose = () => setShow_qlist(false);

    const handle_be_leader = () => {
        setShow_qlist(true);
    };

    const handle_self_rate = () => {
        setShow_qlist(false);
        navigate(`/questionnaire/selfrate/${userProfile.first_name}-${userProfile.last_name}-${userProfile.id}`,
            {
                state: {
                    userProfile: userProfile,
                    self_rate: true,
                },
            },
        );
    };

    const getSelfRatingStatus = async () => {
        if (!userProfile) {
            return;
        }
        try {
            const config = {
                ...getAuthenticationHeader(),
                params: {
                    first_name: userProfile.first_name,
                    last_name: userProfile.last_name,
                    id: userProfile.id,
                },
            };

            const response = await axios.get(`${backendURL}/get_rating_list`, config);
            if (response.status === 200) {
                const selfrating = response.data.filter(ratingList => ratingList.self_rate === true);
                if (selfrating.length > 0) {
                    setIsSelfRateCompleted(true);
                }
            }

        } catch (error) {
            console.error(error);
        }

    };

    const searchLeader = async (data) => {
        try {
            let config = getAuthenticationHeader();
            config = {
                ...config,
                params: {
                    searchtxt: data,
                    page: leaderResultPage,
                },
            };

            // search leader by name/email
            const response = await axios.get(`${backendURL}/search_name/`, config);

            const userList = response.data;
            setSearchResults(prevList => {
                return [...(prevList || []), ...userList];
            });

            if (userList.length < 15)
            {
                setNoMoreResults(true);
                return;
            }
            setLeaderResultPage(prev => {return prev + 15;});
        } catch (error) {
            console.error(error);
        }
    };

    let isSearching = false;
    const handleTextChange = (e) => {
        e.preventDefault();
        if (isSearching) {
            setLeaderResultPage(0);
            setNoMoreResults(false);
            return;
        }
        if (e.target.value === '') {
            setLeaderResultPage(0);
            setNoMoreResults(false);
            setSearchtxt(null);
            setSearchResults(null);
            return;
        }

        isSearching = true;
        setTimeout(() => {
            setSearchResults(null);
            isSearching = false;

            if (e.target.value === '' || e.target.value.split(' ').join('') === '') {
                setLeaderResultPage(0);
                setNoMoreResults(false);
                setSearchResults(null);
                setSearchtxt(null);
                return;
            }
            try {
                setSearchtxt(e.target.value);
                searchLeader(e.target.value);
            } catch (error) {
                setLeaderResultPage(0);
                setNoMoreResults(false);
                console.error(error);
            }
        }, 500);
    };

    const moreResults = () => {
        const searchBox = document.getElementById('nav-search-txt');
        searchLeader(searchBox.value);
    };

    const inviteLeader = () => setInviteLeaderForm(!inviteLeaderForm);

    const handleEmailChange = (event) => setEmail(event.target.value);

    const handleInviteSubmit = async (event) => {
        if (email.length === 0) {
            return;
        }
        event.preventDefault();
        try {
            await axios.post(`${backendURL}/send_invitation/`, { friend_email: email }, getAuthenticationHeader());
            document.getElementById('invite-leader-invite-btn').style.backgroundColor = 'var(--success-green)';
            document.getElementById('invite-leader-invite-btn').innerText = 'Email sent!';
            setTimeout(() => {
                document.getElementById('invite-leader-invite-btn').style.backgroundColor = 'var(--main-accent-blue)';
                document.getElementById('invite-leader-invite-btn').innerText = 'Send invite';
            }, 3000);
            setEmail('');
        } catch (e) {
            console.error(e);
        }
    };

    // let hamburgerMenu = false;
    const [hamburgerMenu, setHamburgerMenu] = useState(false);
    const hamburgerToggle = () => {
        const navBarWidth = document.getElementById('navbar-container').offsetWidth;
        if (navBarWidth > 855) {
            return;
        }

        const menu = document.getElementsByClassName('nav-bar-menu-container')[0];
        if (!hamburgerMenu) {
            setHamburgerMenu(true);
            menu.style.opacity = '1';
            menu.style.transform = 'translate(0, 38px)';
        }
        else {
            setHamburgerMenu(false);
            menu.style.opacity = '0';
            menu.style.transform = 'translate(0, -100%)';
        }
    };

    const listen_notification = () => {
        if (!userProfile) {
            return;
        }

        try {
            const socket = new WebSocket(`${websocketURL}/listen_notification/?token=${getAuthenticationToken()}`);

            socket.onopen = () => {
                console.log('WebSocket connection established.');
            };
            socket.onclose = () => {
                console.log('WebSocket connection closed.');
            };
            socket.onmessage = (event) => {
                const data = JSON.parse(event.data);
                setNotifCount(data.count);
                console.log('WebSocket message received.', data.count);
            };
        }
        catch (e) {
            console.error(e);
        }
    };

    // fetch the count of new notifications
    const fetchNewNotif = async () => {
        if (!userProfile) {
            return;
        }
        try {
            const config = getAuthenticationHeader();

            const response = await axios.get(`${backendURL}/new_notifications/`, config);
            if (response.status === 200) {
                setNotifCount(response.data);
            }
            else if (response.status === 204) {
                setNotifCount(0);
            }

        } catch (err) {
            console.error(err);
        }
    };

    const viewNewNotif = () => {
        if (notifCount > 0) {
            changeNotifAlertStatus();
        }
        navigate('/notification');
    };

    // change new notifition status, and it will not be notified anymore by alert icon.
    const changeNotifAlertStatus = async () => {
        try {
            const config = getAuthenticationHeader();

            const requestData = {};

            const response = await axios.put(`${backendURL}/new_notifications/`, requestData, config);
            if (response.status === 200) {
                setNotifCount(0);
            }

        } catch (err) {
            console.error(err);
        }
    };

    const copyInviteLink = async () => {
        try {
            await navigator.clipboard.writeText(`${frontendURL}/register`);
            document.getElementById('copied-indicator').style.display = 'block';
            setTimeout(() => {
                document.getElementById('copied-indicator').style.color = 'var(--success-green)';
            }, 100);
            setTimeout(() => {
                document.getElementById('copied-indicator').style.color = 'var(--transparent)';
            }, 2000);
            setTimeout(() => {
                document.getElementById('copied-indicator').style.display = 'none';
            }, 2100);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    return (
        <div className="navbar-container" id="navbar-container">
            <nav className="navbar">
                {/* pop up window for user to display unique link and input teammember email */}
                <Modal show={show_qlist} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <h3>Become a Leader</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <p>To become a leader, you will need to complete self-rating first, then invite your direct reports/team members to rate your leadership attributes. Are you sure you want to start the self-rating questionnaire?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="theme-button" onClick={handle_self_rate}>
                            Start Self-rating
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={inviteLeaderForm}
                    backdrop="static"
                    className='invite-leader-modal edit-form-group'
                    onHide={() => setInviteLeaderForm(false)}
                    centered
                >
                    <Modal.Header closeButton>
                        <h3>Invite a Leader</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group>
                                <Form.Label>Email:</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter invite email"
                                    onChange={handleEmailChange}
                                    value={email}
                                    required
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Or send them a link to PplLdr directly</Form.Label>
                                <p>Link:</p>
                                <div id='link-field' onClick={copyInviteLink}>
                                    <div id='copied-indicator'>
                                        <p>Link copied</p>
                                    </div>
                                    <Form.Control
                                        type='text'
                                        value={`${frontendURL}/register`}
                                        id='pplldr-register-link'
                                    />
                                    <i className='fa-solid fa-link' onClick={copyInviteLink}></i>
                                </div>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={() => setInviteLeaderForm(false)}>
                            Close
                        </Button>
                        <Button onClick={handleInviteSubmit} id='invite-leader-invite-btn'>
                            Send invite
                        </Button>
                    </Modal.Footer>
                </Modal>

                <div className="home-icon-container">
                    <Link to={userProfile ? '/createpost' : '/'}>
                        <img src={HomeIconLogo} alt="Home" className="home-icon" />
                    </Link>
                </div>

                {userProfile ? (
                    <div className="nav-search">
                        <input
                            className="nav-search-txt"
                            id="nav-search-txt"
                            type="text"
                            placeholder="Search leader or company"
                            onChange={(e) => handleTextChange(e)}
                        />
                        <div className="nav-beleader-button">
                            {isSelfRateCompleted ?
                                (<button className="theme-button" onClick={() => navigate('/user/myteams')}>
                                    <> My Team Members</>
                                </button>)
                                :
                                (<button className="theme-button" onClick={handle_be_leader}>
                                    <>Become a Leader</>
                                </button>)
                            }
                        </div>
                    </div>
                )
                    :
                    <Link className="nav-links-not-logged" to="/podcast/">Listen to our podcast</Link>
                }

                <div className="nav-links">
                    {userProfile ? (
                        <div className='alert-icon-container' onClick={viewNewNotif}>
                            <div className='alert-icon'><i className="fa-regular fa-bell fa-xl fa-fw" /></div>
                            {(notifCount > 0) && (<div className="alert-icon-txt">{notifCount > 99 ? '99+' : notifCount}</div>)}
                        </div>
                    ) : null}

                    {userProfile ? (
                        <div className="logged-in-links">
                            {userProfile ? <img id="nav-profile-btn" className={userProfile.is_premium ? 'premium-user-pfp' : ''} src={userProfile.profile_image} alt="pfp" onClick={() => navigate('/profile')} /> : null}
                            {userProfile ? <div id="nav-hamburger-menu" onClick={() => { hamburgerToggle(); }}><i className="fa-solid fa-bars fa-xl"></i></div> : null}
                            <div className="nav-bar-menu-container">
                                <PremiumBtn />
                                <button
                                    className="nav-bar-menu-btn"
                                    onClick={() => {
                                        navigate('/createPost');
                                        hamburgerToggle();
                                    }}>
                                    <span>
                                        <i className="fa-solid fa-house"></i>
                                    </span>
                                    <p>Home Page</p>
                                </button>
                                <button
                                    className="nav-bar-menu-btn"
                                    onClick={() => {
                                        navigate('/profile');
                                        hamburgerToggle();
                                    }}>
                                    <span>
                                        <i className="fa-solid fa-user"></i>
                                    </span>
                                    <p>View Profile</p>
                                </button>
                                <button
                                    className="nav-bar-menu-btn"
                                    onClick={() => {
                                        navigate('/podcast/');
                                        hamburgerToggle();
                                    }}>
                                    <span>
                                        <i className="fa-solid fa-headphones"></i>
                                    </span>
                                    <p>Podcast</p>
                                </button>
                                <button
                                    className="nav-bar-menu-btn"
                                    onClick={() => {
                                        navigate('/aitools');
                                        hamburgerToggle();
                                    }}>
                                    <span>
                                        <i className="fa-solid fa-book"></i>
                                    </span>
                                    <p>Leadership Learning Assistant </p>
                                </button>
                                <button
                                    className="nav-bar-menu-btn"
                                    onClick={() => {
                                        navigate('/setting');
                                        hamburgerToggle();
                                    }}>
                                    <span>
                                        <i className="fa-solid fa-gear "></i>
                                    </span>
                                    <p>Settings</p>
                                </button>
                                <button className="nav-bar-menu-btn" onClick={() => {
                                    viewNewNotif();
                                    hamburgerToggle();
                                }}>
                                    <span>
                                        <i className="fa-regular fa-bell "></i>
                                    </span>
                                    <p>Notifications</p>
                                    {(notifCount > 0) && (<div className="alert-icon-txt">{notifCount > 99 ? '99+' : notifCount}</div>)}
                                </button>
                                <button
                                    className="nav-bar-menu-btn"
                                    onClick={() => {
                                        navigate('/faq');
                                        hamburgerToggle();
                                    }}>
                                    <span>
                                        <i className="fa-solid fa-question"></i>
                                    </span>
                                    <p>FAQ</p>
                                </button>
                                <button className="nav-bar-menu-btn" onClick={handleLogout}>
                                    <span>
                                        <i className="fa-solid fa-power-off "></i>
                                    </span>
                                    <p>Logout</p>
                                </button>
                            </div>
                        </div>
                    ) : (
                        <>
                            <Link className="nav-links-not-logged" to="/login">Sign in</Link>
                            <Link className="nav-links-not-logged" to="/register">Register</Link>
                        </>
                    )}
                </div>
            </nav>
            {searchtxt && searchResult &&
                <div className="nav-search-results">
                    <div className="search-result-close" onClick={() => { setSearchResults(null); }}>
                        &times;
                    </div>
                    {searchResult.length > 0 ?
                        searchResult.map(resultItem => {
                            return (
                                <UserResult
                                    key={resultItem.id}
                                    leader={resultItem}
                                    handleClose={() => { setSearchResults(null); }} />
                            );
                        })
                        :
                        <p>No results found for {`"${searchtxt}"`}</p>
                    }
                    <div className="arrow-indicator">
                        {(searchResult.length > 3) && <i className="fa-solid fa-arrow-down fa-fade"></i>}
                    </div>
                    <button
                        className='more-results-btn'
                        onClick={moreResults}
                        disabled={noMoreResults}
                    >
                        {noMoreResults ? 'end of users' : 'more results'}
                    </button>
                    <div className="note-info">
                        <p>Don{'\''}t see the leader you are looking for?</p>
                        <button className="theme-button" onClick={() => { inviteLeader(); }}>Invite a leader</button>
                    </div>
                </div>
            }
        </div>
    );
};

Nav.propTypes = {
    userAppData: PropTypes.object,
    setUserAppData: PropTypes.func,
};

export default Nav;