import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import propTypes from 'prop-types';

import './CreatePost.css';
import PremiumBtn from '../Utils/PremiumBtn';
import UserThumbnail from '../Utils/UserThumbnail';
import { FeaturedLeader } from './FeaturedContent';
import { getAuthenticationHeader, backendURL, logOut } from '../Utils/utils';
import Loading from '../Utils/Loading';
import Post from './Post';


// Create a post with content, image, file, and video and handle the uploading and displaying of posts, images, and videos.
const CreatePost = (props) => {
    const [postContent, setPostContent] = useState('');
    const [postImage, setPostImage] = useState(null);
    const [postFile, setPostFile] = useState(null);
    const [postVideo, setPostVideo] = useState(null);
    const [previewFile, setPreviewFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [previewVideo, setPreviewVideo] = useState(null);
    const [posts, setPosts] = useState([]);
    const [featuredTopLeaders, setFeaturedTopLeaders] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState('Loading Latest Posts');
    let pageIndex = 0;
    let wait = false;
    const [likedPostsMap, setLikedPostsMap] = useState(null);
    const navigate = useNavigate();

    // Function to fetch posts from the backend with lazy loading
    const fetchPosts = async () => {
        if (wait) {
            return;
        }

        setTimeout(() => { wait = false; }, 3000);
        wait = true;

        try {
            const config = { ...getAuthenticationHeader(), params: { page: pageIndex } };

            const response = await axios.get(`${backendURL}/social_post/`, config);
            const newItems = response.data.filter(item => !posts.some(post => post.id === item.id));
            setPosts(prevdata => [...prevdata, ...newItems]);
            if (response.data.length > 0) {
                pageIndex = pageIndex + 10;
            }
        } catch (err) {
            console.error('Error fetching posts:', err);
            logOut();
        }

        setLoading(false);
    };

    const fetchLikedPosts = async () => {
        const config = getAuthenticationHeader();

        try {
            const response = await axios.get(`${backendURL}/get_liked_posts/`, config);
            setLikedPostsMap(response.data);
        }
        catch (e) {
            console.error(e);
            logOut();
        }
    };

    const fetchTopLeaders = async () => {
        try {
            const response = await axios.get(`${backendURL}/get_top_ten_leaders/`);
            setFeaturedTopLeaders(response.data);
        }
        catch (err) {
            console.error(err);
        }
    };

    const dynamicPostRemove = (idx) => {
        const postList = [...posts.slice(0, idx), ...posts.slice(idx + 1)];
        setPosts(postList);
    };

    // Function to get the user ID from the JWT token
    const getUserId = () => {
        const config = getAuthenticationHeader();

        const decodedToken = jwtDecode(config.headers.Authorization);
        const userId = decodedToken.user_id;
        return userId;
    };

    // Function to handle posting a new post
    const handlePost = async () => {
        try {
            const config = getAuthenticationHeader('multipart/form-data');

            const requestData = {
                content: postContent,
                user: getUserId(),
                image: postImage,
                file: postFile,
                video: postVideo,
            };

            setLoadingMessage('Uploading in Progress...');
            setLoading(true);
            const response = await axios.post(`${backendURL}/social_post/`, requestData, config);

            if (response.status === 201 && response.data) {
                const postList = [response.data, ...posts];
                setPosts(postList);
            }

            // Reset post content
            setPostContent('');
            setPostFile(null);
            setPreviewFile(null);
            setPostImage(null);
            setPreviewImage(null);
            setPostVideo(null);
            setPreviewVideo(null);

            toast.success('Upload post success!');
        } catch (err) {
            console.error(err);
        }
        setLoadingMessage('Loading Latest Posts');
        setLoading(false);
    };

    const imageUpload = (e) => {
        setPostImage(e.target.files[0]);
        setPreviewImage(URL.createObjectURL(e.target.files[0]));
        toast.success('Uploaded image successfully');
    };

    const fileUpload = (e) => {
        setPostFile(e.target.files[0]);
        setPreviewFile(URL.createObjectURL(e.target.files[0]));
        toast.success('Uploaded file successfully');
    };

    const extType = ['mov', 'mp4', 'webm'];
    const videoUpload = (e) => {
        if (!e.target.files[0]) {
            return;
        }

        // 100 MB
        const maxSize = 100 * 1024 * 1024;
        if (e.target.files[0].size > maxSize) {
            toast.info('File size exceeds the maximum allowed size.');
            return;
        } else {
            toast.success('Uploaded video successfully');
        }

        const fileNameArr = e.target.files[0].name.split('.');
        if (!extType.includes(fileNameArr[fileNameArr.length - 1])) {
            console.error('Unsupported Video Format');
            return;
        }

        setPostVideo(e.target.files[0]);
        setPreviewVideo(URL.createObjectURL(e.target.files[0]));
    };

    const textareaHeightAdjust = (e) => {
        e.style.height = 'auto';
        e.style.height = (e.scrollHeight) + 'px';
    };

    const removeImage = () => {
        setPostImage(null);
        setPreviewImage(null);
    };

    const removeFile = () => {
        setPostFile(null);
        setPreviewFile(null);
    };

    const removeVideo = () => {
        setPostVideo(null);
        setPreviewVideo(null);
    };

    // Implementing lazy loading
    const lazyFetch = () => {
        const item = document.querySelector('#grouped-cards');
        try {
            if (item.scrollHeight - window.scrollY < 700) {
                fetchPosts();
            }
        }
        catch (e) {
            console.log('Posts not loaded');
        }
    };

    useEffect(() => {
        fetchLikedPosts()
            .then(() => {
                fetchPosts();
            });
        fetchTopLeaders();

        window.addEventListener('scroll', lazyFetch);

        return () => {
            window.removeEventListener('scroll', lazyFetch);
        };
    }, []);

    if (loading) {
        return <Loading message={loadingMessage} />;
    }

    return (
        <div className="homebody" id="create-post-container">
            <div id="premium-btn">
                <button className='book-recommendation-link' onClick={() => navigate('/aitools')}>
                    <span>
                        <i className="fa-solid fa-star"></i>
                    </span>
                    Leadership Learning Assistant
                </button>
                <PremiumBtn id="premium-btn" />
            </div>

            <div className='featured-leaders-container'>
                <h3>Top {featuredTopLeaders.length} Leaders</h3>
                {featuredTopLeaders.length && featuredTopLeaders.map((leaderInfo, idx) => {
                    return (
                        <FeaturedLeader
                            leader={leaderInfo.leader}
                            avgScore={leaderInfo.average_score}
                            key={idx}
                        />
                    );
                })}
            </div>

            <div className="grouped-cards" id="grouped-cards">
                <div className="create-post-card">
                    {props.userProfile && <UserThumbnail userProfile={props.userProfile} self={true} />}
                    <textarea
                        id="post-text-area"
                        className="post-content-input"
                        placeholder="What's on your mind...?"
                        value={postContent}
                        onChange={(e) => { setPostContent(e.target.value); }}
                        onKeyUp={e => { textareaHeightAdjust(e.target); }}
                    />
                    {previewImage && <img alt="post" className="post-content-preview-image" src={previewImage} />}

                    {previewVideo &&
                        <video
                            className="post-content-preview-image"
                            width="100%"
                            controls
                        >
                            <source src={previewVideo} type='video/mp4' />
                            <source src={previewVideo} type='video/mov' />
                            <source src={previewVideo} type='video/webm' />
                        </video>
                    }

                    {previewFile &&
                        <button
                            className='post-button'
                            onClick={() => { window.open(previewFile, '_blank'); }}>
                            Preview uploaded file
                        </button>
                    }

                    <div className="create-post-attachments">
                        {postImage ?
                            <button className="remove-attachment-button" onClick={removeImage}>
                                &times;
                                Remove Image

                            </button>
                            :
                            <label htmlFor="post-image" className="add-attachment-button" >
                                <i className="fa-solid fa-image"></i>
                                Add Image
                                <input
                                    id="post-image"
                                    type="file"
                                    accept="image/*"
                                    onChange={e => { imageUpload(e); }}
                                />
                            </label>
                        }

                        {postVideo ?
                            <button className="remove-attachment-button" onClick={removeVideo}>
                                &times;
                                Remove Video
                            </button>
                            :
                            <label htmlFor="post-video" className="add-attachment-button" >
                                <i className="fa-solid fa-video"></i>
                                Add Video
                                <input
                                    id="post-video"
                                    type="file"
                                    accept="video/*"
                                    onChange={e => { videoUpload(e); }}
                                />
                            </label>
                        }

                        {postFile ?
                            <button className="remove-attachment-button" onClick={removeFile}>
                                &times;
                                Remove File
                            </button>
                            :
                            <label htmlFor="post-file" className="add-attachment-button" >
                                <i className="fa-solid fa-paperclip"></i>
                                Add File
                                <input
                                    id="post-file"
                                    type="file"
                                    onChange={e => { fileUpload(e); }}
                                />
                            </label>
                        }
                        <button className="post-button" onClick={handlePost}>
                            Post
                        </button>
                    </div>
                </div>

                <div id="divider"></div>

                {/* Display posts */}
                <div className="post-card-container" id="post-card-container">
                    {likedPostsMap && posts.map((post, idx) => (
                        <Post
                            key={post.id}
                            post={post}
                            activeLike={likedPostsMap[post.id] ? true : false}
                            userId={props.userProfile.id}
                            idx={idx}
                            dynamicPostRemoveCallBack={dynamicPostRemove}
                            createdAt={new Date(post.created_at)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};


CreatePost.propTypes = {
    // userProfile already defined in UserThumbnail
    userProfile: propTypes.object,
};

export default CreatePost;
