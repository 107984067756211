import { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';

import './Subscription.css';
import { getAuthenticationHeader, backendURL } from '../Utils/utils';
import Loading from '../Utils/Loading';

// Component for success payment
const SuccessPayment = () => {
    const [isLoading, setLoading] = useState(true);
    const [plan, setPlan] = useState();
    const wasCalled = useRef(false);

    const location = useLocation();
    const params = new URLSearchParams(location.search);

    useEffect(() => {
        // make sure the membership update execute only once
        if (wasCalled.current) return;
        wasCalled.current = true;
        set_membership();
    }, []);

    const set_membership = async () => {
        try {
            const config = getAuthenticationHeader();
            const memberData = {
                session_id: params.get('session_id'),
            };

            const response = await axios.put(`${backendURL}/usermembership/`, memberData, config);
            if (response.status === 200) {
                setPlan(response.data);
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className="success-payment-body">
            <h2>You have successfully suscribed the plan:</h2>
            <h2><strong>{plan}</strong></h2>
            <Link to="/createpost" >
                <h4>Go back to homepage</h4>
            </Link>
        </div>

    );
};

export default SuccessPayment;
