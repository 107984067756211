import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { getAuthenticationHeader, backendURL } from './utils';
import './PremiumBtn.css';

const PremiumBtn = () => {
    const navigate = useNavigate();
    // Function to navigate to the subscription page
    const handleSubscriptionClick = async () => {

        try {
            const config = getAuthenticationHeader();
            const response = await axios.get(`${backendURL}/usermembership/`, config);
            // set returned plan type to 'current_plan'
            const currentplan = response.data;

            // Store the token in localStorage for later use
            localStorage.setItem('currentplan', currentplan);

            navigate('/plan/manageplan');

        } catch (error) {
            console.error(error);
        }

    };
    return (
        <button
            onClick={handleSubscriptionClick}
            className="subscription-link"
        >
            <span>
                <i className="fa-solid fa-star"></i>
            </span>
            <p>Go Premium</p>
        </button>
    );
};

export default PremiumBtn;